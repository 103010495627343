import _ from 'lodash';
import React, { useState } from 'react';
import config from '../../../config';
import log from 'loglevel';
import Select from 'react-select';
import { ProductsAPIQueryType, useProductsQuery } from '../../utils/public-api';
import { AcornProduct, useSaveProductMutation } from '../../generated/types-and-hooks';
// import { diff } from 'json-diff';
import * as jsondiffpatch from 'jsondiffpatch';

const ENV_CONFIG = config.get();

type SelectOption = {
  label: string;
  value: string;
};

const translateProductToOption = (product: AcornProduct): SelectOption => ({
  label: `${product.Id} - ${product.Name} (${product.BotanicalName} - ${product.CommonName})`,
  value: product.Id,
});
const translateFromOption = (option: SelectOption): string => option.value;

const getOtherEnvironment = (env): 'dev' | 'prod' => {
  switch (env) {
    case 'prod':
      return 'dev';
    case 'dev':
      return 'prod';
    default:
      throw new Error(`invalid environment: ${env}`);
  }
};

const ProductDataImporter = () => {
  const [productsSelected, setProductsSelected] = useState({});
  const environmentName = config.getEnvironment();
  const otherEnvironment = getOtherEnvironment(environmentName);
  const productsFromOtherEnvironment = useProductsQuery({
    type: ProductsAPIQueryType.AllWithEnvironment,
    env: otherEnvironment,
  });

  const saveProductMutation = useSaveProductMutation({
    onSuccess: (data) => {
      log.info({ data }, 'successfully saved product');
    },
    onError: (err, vars) => {
      log.error({ err, vars }, 'got error while saving product');
    },
  });

  const productsFromThisEnvironment = useProductsQuery({
    type: ProductsAPIQueryType.All,
  });

  const filterOutGeneratedTags = (tags: string[]) =>
    _.filter(tags, (tag) => {
      if (tag.startsWith('gc:') || tag.startsWith('genus:') || tag.startsWith('included_in:')) {
        return false;
      }
      return true;
    });
  const fixupProductFromSearchApi = (product: AcornProduct) => {
    return {
      ...product,
      Tags: filterOutGeneratedTags(product.Tags),
    };
  };

  const productsListOtherEnv = productsFromOtherEnvironment.data?.Products.map(fixupProductFromSearchApi);
  const productsListThisEnv = productsFromThisEnvironment.data?.Products.map(fixupProductFromSearchApi);
  const productsOtherEnvById = _.keyBy(productsListOtherEnv, 'Id');
  const productsEnvById = _.keyBy(productsListThisEnv, 'Id');

  jsondiffpatch.formatters.html.hideUnchanged();

  const productsInOtherEnv = productsListOtherEnv?.filter(({ Id }) => !(Id in productsEnvById));

  const toggleProductSelected = (Id) => {
    if (Id in productsSelected) {
      const newProducts = { ...productsSelected };
      delete newProducts[Id];
      setProductsSelected(newProducts);
    } else {
      const newProducts = { ...productsSelected };
      newProducts[Id] = true;
      setProductsSelected(newProducts);
    }
  };

  const selectAll = () => {
    const newSelected = {};
    _.map(productsListOtherEnv, ({ Id }) => {
      if (Id in productsEnvById) {
        _.set(newSelected, Id, true);
      }
    });
    _.map(productsInOtherEnv, ({ Id }) => {
      _.set(newSelected, Id, true);
    });
    setProductsSelected(newSelected);
  };

  const renderRow = (product: AcornProduct) => {
    const otherEnvProduct = product.Id in productsOtherEnvById ? productsOtherEnvById![product.Id] : undefined;
    let renderedDiff;
    let renderedStatus;
    let renderedCheckbox;
    if (otherEnvProduct) {
      const delta = jsondiffpatch.diff(product, otherEnvProduct);
      renderedDiff = <div dangerouslySetInnerHTML={{ __html: jsondiffpatch.formatters.html.format(delta, product) }} />;
      renderedCheckbox = (
        <input
          type='checkbox'
          checked={productsSelected[product.Id] === true}
          onChange={() => toggleProductSelected(product.Id)}
        />
      );
    } else {
      renderedDiff = <div>No matching product in {otherEnvironment}</div>;
    }
    return (
      <tr id={product.Id}>
        <td>{renderedCheckbox}</td>
        <td>{product.Id}</td>
        <td>
          {product.Name} ({product.BotanicalName} - {product.CommonName})
        </td>
        <td>{renderedStatus}</td>
        <td>{renderedDiff}</td>
      </tr>
    );
  };

  const renderNewRow = (product: AcornProduct) => {
    return (
      <tr id={product.Id}>
        <td>
          <input
            type='checkbox'
            checked={productsSelected[product.Id] === true}
            onChange={() => toggleProductSelected(product.Id)}
          />
        </td>
        <td>{product.Id}</td>
        <td>
          {product.Name} ({product.BotanicalName} - {product.CommonName})
        </td>
        <td>Existing no product in {environmentName}</td>
        <td>
          <pre>{JSON.stringify(product, null, 2)}</pre>
        </td>
      </tr>
    );
  };

  const rows = productsListThisEnv?.map(renderRow);
  const newRows = productsInOtherEnv?.map(renderNewRow);

  const importSelectedProducts = async () => {
    log.info({ productsSelected }, 'importing selected products');
    if (confirm(`Going to import ${_.size(productsSelected)} products, are you sure?`)) {
      const productsToImport = _.map(productsSelected, (val, key) => productsOtherEnvById[key]);
      for (const product of productsToImport) {
        const fixedUpProduct = { ...product };
        if (!_.has(fixedUpProduct, 'BackorderLimit')) {
          fixedUpProduct.BackorderLimit = 0;
        }
        const result = await saveProductMutation.mutateAsync(fixedUpProduct);
      }
    }
    <input value='Import Selected Products' type='button' onClick={importSelectedProducts} />;
  };
  return (
    <div className='grid-x grid-margin-x'>
      <div className='cell small-12'>
        <h1>Product Data Importer</h1>
        <p>Current Environment: {environmentName}</p>
        <p>
          Select products from <strong>{otherEnvironment}</strong> to import
        </p>
        <p>
          {' '}
          <input value='Select all' type='button' onClick={selectAll} />
        </p>
        <p>{_.size(productsSelected)} Products Selected</p>
        {_.size(productsSelected) > 0 ? (
          <input value='Import Selected Products' type='button' onClick={importSelectedProducts} />
        ) : null}
      </div>
      <table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {newRows}
          {rows}
        </tbody>
      </table>
    </div>
  );
};

export default ProductDataImporter;
