import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import LoadingError from '../LoadingError';
import _ from 'lodash';
import { useGetOrderQuery } from '../../generated/types-and-hooks';

const OrderDetail = ({ OrderId } = {}) => {
  const orderContext = useGetOrderQuery({ Id: OrderId });
  return (
    <div className='grid-x grid-margin-x'>
      <LoadingError error={orderContext.error} />
      <div className='callout success cell small-12 medium-8 medium-offset-2'>
        <h1>Thank you for your order!</h1>
        <p>
          <strong>Invoice ID: {orderContext.data?.GetOrder?.InvoiceId}</strong>
        </p>
        <p>You will receive an email confirmation shortly. (Check your spam folder if you don&apos;t see it.)</p>
        <p>We&apos;ll send you another email with a UPS tracking number when your order ships.</p>
        <p>
          Need more plants? Check out <a href='https://mountainvalleygrowers.com'>Mountain Valley Growers</a> for larger
          pots or <Link to='/'>return to the Plug Trays home page.</Link>
        </p>
      </div>
    </div>
  );
};

OrderDetail.propTypes = {
  OrderId: PropTypes.string,
};

export default OrderDetail;
