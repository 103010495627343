import _ from 'lodash';
import { useRef, useEffect } from 'react';
import { checkout, getShippingOptions } from '../../graphql/cart';
import { getMyOrders } from '../../graphql/users';
import log from 'loglevel';
import { useMutation, useQuery } from '@tanstack/react-query';
import { doGraphqlQuery } from '../../fetcher';

export const resolveCheckout = async (Review) => {
  // throw {errors: [{message:'one error'}, {message:'another error'}]};
  const result = await doGraphqlQuery(checkout, { Review });
  result.data.CheckoutV1.Cart.ProductsById = _.keyBy(result.data.CheckoutV1.Cart.Products, ({ Id }) => Id);
  log.debug({ result }, 'loaded Checkout payload from API');
  return result.data.CheckoutV1;
};

export const useCheckoutContext = () => {
  const Checkout = useQuery(['checkout'], _.partial(resolveCheckout, true), {});
  return Checkout;
};

export const resolveMyOrders = async () => {
  const myOrders = await doGraphqlQuery(getMyOrders);
  return _.sortBy(myOrders.data.GetMyOrders, ['TimestampCreated']);
};

export const useMyOrders = () => useQuery(['myOrders'], resolveMyOrders);

const fetchShippingOptions = async ({ Address, PlugtrayCount }) => {
  // throw {errors: [{message:'one error'}, {message:'another error'}]};
  if (PlugtrayCount < 1) {
    return [
      {
        DaysInTransit: 'N/A',
        DeliveryTime: null,
        Price: 0,
        PriceCurrency: 'USD',
        ServiceCode: 'N/A',
        BillingWeight: null,
      },
    ];
  }
  const shipping = await doGraphqlQuery(getShippingOptions, { Address, PlugtrayCount });
  return shipping.data.GetShippingOptions;
};

export const useShippingOptions = ({ Address, PlugtrayCount }, extraOptions) =>
  useQuery(['shippingOptions', { Address, PlugtrayCount }], () => fetchShippingOptions({ Address, PlugtrayCount }), {
    enabled: !_.isUndefined(Address.Zip),
    ...extraOptions,
  });

export const postPaymentToAuthorize = (paymentData) => {
  return new Promise((resolve, reject) => {
    const acceptHandler = (resp) => {
      if (_.get(resp, 'messages.resultCode') === 'Error') {
        const errMsgs = _.map(resp.messages.message, ({ code, text }) => `${text} (${code})`);
        log.error({ errMsgs }, 'postPaymentToAuthorize: got error, cannot proceed');
        return reject(errMsgs.join(', '));
      }
      if (_.has(resp, ['opaqueData', 'dataValue'])) {
        return resolve(resp);
      }
      return reject(resp);
    };
    Accept.dispatchData(paymentData, acceptHandler);
  });
};

export const useSubmitPaymentToAuthorize = () => useMutation(postPaymentToAuthorize);

export const useDidMount = () => {
  const didMountRef = useRef(true);

  useEffect(() => {
    didMountRef.current = false;
  }, []);
  return didMountRef.current;
};
