import React from 'react';
import { formatCurrency } from '../../utils/cart';
import PropTypes from 'prop-types';
import _ from 'lodash';

const CartCheckoutOrderSummary = ({
  Subtotal,
  Tax,
  Total,
  GiftCardSubtotal,
  Cart,
  GiftCardRedemptionsTotal,
  TotalToCharge,
}) => (
  <div className='cell small-12 medium-4 order-summary--container__sticky'>
    <div className='grid-x grid-padding-x grid-padding-y order-summary--container'>
      <div className='cell small-12'>
        <h4 className='header__small-caps'>Order summary</h4>
        <p className='order-summary--item'>
          Subtotal:{' '}
          <span className='currency' data-testid='cartSubtotal'>
            {formatCurrency(Subtotal + GiftCardSubtotal)}
          </span>
        </p>
        <p className='order-summary--item'>
          Estimated tax:{' '}
          <span className='currency' data-testid='cartTax'>
            {formatCurrency(Tax)}
          </span>
        </p>
        <p className='order-summary--item'>
          Estimated shipping:
          <span className='currency' data-testid='cartShipping'>
            {formatCurrency(_.get(Cart, 'ShippingService.shippingService.Price', 0))}
          </span>
        </p>

        <p className='order-summary--item'>
          <strong>
            Estimated total:{' '}
            <span className='currency' data-testid='cartTotal'>
              {formatCurrency(Total)}
            </span>
          </strong>
        </p>

        {GiftCardRedemptionsTotal > 0 && (
          <>
            <p className='order-summary--item'>
              Gift Card Redemptions:
              <span className='currency' data-testid='cartShipping'>
                {formatCurrency(GiftCardRedemptionsTotal)}
              </span>
            </p>
            <p className='order-summary--item'>
              <strong>
                Total Payment Required:{' '}
                <span className='currency' data-testid='cartTotal'>
                  {formatCurrency(TotalToCharge)}
                </span>
              </strong>
            </p>
          </>
        )}
      </div>
    </div>
  </div>
);

CartCheckoutOrderSummary.propTypes = {
  Subtotal: PropTypes.number,
  Tax: PropTypes.number,
  Total: PropTypes.number,
  TotalToCharge: PropTypes.number,
  GiftCardSubtotal: PropTypes.number,
  GiftCardRedemptionsTotal: PropTypes.number,
  Cart: PropTypes.object,
};

export default CartCheckoutOrderSummary;
