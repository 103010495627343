import { navigate } from 'gatsby';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { addCartGiftCard, updateCartGiftCard } from '../graphql/cart';
import log from 'loglevel';
import SavingError from './SavingError';
import { useCart } from './hooks';
import LoadingError from './LoadingError';
import { doGraphqlQuery } from '../fetcher';

const addGiftCardToCart = async (GiftCard) => {
  const result = await doGraphqlQuery(addCartGiftCard, { GiftCard });
  log.debug({ result, GiftCard }, 'added new gift card to cart');
  return result.data;
};

const updateGiftCard = async ({ Index, ...GiftCard }) => {
  const result = await doGraphqlQuery(updateCartGiftCard, { Index, GiftCard });
  log.debug({ result, GiftCard, Index }, 'saved existing gift card to cart');
  return result.data;
};

const GiftCard = ({ Index }) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  const isEditing = !_.isUndefined(Index);
  const cartQuery = useCart({ enabled: isEditing });
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
  } = useForm();

  const addCardMutation = useMutation(addGiftCardToCart, {
    onSuccess: (data) => {
      log.debug({ data }, 'addCardMutation: onSuccess');
      queryClient.setQueryData(['cart'], data.AddCartGiftCard);
      queryClient.invalidateQueries('checkout');
      navigate('/app/cart');
    },
  });
  const updateCardMutation = useMutation(updateGiftCard, {
    onSuccess: (data) => {
      log.debug({ data }, 'updateCardMutation: onSuccess');
      queryClient.setQueryData(['cart'], data.UpdateCartGiftCard);
      queryClient.invalidateQueries('checkout');
      navigate('/app/cart');
    },
  });

  useEffect(() => {
    // Load gift card to edit
    if (isEditing && !hasLoaded && _.has(cartQuery, ['data', 'GiftCards'])) {
      const card = _.get(cartQuery, ['data', 'GiftCards', Index]);
      if (!card) {
        log.error({ card, Index }, 'cannot find existing gift card, redirecing to add new gift card');
        navigate('/gift-cards');
        return;
      }
      log.debug({ card }, 'populating form from gift card');
      _.map(card, (value, key) => setValue(key, value));
      setValue('RecipientEmailConfirm', card.RecipientEmail);
      setHasLoaded(true);
    }
  }, [cartQuery.data]);
  const onSubmit = async ({ Amount, Message, RecipientEmail, RecipientName, SenderName }) => {
    if (isEditing) {
      return updateCardMutation.mutateAsync({
        Message,
        RecipientEmail,
        RecipientName,
        SenderName,
        Amount,
        Index: Number(Index),
      });
    } else {
      return addCardMutation.mutateAsync({ Message, RecipientEmail, RecipientName, SenderName, Amount });
    }
  };

  const pageHeader = isEditing ? `Edit gift card # ${Number(Index) + 1}` : 'Add a gift card';
  return (
    <div>
      <Helmet title={pageHeader} />
      <h2>{pageHeader}</h2>
      <LoadingError error={cartQuery.error} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor='RecipientName'>To*</label>
        <input
          type='text'
          placeholder="Recipient's Name"
          {...register('RecipientName', { required: true, maxLength: 80, minLength: 1 })}
          aria-invalid={_.has(errors, ['RecipientName']) ? 'true' : 'false'}
        />
        {_.has(errors, ['RecipientName']) && <span role='alert'>This is required</span>}

        <label htmlFor='SenderName'>From*</label>
        <input
          type='text'
          placeholder="Sender's Name"
          {...register('SenderName', { required: true, maxLength: 80, minLength: 1 })}
        />
        {_.has(errors, ['SenderName']) && <span role='alert'>This is required</span>}

        <label htmlFor='Amount'>Amount ($10-$1,000)</label>
        <input
          type='number'
          placeholder='0.00'
          {...register('Amount', { required: true, valueAsNumber: true })}
          step='10'
          min='10'
          max='1000'
        />
        {_.has(errors, ['Amount']) && <span role='alert'>This is required</span>}

        <label htmlFor='RecipientEmail'>Recipient&apos;s email address*</label>
        <input
          type='email'
          placeholder="Recipient's email address"
          {...register('RecipientEmail', { required: true, maxLength: 80, minLength: 1 })}
          aria-invalid={_.has(errors, ['RecipientEmail']) ? 'true' : 'false'}
        />
        {_.has(errors, ['RecipientEmail']) && <span role='alert'>This is required</span>}

        <label htmlFor='RecipientEmailConfirm'>Confirm recipient&apos;s email address</label>
        <input
          type='email'
          placeholder="Confirm recipient's email address"
          {...register('RecipientEmailConfirm', {
            required: true,
            maxLength: 80,
            minLength: 1,
            validate: (email) => email === getValues('RecipientEmail'),
          })}
          aria-invalid={_.has(errors, ['RecipientEmailConfirm']) ? 'true' : 'false'}
        />
        {_.has(errors, ['RecipientEmailConfirm']) && (
          <span role='alert'>This is required to match the Recipient&apos;s email address above</span>
        )}

        <label htmlFor='Message'>Message (optional)</label>
        <input
          type='text'
          placeholder='Message (up to 1000 chars)'
          {...register('Message', { required: false, maxLength: 1000, minLength: 0 })}
          aria-invalid={_.has(errors, ['Message']) ? 'true' : 'false'}
        />
        {_.has(errors, ['Message']) && <span role='alert'>This is required</span>}

        <SavingError error={addCardMutation.error} />
        <SavingError error={updateCardMutation.error} />
        <input disabled={isSubmitting} type='submit' value={isEditing ? 'Save gift card' : 'Add to cart'} />
      </form>
    </div>
  );
};

GiftCard.propTypes = {
  Index: PropTypes.string,
};

GiftCard.displayName = 'Gift-Cards';

export default GiftCard;
