import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { getUser, putUser } from '../../graphql/users';
import { doGraphqlQuery } from '../../fetcher';

const UserProfileHooks = () => {
  const [message, setMessage] = useState(undefined);
  const [user, setUser] = useState({
    name: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
  });

  useEffect(() => {
    const f = async () => {
      const profile = await doGraphqlQuery(getUser);
      const loadedUser = { ...user, ..._.pickBy(_.get(profile, ['data', 'getUser']), _.isString) };
      setUser(loadedUser);
    };
    f();
  }, []);

  const setUserProperty = (event) => {
    let key = _.get(event, 'target.name');
    let value = _.get(event, 'target.value');
    setUser(_.set({ ...user }, key, value));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let fields = ['name', 'address', 'address2', 'city', 'state', 'zip', 'phone'];
    let payload = _.pick(user, fields);

    await doGraphqlQuery(putUser, payload);
    // let newUser = {...this.state, ..._.pickBy(_.get(profile, ['data', 'getUser']), _.isString)};
    setMessage('Successfully saved user.');
    // this.setState({...newState, message: <b>Successfully saved profile!</b>});
    // TODO Fix API Response, & update state
  };

  const messageRendered = message ? <h4>{message}</h4> : undefined;

  return (
    <div>
      <h3>User Profile</h3>
      {messageRendered}
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type='text' name='name' value={_.get(user, 'name')} onChange={setUserProperty} />
        </label>
        <br />
        <label>
          Address:
          <input type='text' name='address' value={_.get(user, 'address')} onChange={setUserProperty} />
        </label>
        <br />
        <label>
          Address2:
          <input type='text' name='address2' value={_.get(user, 'address2')} onChange={setUserProperty} />
        </label>
        <br />
        <label>
          City:
          <input type='text' name='city' value={_.get(user, 'city')} onChange={setUserProperty} />
        </label>
        <br />
        <label>
          State:
          <input type='text' name='state' value={_.get(user, 'state')} onChange={setUserProperty} />
        </label>
        <br />
        <label>
          Zip:
          <input type='text' name='zip' value={_.get(user, 'zip')} onChange={setUserProperty} />
        </label>
        <br />
        <label>
          Phone:
          <input type='text' name='phone' value={_.get(user, 'phone')} onChange={setUserProperty} />
        </label>
        <br />
        <input type='submit' value='Submit' />
      </form>
    </div>
  );
};

export default UserProfileHooks;
