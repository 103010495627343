import React from 'react';
import PropTypes from 'prop-types';
import AdminActiveOrdersV2 from './AdminActiveOrdersV2';
import { Link } from 'gatsby';
import { OrderStatus } from '../../generated/types-and-hooks';
import AdminZebraPicker from './AdminZebraPicker';

const CURRENT_ROUTE = '/app/admin/all-orders-v2';

const AdminOrdersV2 = ({ Status }) => {
  const isSelected = (status) => status === Status;

  const { Rendered: zebraPickerRendered, StatusRendered: zebraStatusRendered, PrintShipments } = AdminZebraPicker();

  return (
    <div className='grid-x'>
      <h2 className='cell small-8'>Orders V2</h2>
      <p className='cell small-4' style={{ alignSelf: 'end' }}>
        <small>
          Displaying orders with status <code>{Status}</code>
        </small>
      </p>
      {zebraPickerRendered}
      <ul className='tabs cell small-8'>
        <li className='tabs-title is-active'>
          <Link to={`${CURRENT_ROUTE}/${OrderStatus.Ready}`} aria-selected={isSelected(OrderStatus.Ready)}>
            New
          </Link>
        </li>
        <li className='tabs-title'>
          <Link to={`${CURRENT_ROUTE}/${OrderStatus.Scheduled}`} aria-selected={isSelected(OrderStatus.Scheduled)}>
            Scheduled
          </Link>
        </li>
        <li className='tabs-title'>
          <Link to={`${CURRENT_ROUTE}/${OrderStatus.Fulfillment}`} aria-selected={isSelected(OrderStatus.Fulfillment)}>
            In Fulfillment
          </Link>
        </li>
        <li className='tabs-title'>
          <Link to={`${CURRENT_ROUTE}/${OrderStatus.PartiallyShipped}`} aria-selected={isSelected(OrderStatus.Shipped)}>
            Partially Shipped
          </Link>
        </li>
        <li className='tabs-title'>
          <Link to={`${CURRENT_ROUTE}/${OrderStatus.Shipped}`} aria-selected={isSelected(OrderStatus.Shipped)}>
            Shipped
          </Link>
        </li>
        <li className='tabs-title'>
          <Link to={`${CURRENT_ROUTE}/${OrderStatus.Declined}`} aria-selected={isSelected(OrderStatus.Declined)}>
            Declined
          </Link>
        </li>
        <li className='tabs-title'>
          <Link to={`${CURRENT_ROUTE}/${OrderStatus.Cancelled}`} aria-selected={isSelected(OrderStatus.Downloaded)}>
            Cancelled
          </Link>
        </li>
      </ul>
      <AdminActiveOrdersV2
        Status={Status}
        ScanIndexForward={false}
        PrintShipments={PrintShipments}
        zebraStatusRendered={zebraStatusRendered}
      />
    </div>
  );
};

AdminOrdersV2.propTypes = {
  Status: PropTypes.string,
};

AdminOrdersV2.defaultProps = {};

export default AdminOrdersV2;
