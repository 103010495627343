import _ from 'lodash';
import React, { Fragment } from 'react';
import { navigate, Link, graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { formatCurrency } from '../../utils/cart';
import Button from '../Button';
import CartItem from './CartItem';
import CartGiftCard from './CartGiftCard';
import { useCheckoutContext } from './hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { setCartProductQuantityWithCheckoutReview, removeGiftCardCheckout } from '../../graphql/cart';
import log from 'loglevel';
import LoadingError from '../LoadingError';
import SavingError from '../SavingError';
import { Helmet } from 'react-helmet';
import { doGraphqlQuery } from '../../fetcher';

const PLANT_TYPE_PLUGTRAY = 'PLUGTRAY';

const setCartProductQuantity = async ({ Id, NewQuantity }) => {
  const result = await doGraphqlQuery(setCartProductQuantityWithCheckoutReview, {
    Id,
    Quantity: NewQuantity,
    PlantType: PLANT_TYPE_PLUGTRAY,
  });
  log.debug({ result }, 'got setCartProductQuantity result');
  return result.data;
};

const rmGiftCard = async ({ Index }) => {
  const result = await doGraphqlQuery(removeGiftCardCheckout, {
    Index,
  }
  );
  log.debug({ result }, 'got removeGiftCardCheckout result');
  return result.data;
};

const CartMarkup = ({ componentText }) => {
  const { frontmatter } = componentText;
  const queryClient = useQueryClient();
  const checkoutContext = useCheckoutContext();

  const { Tax, Total, Subtotal, GiftCardSubtotal } = _.get(checkoutContext, ['data']) || {};

  const beginCheckout = async () => {
    navigate('/app/checkout/shipping-address');
  };

  const quantityChangeMutation = useMutation(setCartProductQuantity, {
    onSuccess: (data) => {
      log.debug({ data }, 'quantityChangeMutation: onSuccess');
      queryClient.setQueryData(['checkout'], data.CheckoutV1);
      queryClient.setQueryData(['cart'], data.CheckoutV1.Cart);
    },
  });

  const giftCardRemoveMutation = useMutation(rmGiftCard, {
    onSuccess: (data) => {
      log.debug({ data }, 'giftCardRemoveMutation: onSuccess');
      queryClient.setQueryData(['checkout'], data.CheckoutV1);
      queryClient.setQueryData(['cart'], data.CheckoutV1.Cart);
    },
  });

  const Products = _.get(checkoutContext, ['data', 'Cart', 'Products']) || [];
  const renderedProducts = _.map(Products, ({ Id, ...props }) =>
    CartItem({
      Id,
      ...props,
      setCartQuantity: (NewQuantity) => {
        if (typeof NewQuantity === 'number' && NewQuantity >= 0) {
          quantityChangeMutation.mutate({ Id, NewQuantity });
        } else {
          log.error({ Id, ...props, NewQuantity }, 'skipping setting new cart quantity');
        }
      },
    })
  );
  const productQuantitiesValidated = _.sum(
    _.map(_.get(checkoutContext, ['data', 'Cart', 'Products']), ({ QuantityValidated }) => QuantityValidated)
  );

  const GiftCards = _.get(checkoutContext, ['data', 'Cart', 'GiftCards']) || [];
  const renderedGiftCards = _.map(GiftCards, ({ Index, ...props }) =>
    CartGiftCard({ Index, ...props, removeGiftCard: () => giftCardRemoveMutation.mutate({ Index }) })
  );

  const checkoutItems = productQuantitiesValidated + _.size(GiftCards);
  const canCheckout = checkoutItems > 0;
  return (
    <Fragment>
      <Helmet title='Shopping Cart' />
      <ul className='breadcrumbs'>
        <li>
          <Link to='/'>&lt; Back to Plug Trays home</Link>
        </li>
      </ul>
      <h1>
        Shopping Cart{' '}
        <small>
          ({checkoutItems} {checkoutItems > 1 ? 'items' : 'item'})
        </small>
      </h1>
      <LoadingError error={checkoutContext.error} />
      <SavingError error={quantityChangeMutation.error} />
      <SavingError error={giftCardRemoveMutation.error} />
      <div className='grid-x grid-margin-x'>
        <div className='cell small-12 medium-8'>
          <div className='grid-x grid-margin-x show-for-large product-table--header__faux'>
            <div className='cell large-6'>Item details</div>
            <div className='cell large-1 currency-header'>Price</div>
            <div className='cell large-3 table-header__center'>Quantity</div>
            <div className='cell large-2 currency-header'>Total</div>
          </div>
          {renderedProducts}
          {renderedGiftCards}
          <div className='grid-x grid-margin-x grid-padding-y grid-margin-y'>
            <div className='cell small-12 step-bar'>
              <div className='step-bar--subtotal'>
                <p>Subtotal</p>
                <p className='currency'>{formatCurrency(Subtotal + GiftCardSubtotal)}</p>
              </div>
              {productQuantitiesValidated % 2 === 1 && (
                <div className='callout primary'>
                  <strong>{frontmatter.free_shipping_text}</strong>
                </div>
              )}
              <Button
                style='primary expanded'
                onClickHandler={beginCheckout}
                isDisabled={!canCheckout}
                text='Check out'
              />
            </div>
          </div>
        </div>
        <div className='cell small-12 medium-4 order-summary--container__sticky'>
          <div className='grid-x grid-padding-x grid-padding-y order-summary--container'>
            <div className='cell small-12'>
              <h4 className='header__small-caps'>Order summary</h4>
              <p className='order-summary--item'>
                Subtotal: <span className='currency'>{formatCurrency(Subtotal + GiftCardSubtotal)}</span>
              </p>
              <p className='order-summary--item'>
                Estimated tax: <span className='currency'>{formatCurrency(Tax)}</span>
              </p>
              <p className='order-summary--item'>
                Estimated shipping:
                <span className='currency'>--</span>
              </p>

              <p className='order-summary--item'>
                <strong>
                  Estimated total: <span className='currency'>{formatCurrency(Total)}</span>
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const markdownQuery = graphql`
  query {
    markdownRemark(frontmatter: { component: { eq: "cart" } }) {
      html
      frontmatter {
        component
        free_shipping_text
      }
    }
  }
`;

const Cart = () => {
  const data = useStaticQuery(markdownQuery);

  return <CartMarkup componentText={data.markdownRemark} />;
};

CartMarkup.propTypes = {
  componentText: PropTypes.shape({
    frontmatter: PropTypes.shape({
      free_shipping_text: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

CartMarkup.defaultProps = {};

export default Cart;
