import React, { useEffect, useState } from 'react';
import { useAdminGetBackorderReportQuery, useGetShipmentReportQuery } from '../../generated/types-and-hooks';
import { stringify } from 'csv-stringify/browser/esm/sync';
import _ from 'lodash';
import log from 'loglevel';
import LoadingError from '../LoadingError';

const downloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob([data], { type: fileType });
  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export const Reports = () => {
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [fetchShipmentReportEnabled, setFetchShipmentReportEnabled] = useState<boolean>(false);
  const backorderReportContext = useAdminGetBackorderReportQuery();
  const shipmentReportContext = useGetShipmentReportQuery(
    {
      StartTime: new Date(startDate).valueOf() / 1000,
      EndTime: new Date(endDate).valueOf() / 1000,
    },
    {
      enabled: fetchShipmentReportEnabled,
    }
  );
  const headers = new Set<string>();
  const data: any[] = [];
  for (const productBackorder of backorderReportContext.data?.GetBackorderReport || []) {
    const productData = { ProductId: productBackorder.Id };
    for (const weekBackorder of productBackorder.Backorders) {
      if (!headers.has(weekBackorder.YearWeek)) {
        headers.add(weekBackorder.YearWeek);
      }
      productData[weekBackorder.YearWeek] = weekBackorder.Quantity;
    }
    data.push(productData);
  }
  const headersSorted = _.sortBy(Array.from(headers), [
    (header) => header.split('-')[0],
    (header) => header.split('-')[1],
  ]);

  const downloadReport = (e) => {
    e.preventDefault();
    downloadFile({
      data: stringify(data, {
        header: true,
        columns: ['ProductId', ...headersSorted],
        quoted_string: true,
      }),
      fileName: `backorder-report-${new Date().toLocaleDateString().replace(/\//g, '-')}.csv`,
      fileType: 'text/csv',
    });
  };

  const downloadReportRendered = !backorderReportContext.isFetched ? (
    <p>Loading...</p>
  ) : (
    <button onClick={downloadReport}>Download Backorder Report</button>
  );

  const generateShipmentsReport = async (e) => {
    e.preventDefault();
    setFetchShipmentReportEnabled(true);
  };

  useEffect(() => {
    if (fetchShipmentReportEnabled && shipmentReportContext.isSuccess && !shipmentReportContext.isStale) {
      setFetchShipmentReportEnabled(false);
      log.debug('need to generate report');

      const shipmentData = shipmentReportContext.data.GetShipmentReport.map((shipment) => ({
        ...shipment,
        ShipmentTimestampCreated: new Date(shipment?.ShipmentTimestampCreated! * 1000).toISOString(),
      }));
      downloadFile({
        data: stringify(shipmentData, {
          header: true,
          columns: [
            'OrderId',
            'InvoiceId',
            'OrderStatus',
            'UpsShipmentId',
            'CustomerBilledTotal',
            'ShipmentStatus',
            'ShipmentType',
            'ShipmentTotal',
            'ShipmentTimestampCreated',
            'UpsTrackingNumber',
          ],
          quoted_string: true,
        }),
        fileName: `shipment-report-${new Date().toLocaleDateString().replace(/\//g, '-')}.csv`,
        fileType: 'text/csv',
      });
      setFetchShipmentReportEnabled(false);
    }
  }, [shipmentReportContext.data, fetchShipmentReportEnabled]);

  return (
    <div>
      <h1>Reports</h1>
      {downloadReportRendered}

      <div>
        <h2>UPS Shipments Export</h2>
        <LoadingError error={shipmentReportContext.error} />
        <label>
          Start Date:
          <input type='date' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </label>
        <label>
          End Date:
          <input type='date' value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </label>
        <button onClick={generateShipmentsReport} disabled={!startDate || !endDate || fetchShipmentReportEnabled}>
          Generate Report
        </button>
      </div>
    </div>
  );
};

export default Reports;
