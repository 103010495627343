import React, { useState, useEffect } from 'react';
import TextInput from './TextInput';
import { saveEmail } from '../graphql/users';
import PropTypes from 'prop-types';
import { doGraphqlQuery } from '../fetcher';

const EmailOptOut = ({ email }) => {
  const [didOptOut, setDidOptOut] = useState(false);
  const [Email, EmailRendered, SetEmail] = TextInput({
    labelText: 'Email address',
    type: 'email',
    required: true,
    placeholderText: 'you@email.com',
    helpText: 'We\'ll never sell or spam your email.',
  });

  useEffect(() => {
    if (email) {
      SetEmail(email);
    }
  }, [email]);

  const handleSubmitOptOut = async (event) => {
    event.preventDefault();
    await doGraphqlQuery(saveEmail, { Email, OptOut: true });
    setDidOptOut(true);
  };
  const handleSubmitOptIn = async (event) => {
    event.preventDefault();
    await doGraphqlQuery(saveEmail, { Email, OptOut: false });
    setDidOptOut(false);
  };

  const optBackInRendered = (
    <div>
      <p>You have been opted out of all future emails. If this was a mistake, click below to opt into future emails.</p>
      <button onClick={handleSubmitOptIn} type='button' className='button'>
        Opt-In
      </button>
    </div>
  );

  return (
    <div>
      <h1>Opt-Out</h1>
      <form onSubmit={handleSubmitOptOut}>
        {EmailRendered}
        <input className='button' type='submit' value='Opt Out' />
      </form>
      {didOptOut ? optBackInRendered : undefined}
    </div>
  );
};

EmailOptOut.propTypes = {
  email: PropTypes.string,
};

export default EmailOptOut;
