import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { States } from '../../utils/states';
import { useQueryClient } from '@tanstack/react-query';
import log from 'loglevel';
import LoadingError from '../LoadingError';
import SavingError from '../SavingError';
import { navigate } from 'gatsby-link';
import { useAdminSaveWarrantyStatesMutation, useGetWarrantyStatesV1Query } from '../../generated/types-and-hooks';

enum WarrantyRadioState {
  UnderWarranty,
  RequireImmediateShipping,
  HoldOrders,
}

const AdminWarrantyStates = () => {
  const queryClient = useQueryClient();
  const [initialData, setInitialData] = useState();
  const [defaultWarrantyStatesSelected, setDefaultWarrantyStatesSelected] = useState([]);

  const warrantyStatesContext = useGetWarrantyStatesV1Query();
  const saveWarrantyStatesMutation = useAdminSaveWarrantyStatesMutation({
    onSuccess: (data) => {
      log.debug({ data }, 'save warranty states onSuccess');
      queryClient.invalidateQueries({ queryKey: ['getWarrantyStates'] });
      queryClient.invalidateQueries({ queryKey: ['GetWarrantyStatesV1'] });
      navigate('/app/admin');
    },
  });

  const radioGroup = (Name, ShortName, i) => {
    const [selectedWarrantyStatus, setSelectedWarrantyStatus] = useState<WarrantyRadioState>();
    const zebraStyle = i % 2 == 0 ? { backgroundColor: '#e8e8e8' } : {};
    const rendered = (
      <tr style={zebraStyle} key={Name}>
        <td>
          <div style={{ padding: '0.75rem' }}>{Name}</div>
        </td>
        <td>
          <label style={{ padding: '0.75rem' }}>
            <input
              type='radio'
              style={{ width: 'auto', marginBottom: 0 }}
              checked={selectedWarrantyStatus === WarrantyRadioState.UnderWarranty}
              onChange={() => setSelectedWarrantyStatus(WarrantyRadioState.UnderWarranty)}
            />
            Apply warranty to all orders
          </label>
        </td>
        <td>
          <label style={{ padding: '0.75rem' }}>
            <input
              type='radio'
              style={{ width: 'auto', marginBottom: 0 }}
              checked={selectedWarrantyStatus === WarrantyRadioState.HoldOrders}
              onChange={() => setSelectedWarrantyStatus(WarrantyRadioState.HoldOrders)}
            />
            Hold orders until safe to send
          </label>
        </td>
        <td>
          <label style={{ padding: '0.75rem' }}>
            <input
              type='radio'
              style={{ width: 'auto', marginBottom: 0 }}
              checked={selectedWarrantyStatus === WarrantyRadioState.RequireImmediateShipping}
              onChange={() => setSelectedWarrantyStatus(WarrantyRadioState.RequireImmediateShipping)}
            />
            Require immediate shipping on all orders
          </label>
        </td>
      </tr>
    );

    return [selectedWarrantyStatus, rendered, setSelectedWarrantyStatus];
  };

  useEffect(() => {
    if (warrantyStatesContext.data?.GetWarrantyStatesV1.WarrantyStates) {
      let i = 0;
      _.map(States, (Name, ShortName) => {
        // const theStatesState = stateRatioButtonsState[i];
        const theState = stateRadioButtons[i];
        const [, , setTheStatesWarrantyState] = theState;
        const radioState = getRadioStateForState(ShortName);
        setTheStatesWarrantyState(radioState);
        i++;
      });
    }
  }, [warrantyStatesContext.data]);

  const getRadioStateForState = (ShortName) => {
    const UnderWarranty = warrantyStatesContext.data?.GetWarrantyStatesV1.WarrantyStates.includes(ShortName);
    const WaiverRequired = warrantyStatesContext.data?.GetWarrantyStatesV1.WaiverRequiredStates.includes(ShortName);
    if (UnderWarranty) {
      return WarrantyRadioState.UnderWarranty;
    }
    if (WaiverRequired) {
      return WarrantyRadioState.RequireImmediateShipping;
    }
    return WarrantyRadioState.HoldOrders;
  };

  const stateRadioButtons = _.map(Object.entries(States), ([Name, ShortName], i) => radioGroup(Name, ShortName, i));
  const stateRadioButtonsRendered = _.map(stateRadioButtons, ([, rendered]) => rendered);

  const stateRatioButtonsState = _.map(stateRadioButtons, ([selectedWarrantyStatus]) => selectedWarrantyStatus);

  const submit = (e) => {
    e.preventDefault();
    const WarrantyStates = [];
    const WaiverRequiredStates = [];
    let i = 0;
    _.map(States, (Name, ShortName) => {
      const theStatesState = stateRatioButtonsState[i];
      switch (theStatesState) {
        case WarrantyRadioState.UnderWarranty:
          WarrantyStates.push(ShortName);
          break;
        case WarrantyRadioState.RequireImmediateShipping:
          WaiverRequiredStates.push(ShortName);
          break;
      }
      i++;
    });
    saveWarrantyStatesMutation.mutate({ WarrantyStates, WaiverRequiredStates });
  };

  if (warrantyStatesContext.isLoading) {
    return (
      <div>
        <h3>Manage shipping warranty</h3>
        <i>Loading.......</i>
      </div>
    );
  }

  return (
    <div>
      <h3>Manage shipping warranty</h3>
      <div className='tab-content'>
        <p>Explainer content here</p>
        <hr />
        <LoadingError error={warrantyStatesContext.error} />
        <SavingError error={saveWarrantyStatesMutation.error} />
        <div className='grid-x'>
          {/* <div className="cell small-8">
              <label style={{ marginRight: '3rem' }}>
                <input 
                  type="checkbox"
                  value="states-under-warranty"
                  ref={warrantyStatesCheckbox} 
                  defaultChecked={false}
                  // onChange = {warrantyStatesCheckbox.current.checked ? selectNone : selectAll} 
                  />
                Select all
              </label>
            </div>
            <div className="cell small-2">
              <a
                // onClick={selectReset}
                style={{ color: '#ce4e27', fontFamily: 'Poppins Semibold', fontSize: '14px'}}>
                Reset to last saved version
              </a>
            </div> */}
          <div className='cell small-offset-10 small-2'>
            <input
              form='states-under-warranty-form'
              type='submit'
              className='button secondary no-span'
              style={{ float: 'right' }}
              value='Save States'
              disabled={saveWarrantyStatesMutation.isLoading}
            />
          </div>
        </div>
        <form onSubmit={submit} id='states-under-warranty-form'>
          <SavingError error={saveWarrantyStatesMutation.error} />
          <table className='striped' style={{ marginTop: '2rem' }}>
            <tbody>{stateRadioButtonsRendered}</tbody>
          </table>
        </form>
      </div>
    </div>
  );
};

export default AdminWarrantyStates;
