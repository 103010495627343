import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const WaiveWarrantyText = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          markdownRemark(frontmatter: { component: { eq: "waiveWarrantyText" } }) {
            html
            frontmatter {
              component
            }
          }
        }
      `}
      render={(data) => <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>}
    />
  );
};

export default WaiveWarrantyText;
