import _ from 'lodash';
import React, { useState, useEffect, Fragment } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Modal from 'react-modal';
import TextInput, { validatePhone } from '../TextInput';
import { navigate } from 'gatsby';
import { saveShippingAddress, validateAddress } from '../../graphql/cart';
import StateInput from '../StateInput';
import Button from '../Button';
import CartCheckoutOrderSummary from './CartCheckoutOrderSummary';
import { useMyOrders, useCheckoutContext } from './hooks';
import log from 'loglevel';
import SavingError from '../SavingError';
import LoadingError from '../LoadingError';
import { doGraphqlQuery } from '../../fetcher';

const postShippingAddress = async (shippingPayload) => {
  // throw {errors: [{message:'one error'}, {message:'another error'}]};
  const result = await doGraphqlQuery(saveShippingAddress, shippingPayload);
  log.debug({ result }, 'posted Shipping address with result');
  return result.data;
};

const postValidateAddress = async (validationPayload) => {
  // throw {errors: [{message:'post validate address error'}, {message:'another error'}]};
  const validateResult = await doGraphqlQuery(validateAddress, validationPayload);
  log.debug({ validateResult }, 'doValidateAddress got result');
  return validateResult.data.ValidateAddress;
};

const CartCheckoutShippingAddress = () => {
  const queryClient = useQueryClient();
  const checkoutContext = useCheckoutContext();

  const [addressAsEntered, setAddressAsEntered] = useState(undefined);
  const [chosenAddress, setChosenAddress] = useState(undefined);
  const [hidePrevAddress, setHidePrevAddress] = useState(undefined);

  const [validationVisible, setValidationVisible] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  const submitMutation = useMutation(postShippingAddress, {
    onSuccess: (data) => {
      log.info({ data }, 'postShippingAddress, got data');
      queryClient.setQueryData(['checkout'], data.CheckoutV1);
      navigate('/app/checkout/shipping');
    },
    onError: (err) => {
      log.error({ err }, 'got error submitting shipping address');
      closeModal();
    },
  });
  const validationMutation = useMutation(postValidateAddress, {
    onSuccess: () => {
      openModal();
    },
  });

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const [FirstName, FirstNameRendered, SetFirstName] = TextInput({
    validationVisible,
    labelText: 'First name',
    required: true,
  });
  const [LastName, LastNameRendered, SetLastName] = TextInput({
    validationVisible,
    labelText: 'Last name',
    required: true,
  });
  const [Company, CompanyRendered, SetCompany] = TextInput({
    validationVisible,
    labelText: 'Company (optional)',
  });
  const [Address, AddressRendered, SetAddress] = TextInput({
    validationVisible,
    labelText: 'Street address',
    required: true,
    helpText: 'Please note: We cannot ship to post office boxes.',
  });
  const [Address2, Address2Rendered, SetAddress2] = TextInput({
    validationVisible,
    labelText: 'Street address 2 (optional)',
    placeholderText: 'Apartment or unit number',
  });
  const [City, CityRendered, SetCity] = TextInput({
    validationVisible,
    labelText: 'City',
    required: true,
  });
  const [State, StateRendered, SetState] = StateInput({
    labelText: 'State',
    required: true,
  });
  const [Zip, ZipRendered, SetZip] = TextInput({
    validationVisible,
    labelText: 'Zip',
    validationPattern: '^[0-9]{5}(?:-[0-9]{4})?$',
    required: true,
  });
  const [Phone, PhoneRendered, SetPhone] = TextInput({
    validationVisible,
    labelText: 'Phone number',
    required: true,
    validator: validatePhone,
    validationPattern: '[0-9]{3}-[0-9]{3}-[0-9]{4}',
    placeholderText: '(555)555-5555',
  });

  const [Email, EmailRendered, SetEmail] = TextInput({
    validationVisible,
    labelText: 'Email address',
    type: 'email',
    required: true,
    placeholderText: 'you@email.com',
    helpText: 'We\'ll never sell or spam your email.',
  });

  useEffect(() => {
    // Sets state of page from the loaded API requests
    // This runs after every render, be careful!
    const setFormData = _.omitBy(
      { FirstName, LastName, Company, Address, Address2, City, Zip, Phone, Email },
      _.isEmpty
    );
    const shipping = _.get(checkoutContext, ['data', 'Cart', 'Shipping']) || {};
    if (_.isEmpty(setFormData) && !_.isEmpty(shipping)) {
      log.debug({ shipping }, 'shipping data has already been typed in, populating form with it');
      SetFirstName(shipping.FirstName);
      SetLastName(shipping.LastName);
      SetCompany(shipping.Company);
      SetAddress(shipping.Address);
      SetAddress2(shipping.Address2);
      SetCity(shipping.City);
      SetState(shipping.State);
      SetZip(shipping.Zip);
      SetPhone(shipping.Phone);
      SetEmail(shipping.Email);
    }
  });

  const renderAddr = (
    // eslint-disable-next-line
    { Address, Address2, City, State, Zip, defaultChecked },
    index = 0
  ) => {
    const handleClick = () => {
      SetAddress(Address);
      SetAddress2(Address2);
      SetCity(City);
      SetState(State);
      SetZip(Zip);
      setChosenAddress(`${Address} ${Address2} ${City} ${State} ${Zip}`);
    };

    return (
      <label
        key={index + 1}
        className={`address-verification ${
          chosenAddress === `${Address} ${Address2} ${City} ${State} ${Zip}` ? 'checked' : ''
        }`}
      >
        <input
          type='radio'
          value={`${Address} ${Address2} ${City} ${State} ${Zip}`}
          name='verified-shipping-address'
          onClick={handleClick}
          defaultChecked={defaultChecked}
          data-testid={`address-${index}`}
        />
        <p>{Address}</p>
        {Address2 != null ? <p>{Address2}</p> : ''}
        <p>
          {City}, {State} {Zip}
        </p>
      </label>
    );
  };

  const addressVerificationRendered = modalIsOpen ? (
    <div className='grid-x grid-margin-x'>
      {validationMutation.isLoading ? (
        <em>Verifying address...</em>
      ) : (
        <>
          <h3 className='cell small-12'>Confirm your address</h3>
          <p className='cell small-12'>
            {validationMutation.data.length === 0
              ? 'We could not verify your address. Please review the information you entered to make sure it looks correct.'
              : 'We found verified addresses matching the information you entered. Please choose one of the following options.'}
          </p>
          <div className={`cell small-12 ${validationMutation.data.length === 0 ? '' : 'medium-6'}`}>
            <fieldset>
              <legend className='header__small-caps'>Address you entered</legend>
              {renderAddr({ ...addressAsEntered, defaultChecked: true })}
            </fieldset>
          </div>
          {validationMutation.data.length === 0 ? undefined : (
            <div className='cell small-12 medium-6'>
              <fieldset>
                <legend className='header__small-caps'>Verified addresses found</legend>
                {_.map(validationMutation.data, renderAddr)}
              </fieldset>
            </div>
          )}
        </>
      )}
    </div>
  ) : undefined;

  const doValidateAddress = async (event) => {
    event.preventDefault();
    const validatePayload = {
      Address: {
        Address,
        Address2,
        City,
        State,
        Zip,
      },
    };
    setAddressAsEntered({
      Address,
      Address2,
      City,
      State,
      Zip,
    });
    setChosenAddress(`${Address} ${Address2} ${City} ${State} ${Zip}`);
    validationMutation.mutate(validatePayload);
  };

  const submit = async (event) => {
    event.preventDefault();
    const fieldValues = [FirstName, LastName, Company, Address, Address2, City, State, Zip, Phone, Email];
    let passedValidation = true;
    _.map(fieldValues, (value) => {
      if (_.isUndefined(value)) {
        passedValidation = false;
      }
    });
    if (!passedValidation) {
      setValidationVisible(true);
      return;
    }

    const shippingPayload = {
      FirstName,
      LastName,
      Company,
      Address,
      Address2,
      City,
      State,
      Zip,
      Phone,
      Email,
    };
    submitMutation.mutate(shippingPayload);
  };

  Modal.setAppElement('#___gatsby');

  const myOrders = useMyOrders();
  const handleUseLastOrderData = (e) => {
    e.preventDefault();

    const lastOrder = _.last(myOrders.data);
    const shipping = lastOrder.Shipping;

    if (!_.isEmpty(shipping)) {
      SetFirstName(shipping.FirstName);
      SetLastName(shipping.LastName);
      SetCompany(shipping.Company);
      SetAddress(shipping.Address);
      SetAddress2(shipping.Address2);
      SetCity(shipping.City);
      SetState(shipping.State);
      SetZip(shipping.Zip);
      SetPhone(shipping.Phone);
      SetEmail(shipping.Email);
      setHidePrevAddress(true);
    }
  };

  const showPreviousAddress = () => {
    const lastOrder = _.last(myOrders.data);
    const shipping = lastOrder.Shipping;
    return (
      <div className='user-info-summary__previous-order'>
        <p>
          {shipping.FirstName} {shipping.LastName}
        </p>
        {shipping.Company && <p>{shipping.Company}</p>}
        <p>{shipping.Address}</p>
        {shipping.Address2 && <p>{shipping.Address2}</p>}
        <p>
          {shipping.City}, {shipping.State} {shipping.Zip}
        </p>
        <p>
          <i className='fi-telephone' /> {shipping.Phone}
        </p>
        <p>
          <i className='fi-mail' /> {shipping.Email}
        </p>
      </div>
    );
  };

  const previousOrdersRendered =
    _.size(myOrders.data) === 0 ? undefined : (
      <div className={`cell small-12 callout primary ${hidePrevAddress ? 'hide' : undefined}`}>
        <p>
          <strong>You used this address last time you ordered.</strong>
        </p>
        {showPreviousAddress()}
        <Button style='secondary' onClickHandler={handleUseLastOrderData} text='Use this address' />
      </div>
    );

  return (
    <Fragment>
      <div className='grid-x grid-margin-x'>
        <LoadingError error={checkoutContext.error} />
        <form onSubmit={doValidateAddress} className='cell small-12 medium-8'>
          <fieldset className='grid-x grid-padding-x fieldset--center-legend'>
            <legend className='header__small-caps'>Shipping address</legend>
            <div className='grid-x grid-margin-x'>
              {previousOrdersRendered}
              <div className='cell small-12 medium-6'>{FirstNameRendered}</div>
              <div className='cell small-12 medium-6'>{LastNameRendered}</div>
              <div className='cell small-12'>
                {CompanyRendered}
                {AddressRendered}
                {Address2Rendered}
              </div>
              <div className='cell small-8'>{CityRendered}</div>
              <div className='cell small-4'>{StateRendered}</div>
              <div className='cell small-12 medium-4'>{ZipRendered}</div>
            </div>
          </fieldset>
          <fieldset className='grid-x grid-padding-x fieldset--center-legend'>
            <legend className='header__small-caps'>Shipping contact information</legend>
            <div className='grid-x grid-margin-x'>
              <div className='cell small-12 medium-6'>{PhoneRendered}</div>
              <div className='cell small-12'>{EmailRendered}</div>
            </div>
          </fieldset>
          <div className='grid-x grid-padding-x'>
            <Button
              testId='submit-shipping-address'
              isDisabled={validationMutation.isLoading || submitMutation.isLoading}
              style={validationMutation.isLoading ? 'primary expanded loading' : 'primary expanded'}
              type='submit'
              text='Choose shipping options'
            />
            <br />
            <SavingError error={submitMutation.error} />
            <SavingError error={validationMutation.error} />
          </div>
        </form>
        <div className='cell small-12 medium-4'>
          <CartCheckoutOrderSummary {..._.get(checkoutContext, 'data')} />
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        contentLabel='Address verification modal'
        className='modal'
        onRequestClose={closeModal}
      >
        <div className='grid-x'>
          <div className='cell small-12 modal--close'>
            <button className='button transparent' onClick={closeModal}>
              <i className='fi-x' /> close
            </button>
          </div>
        </div>
        {addressVerificationRendered}
        <Button
          testId='submit-verified-address'
          isDisabled={submitMutation.isLoading}
          style={submitMutation.isLoading ? 'secondary expanded loading' : 'secondary expanded'}
          onClickHandler={submit}
          text='Use this address'
        />
      </Modal>
    </Fragment>
  );
};

export default CartCheckoutShippingAddress;
