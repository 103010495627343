import _ from 'lodash';
import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { Link } from 'gatsby';
import Loading from '../Loading';
import CheckboxInput from '../CheckboxInput';
import log from 'loglevel';
import {
  AcornProduct,
  useAdminGetProductsQuery,
  useAdminSaveProductBackorderLimitMutation,
  useAdminSaveProductInStockMutation,
  useAdminSaveProductInStockPlugtraysMutation,
  useSaveProductPlugtraysPriceMutation,
  useSaveProductPriceMutation,
} from '../../generated/types-and-hooks';
import { validateNumber, validateNumberWithPeriod } from '../../utils/input';
import { useTable, useSortBy, Column } from 'react-table';

const isNotDiscontinued = ({ DisplayMVG, DisplayPlugtrays }: AcornProduct): boolean => DisplayMVG! || DisplayPlugtrays!;

const BooleanCell = ({ value }) => (value ? '✔' : '');

const convertProduct = (product: AcornProduct) => {
  const DisplayName = !product.BotanicalName ? product.Name : `${product.BotanicalName} (${product.CommonName})`;
  return {
    ...product,
    DisplayName,
  };
};

const EditableCell = ({
  value: initialValue,
  column,
  row,
  updatePlugtraysStock,
  updatePlugtraysPrice,
  updateMVGPrice,
  updateMVGInStock,
  updateMVGBackorderLimit,
}) => {
  const { Id } = row.values;
  const { id: fieldChanged } = column;

  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    switch (fieldChanged) {
      case 'InStockPlugtrays':
        return updatePlugtraysStock({ Id, InStockPlugtrays: Number(value) });
      case 'PricePlugtrays':
        if (Number(value) > 0) {
          return updatePlugtraysPrice({ Id, PricePlugtrays: Number(value) });
        }
        return;
      case 'Price':
        if (Number(value) > 0) {
          return updateMVGPrice({ Id, Price: Number(value) });
        }
        return;
      case 'InStock':
        return updateMVGInStock({ Id, InStock: Number(value) });
      case 'BackorderLimit':
        return updateMVGBackorderLimit({ Id, BackorderLimit: Number(value) });
    }
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return <input value={value} onChange={onChange} onBlur={onBlur} />;
};

const ProductIdLinkCell = ({ value: ProductId }) => (
  <a onClick={() => window.open(`/app/admin/products/${ProductId}`, '_blank')}>{ProductId.toLowerCase()}</a>
);

function Table({
  columns,
  data,
  updatePlugtraysStock,
  updatePlugtraysPrice,
  updateMVGPrice,
  updateMVGInStock,
  updateMVGBackorderLimit,
}) {
  const [showDiscontinuedProducts, showDiscontinuedProductsRendered] = CheckboxInput({
    labelText: 'Show discontinued products',
  });

  const filteredData = useMemo(
    () => (showDiscontinuedProducts ? data : data.filter(isNotDiscontinued)),
    [data, showDiscontinuedProducts]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    getToggleHideAllColumnsProps,
    allColumns,
    setHiddenColumns,
  } = useTable(
    {
      columns,
      data: filteredData,
      updatePlugtraysStock,
      updatePlugtraysPrice,
      updateMVGPrice,
      updateMVGInStock,
      updateMVGBackorderLimit,
      autoResetSortBy: false,
      initialState: {
        hiddenColumns: [
          'Price',
          'InStock',
          'DisplayMVG',
          'BackorderLimit',
          'PricePlugtrays',
          'InStockPlugtrays',
          'DisplayPlugtrays',
        ],
        sortBy: [{ id: 'DisplayName', desc: false }],
      },
    },
    useSortBy
  );

  const activatePlugtraysMode = () => {
    setHiddenColumns(['Price', 'InStock', 'DisplayMVG', 'BackorderLimit']);
  };

  const activateMVGMode = () => {
    setHiddenColumns(['PricePlugtrays', 'InStockPlugtrays', 'DisplayPlugtrays']);
  };

  return (
    <>
      <ul className='breadcrumbs'>
        <li>
          <Link to='/app/admin/'>Admin dashboard</Link>
        </li>
        <li>Manage products</li>
      </ul>
      <div style={{ marginBottom: '1rem' }}>
        <button onClick={activatePlugtraysMode} className='button secondary no-span'>
          Edit plug trays
        </button>
        <button onClick={activateMVGMode} className='button primary no-span'>
          Edit pots
        </button>
        {showDiscontinuedProductsRendered}
      </div>

      <table {...getTableProps()}>
        <thead style={{ position: 'sticky', top: '0' }}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting.
                <th {...column.getHeaderProps(column.getSortByToggleProps())} className={column.className}>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                        },
                      ])}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

function AdminProducts() {
  const productsContext = useAdminGetProductsQuery();
  const savePlugtraysStockMutation = useAdminSaveProductInStockPlugtraysMutation();
  const savePlugtraysPriceMutation = useSaveProductPlugtraysPriceMutation();
  const saveMVGPriceMutation = useSaveProductPriceMutation();
  const saveMVGInStockMutation = useAdminSaveProductInStockMutation();
  const saveMVGBackorderLimitMutation = useAdminSaveProductBackorderLimitMutation();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Plant info',
        columns: [
          {
            Header: 'Id',
            accessor: 'Id',
            Cell: ProductIdLinkCell,
          },
          {
            Header: 'Type',
            accessor: 'Type',
          },
          {
            Header: 'Name',
            accessor: 'DisplayName',
          },
        ],
      },
      {
        Header: 'Plug trays',
        className: 'admin-products--plugtrays',
        columns: [
          {
            Header: 'Show',
            accessor: 'DisplayPlugtrays',
            className: 'admin-products--plugtrays admin-products--emoji',
            Cell: BooleanCell,
          },
          {
            Header: 'Price',
            accessor: 'PricePlugtrays',
            className: 'admin-products--plugtrays',
            Cell: EditableCell,
          },
          {
            Header: 'In Stock',
            accessor: 'InStockPlugtrays',
            className: 'admin-products--plugtrays',
            Cell: EditableCell,
          },
        ],
      },
      {
        Header: '3-inch pots',
        className: 'admin-products--pots',
        columns: [
          {
            Header: 'Show',
            accessor: 'DisplayMVG',
            className: 'admin-products--pots admin-products--emoji',
            Cell: BooleanCell,
          },
          {
            Header: 'Price',
            accessor: 'Price',
            className: 'admin-products--pots',
            Cell: EditableCell,
          },
          {
            Header: 'In Stock',
            accessor: 'InStock',
            className: 'admin-products--pots',
            Cell: EditableCell,
          },
          {
            Header: 'Backorder Limit',
            accessor: 'BackorderLimit',
            className: 'admin-products--pots',
            Cell: EditableCell,
          },
        ],
      },
    ],
    []
  );

  // const data = React.useMemo(() => makeData(2000), [])

  if (!productsContext.data) {
    return <Loading />;
  }

  return (
    <Table
      columns={columns}
      data={productsContext.data?.GetProducts?.products?.map(convertProduct)}
      updatePlugtraysStock={savePlugtraysStockMutation.mutateAsync}
      updatePlugtraysPrice={savePlugtraysPriceMutation.mutateAsync}
      updateMVGPrice={saveMVGPriceMutation.mutateAsync}
      updateMVGInStock={saveMVGInStockMutation.mutateAsync}
      updateMVGBackorderLimit={saveMVGBackorderLimitMutation.mutateAsync}
    />
  );
}

AdminProducts.propTypes = {};

AdminProducts.defaultProps = {};

export default AdminProducts;
