import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import LoadingError from '../LoadingError';
import { useAllTags } from '../hooks';
import { useAdminGetSiteSettingsQuery, useAdminSaveSiteSettigsMutation } from '../../generated/types-and-hooks';
import log from 'loglevel';
import SavingError from '../SavingError';
import { useQueryClient } from '@tanstack/react-query';

const AdminSiteSettings = () => {
  const queryClient = useQueryClient();
  const siteSettingsContext = useAdminGetSiteSettingsQuery();
  const saveSiteSettingsMutation = useAdminSaveSiteSettigsMutation({
    onSuccess: (data) => {
      log.debug({ data }, 'finished saving site settings');
      queryClient.invalidateQueries({ queryKey: ['AdminGetSiteSettings'] });
    },
    onError: (error) => {
      log.error({ error });
    },
  });

  const tagsContext = useAllTags();

  const [hasInitOccurred, setHasInitOccurred] = useState(false);
  const [hideBackorders, setHideBackorders] = useState(false);
  const [quickbooksProductSalesOfIncomeAccountId, setQuickbooksProductSalesOfIncomeAccountId] = useState<string>('');
  const [quickbooksGiftCardProductId, setQuickbooksGiftCardProductId] = useState<string>('');
  const [quickbooksRefundDepositToAccountId, setQuickbooksRefundDepositToAccountId] = useState<string>('');

  useEffect(() => {
    if (!hasInitOccurred && !siteSettingsContext.data) return;

    setHideBackorders(siteSettingsContext.data!.GetSiteSettings.HideBackorders);
    setQuickbooksProductSalesOfIncomeAccountId(
      siteSettingsContext.data!.GetSiteSettings.QuickbooksProductSalesOfIncomeAccountId
    );
    setQuickbooksGiftCardProductId(siteSettingsContext.data!.GetSiteSettings.QuickbooksGiftCardProductId);
    setQuickbooksRefundDepositToAccountId(siteSettingsContext.data!.GetSiteSettings.QuickbooksRefundDepositToAccountId);
    setHasInitOccurred(true);
  }, [siteSettingsContext.data]);

  const doSave = () => {
    saveSiteSettingsMutation.mutate({
      HideBackorders: hideBackorders,
      QuickbooksProductSalesOfIncomeAccountId: quickbooksProductSalesOfIncomeAccountId,
      QuickbooksGiftCardProductId: quickbooksGiftCardProductId,
      QuickbooksRefundDepositToAccountId: quickbooksRefundDepositToAccountId,
    });
  };
  return (
    <div>
      <h3>Site Settings</h3>
      <LoadingError error={tagsContext.error} />
      <SavingError error={saveSiteSettingsMutation.error} />

      <h4>Hide Backorders</h4>
      <p>
        <input
          type='checkbox'
          checked={hideBackorders}
          onChange={() => {
            setHideBackorders(!hideBackorders);
          }}
        />
        &nbsp;Hide Backorder Numbers Displayed Checkout pages & receipts
      </p>
      <h2>Quickbooks</h2>
      <p>
        &nbsp;Quickbooks Product SalesOfIncomeAccount Id. This must be an existing Account in Quickbooks, with the
        SubType of 'SalesOfProductIncome'. This account is used when a product gets purchaed.
      </p>
      <select
        onChange={(e) => setQuickbooksProductSalesOfIncomeAccountId(e.target.value)}
        value={quickbooksProductSalesOfIncomeAccountId}
      >
        {siteSettingsContext.data?.GetQuickbooksAccountsOfSubType.map((account) => (
          <option value={account.Id} key={account?.Id}>
            {account.Name} (Id:{account?.Id})
          </option>
        ))}
      </select>
      <p>
        &nbsp;Quickbooks Gift Card Product Id. This must be an existing Product in Quickbooks. This product is used when
        a gift card gets purchased.
      </p>
      <select onChange={(e) => setQuickbooksGiftCardProductId(e.target.value)} value={quickbooksGiftCardProductId}>
        <option value='NotSet'>None</option>
        {siteSettingsContext.data?.GetQuickbooksGiftCardProducts.map((product) => (
          <option value={product.Id} key={product?.Id}>
            {product.Name} (Id:{product?.Id})
          </option>
        ))}
      </select>
      <p>&nbsp;Quickbooks Refund Deposit To Account Id. This must be an existing Account in Quickbooks.</p>
      <select
        onChange={(e) => setQuickbooksRefundDepositToAccountId(e.target.value)}
        value={quickbooksRefundDepositToAccountId}
      >
        <option value='NotSet'>None</option>
        {siteSettingsContext.data?.GetQuickbooksAccounts.filter(
          ({ AccountType }) => AccountType === 'Other Current Asset' || AccountType === 'Bank'
        ).map((account) => (
          <option value={account.Id} key={account?.Id}>
            {account.Name} (Id:{account?.Id})
          </option>
        ))}
      </select>
      <a onClick={doSave}>Save</a>
    </div>
  );
};

AdminSiteSettings.propTypes = {};

AdminSiteSettings.defaultProps = {};

export default AdminSiteSettings;
