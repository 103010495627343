import _ from 'lodash';
import React, { useState, useContext, Fragment } from 'react';
import { UserContext } from '../UserContext';
import TextInput, { validateLength, validateIsValue } from '../TextInput';
import CheckboxInput from '../CheckboxInput';

const CartCheckoutCreateAccount = (email) => {
  const [validationVisible, setValidationVisible] = useState(false);

  const { User } = useContext(UserContext);

  const isLoggedIn = _.get(User, 'username');

  const [CreateAccount, CreateAccountRendered] = CheckboxInput({
    validationVisible,
    labelText: 'I would like to create an account so that I can view order history and save addresses',
  });

  const [Password, PasswordRendered] = TextInput({
    validationVisible,
    readOnly: !CreateAccount,
    validator: _.partial(validateLength, 6, 100),
    // validator: validateCreditCardNumber,
    labelText: 'Password',
    required: CreateAccount,
    type: 'password',
  });

  const [ConfirmPassword, ConfirmPasswordRendered] = TextInput({
    validationVisible,
    readOnly: !CreateAccount,
    validator: _.partial(validateIsValue, Password),
    // validator: validateCreditCardNumber,
    labelText: 'Confirm Password',
    required: CreateAccount,
    type: 'password',
  });

  const validateForm = () => {
    if (CreateAccount) {
      const passedValidation = Password === ConfirmPassword && !_.isEmpty(Password);
      if (!passedValidation) {
        setValidationVisible(true);
        return false;
      }
    }
    return true;
  };

  const rendered = (
    <Fragment>
      {isLoggedIn ? undefined : (
        <fieldset className='grid-x grid-padding-x fieldset--center-legend'>
          <legend className='header__small-caps'>Create Account (optional)</legend>
          <div className='cell small-12'>
            {CreateAccountRendered}
            <div className={`grid-x grid-margin-x ${CreateAccount ? 'accordion__open' : 'accordion__closed'}`}>
              <div className='cell small-12'>
                <label>Username</label>
                <p>{email || 'Your billing email address'}</p>
                <div className='grid-x'>
                  <div className='cell small-12 medium-6'>{PasswordRendered}</div>
                </div>
                <div className='grid-x'>
                  <div className='cell small-12 medium-6'>{ConfirmPasswordRendered}</div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      )}
    </Fragment>
  );

  const payload = { CreateAccount, Password, ConfirmPassword };
  return [rendered, payload, validateForm];
};

export default CartCheckoutCreateAccount;
