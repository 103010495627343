import React from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { UserContext } from './UserContext';
import log from 'loglevel';
import _ from 'lodash';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <UserContext.Consumer>
    {({ userContext }) => {
      log.debug({ userContext }, 'PrivateRoute got user');
      if (_.has(userContext, ['data', 'username'])) {
        return <Component {...rest} />;
      }
      if (userContext.isFetched && _.isUndefined(userContext.data)) {
        // Only do a redirect once the data has fully loaded
        //  so we know for a fact that they're not logged in
        navigate('/');
      }
      return (
        <div>
          <h2>Login Required</h2>
          <p>You need to be logged in.</p>
        </div>
      );
    }}
  </UserContext.Consumer>
);

PrivateRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
};

export default PrivateRoute;
