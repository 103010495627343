import { navigate } from 'gatsby';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { searchProducts } from '../../utils/public-api';
import TextInput from '../TextInput';
import ProductGridHydrated from './ProductGridHydrated';
import { useQuery } from '@tanstack/react-query';
import Loading from '../Loading';
import { Helmet } from 'react-helmet';
import URLSearchParams from '@ungap/url-search-params';
import PropTypes from 'prop-types';
import loadingSpinner from '../../images/loading.gif';

/*
const PARSED_FACETS = ['Uses', 'Characteristics'];
const calculateFacets = (products) => {
  const facets = {
    FlowerColor: new Set(),
    Hardiness: new Set(),
    Height: new Set(),
    Uses: new Set(),
    Characteristics: new Set(),
  };
  _.map(products, (product) => {
    _.map(facets, (value, key) => {
      const thisVal = _.get(product, key);

      if (_.includes(PARSED_FACETS, key)) {
        const vals = _.split(thisVal, ',');
        _.map(vals, (val) => value.add(_.trim(val)));
      } else {
        value.add(thisVal);
      }
    });
  });
  return facets;
};
*/

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    // Return a cleanup function
    return () => {
      clearTimeout(handler);
    };
  }, [value]);
  return debouncedValue;
};

const Search = ({ location = {} } = {}) => {
  const params = new URLSearchParams(location.search);
  const initialSearchQuery = params.get('q') || '';
  const [Query, QueryRendered] = TextInput({ labelText: 'Search Query', defaultValue: initialSearchQuery });
  const debouncedQuery = useDebounce(Query, 500);

  const productsContext = useQuery(['productSearch', debouncedQuery], async () => searchProducts(debouncedQuery), {
    onSuccess: () => {
      navigate(`/app/search?q=${encodeURIComponent(debouncedQuery)}`, { replace: true });
    },
  });

  /*
  const renderFacet = (facet, key) => {
    let renderedFacets = [];
    for (const f of facet) {
      renderedFacets.push(<b>{f}</b>);
    }
    return <div>
      <h2>{key}</h2>
      {renderedFacets}
    </div>;
  };

  const renderedAllFacets = _.map(Facets.data, renderFacet);
  */

  const loadingRendered = productsContext.isLoading ? (
    <div style={{ float: 'right' }}>
      <img src={loadingSpinner} alt='Loading' />
    </div>
  ) : null;

  return (
    <div>
      <Helmet title={`Search: ${debouncedQuery}`} />
      <h2>Product Search</h2>
      {loadingRendered}
      {QueryRendered}
      {productsContext.isLoading ? <Loading /> : null}
      <ProductGridHydrated Products={_.get(productsContext, ['data', 'Products'])} />
    </div>
  );
};

Search.propTypes = {
  location: PropTypes.object,
};

Search.displayName = 'Search';

export default Search;
