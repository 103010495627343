import React from 'react';
import _ from 'lodash';
import Button from '../Button';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const CartGiftCard = ({ Index, Amount, RecipientName, RecipientEmail, SenderName, Message, removeGiftCard }) => {
  const renderedImage = null; // TODO: get an image for a gift card

  return (
    <div key={Index} className='grid-x grid-margin-x cart--product-row'>
      <div className='cell small-6 medium-4 large-2'>{renderedImage}</div>
      <div className='cell small-6 medium-8 large-4'>
        <p className='product-row--title'>
          <span>Gift Card</span>
          <strong>
            <Link to={`/app/gift-cards/${Index}`}>To {RecipientName}</Link>
          </strong>
          <strong>From {SenderName}</strong>
        </p>
        <p className='product-row--item-id'>Delivered to : {RecipientEmail}</p>
        {!_.isEmpty(Message) && <p className='product-row--item-id'>Message : {Message}</p>}
        <p className='product-row--item-id'>Delivered to : {RecipientEmail}</p>
      </div>
      <div className='cell small-12 large-6'>
        <div className='grid-x grid-padding-x product-row--quant-calc'>
          <div className='cell small-2 medium-4 large-2  product-row--item-price'>
            <p className='hide-for-large'>Price</p>
            <p className='currency'>${Amount}</p>
          </div>

          <div className='cell small-8 medium-4 large-6'>
            <label htmlFor={`gc-${Index}`} className='show-for-sr'>
              Quantity
            </label>
            <p className='hide-for-large hide-for-sr'>Quantity</p>
            <div className='input-group plus-minus-input'>
              <div className='input-group-button'></div>

              <div className='input-group-button'></div>
            </div>
          </div>

          <div className='cell small-2 medium-4 large-4'>
            <p className='hide-for-large'>Total</p>
            <p className='currency'>
              <strong>${Amount}</strong>
            </p>
          </div>
        </div>
      </div>
      <div className='cell small-12 product-row--item__delete'>
        <Button style='transparent icon fi-trash' text='Remove item' onClickHandler={removeGiftCard} />
      </div>
    </div>
  );
};

CartGiftCard.propTypes = {
  Amount: PropTypes.number,
  RecipientName: PropTypes.string,
  RecipientEmail: PropTypes.string,
  SenderName: PropTypes.string,
  Message: PropTypes.string,
  removeGiftCard: PropTypes.func,
  Index: PropTypes.number,
};

export default CartGiftCard;
