import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import TextInput, { validateLength } from '../TextInput';
import ShippingServiceInput from './ShippingServiceInput';
import { navigate, Link } from 'gatsby';
import { saveShipping } from '../../graphql/cart';
import CheckboxInput from '../CheckboxInput';
import CartCheckoutOrderSummary from './CartCheckoutOrderSummary';
import UserInfoSummary from './CartUserInfoSummary';
import WaiveWarrantyText from './WaiveWarrantyText';
import ExtraShippingText from './ExtraShippingText';
import Button from '../Button';
import { useCheckoutContext } from './hooks';
import log from 'loglevel';
import PropTypes from 'prop-types';
import LoadingError from '../LoadingError';
import SavingError from '../SavingError';
import { doGraphqlQuery } from '../../fetcher';

const renderAddress = ({
  // eslint-disable-next-line
  FirstName,
  LastName,
  Address,
  Address2,
  City,
  State,
  Zip,
  Phone,
  Email,
}) => (
  <div>
    <p>
      {FirstName} {LastName}
    </p>
    <p>{Address}</p>
    <p>{Address2 ? Address2 : ''}</p>
    <p>
      {City}, {State} {Zip}
    </p>
    <p>
      <i className='fi-telephone'></i> {Phone}
    </p>
    <p>
      <i className='fi-mail'></i> {Email}
    </p>
  </div>
);

const renderMissingAddress = () => (
  <div>
    <p>
      <b>Whoa!!</b> A weird problem has occurred!
    </p>
    <p>
      At this step in the checkout process we expect to have your shipping address saved, but we don&apos;t seem to have
      it!
    </p>
    <p>
      Please go to the <Link to='/app/checkout/shipping-address'>prior step to enter your address</Link>
    </p>
  </div>
);

const postShipping = async (shippingPayload) => {
  // throw {errors: [{message:'one error'}, {message:'another error'}]};
  const result = await doGraphqlQuery(saveShipping, shippingPayload);
  log.debug({ result }, 'posted shipping');
  return result.data;
};

const CartCheckoutShipping = ({ location }) => {
  const queryClient = useQueryClient();
  const checkoutContext = useCheckoutContext({ location });
  const submitMutation = useMutation(postShipping, {
    onSuccess: (data) => {
      log.debug({ data }, 'postShipping onSuccess');
      queryClient.setQueryData(['checkout'], data.CheckoutV1);
      navigate('/app/checkout/billing-payment');
    },
    onError: (err) => {
      log.error({ err }, 'got error saving shipping');
    },
  });
  const [address, setAddress] = useState({});
  const [validationVisible, setValidationVisible] = useState(false);
  const { FirstName, LastName, Address, Address2, City, State, Zip } = address;
  const PlugtrayCount = _.sum(
    _.map(_.get(checkoutContext, 'data.Cart.Products'), ({ QuantityValidated }) => QuantityValidated)
  );

  const [shippingService, shippingServiceRendered] = ShippingServiceInput({
    validationVisible,
    PlugtrayCount,
    FirstName,
    LastName,
    Address,
    Address2,
    City,
    State,
    Zip,
  });

  const [DeliveryInstructions, DeliveryInstructionsRendered, SetDeliveryInstructions] = TextInput({
    validationVisible,
    validator: _.partial(validateLength, 0, 25),
    labelText: 'UPS delivery instructions (optional, limit 25 characters)',
    helpText: 'Example: Leave on porch in shade',
  });

  const [WaiveWarranty, WaiveWarrantyRendered, SetWaiveWarranty] = CheckboxInput({
    validationVisible,
    required: true,
    labelText: 'I will waive my warranty to have my plants sent now. I understand safe delivery cannot be guaranteed.',
    testId: 'waive-warranty',
  });

  let warrantyRendered = (
    <div className='cell small-12'>
      <i>Verifying shipping...</i>
    </div>
  );
  if (_.has(checkoutContext, ['data', 'WarrantyValid'])) {
    // Data is finished loading
    if (!checkoutContext.data.WarrantyValid) {
      warrantyRendered = (
        <>
          <ExtraShippingText />
          <div className='cell small-12 callout alert waive-warranty'>
            <WaiveWarrantyText />
            {WaiveWarrantyRendered}
          </div>
        </>
      );
    } else {
      warrantyRendered = <ExtraShippingText />;
    }
  }
  useEffect(() => {
    // Sets state of page from the loaded API requests
    // This runs after every render, be careful!
    const shipping = _.get(checkoutContext, 'data.Cart.Shipping');
    if (_.isEmpty(address) && !_.isEmpty(shipping)) {
      log.debug('set UI state from checkout context');
      setAddress(shipping);

      const shippingService = _.get(checkoutContext, 'data.Cart.ShippingService') || {};
      const WaiveWarrantyPlugtrays = checkoutContext?.data?.Cart?.WaiveWarrantyPlugtrays;
      if (!_.isEmpty(shippingService)) {
        SetDeliveryInstructions(shippingService.DeliveryInstructions);
        SetWaiveWarranty(WaiveWarrantyPlugtrays || false);
      }
    }
  });

  const submit = async (event) => {
    event.preventDefault();
    const fieldValues = [shippingService, DeliveryInstructions];
    let passedValidation = true;
    _.map(fieldValues, (value) => {
      if (_.isUndefined(value)) {
        passedValidation = false;
      }
    });
    if (!passedValidation) {
      setValidationVisible(true);

      return;
    }

    const shippingPayload = {
      ...shippingService,
      DeliveryInstructions,
      WaiveWarranty,
    };
    submitMutation.mutate(shippingPayload);
  };

  let shippingRendered = <p>Loading.....</p>;
  if (checkoutContext.isFetched) {
    if (_.isNull(_.get(checkoutContext, ['data', 'Cart', 'Shipping']))) {
      shippingRendered = renderMissingAddress();
    } else {
      shippingRendered = renderAddress(checkoutContext.data.Cart.Shipping);
    }
  }

  return (
    <div className='grid-x grid-margin-x'>
      <div className='cell small-12 medium-8'>
        <UserInfoSummary
          heading='Shipping information'
          editLink='/app/checkout/shipping-address'
          infoRendered={shippingRendered}
        />
        <form onSubmit={submit}>
          <LoadingError error={checkoutContext.error} />
          <fieldset className='grid-x grid-padding-x fieldset--center-legend'>
            <legend className='header__small-caps'>Shipping options</legend>
            {shippingServiceRendered}
            <div className='cell small-12'>
              <p>
                <strong>Delivery instructions (optional)</strong>
              </p>
              <p>
                If your UPS delivery driver needs special instructions, such as a gate code, enter that information
                here.
              </p>
            </div>
            <div className='cell small-12 medium-7'>{DeliveryInstructionsRendered}</div>
            {warrantyRendered}

            <Button
              type='submit'
              isDisabled={submitMutation.isLoading || !_.has(checkoutContext, ['data', 'WarrantyValid'])}
              style={submitMutation.isLoading ? 'primary expanded loading' : 'primary expanded'}
              text='Continue to Billing'
              testId='submit-shipping'
            />
            <br />
            <SavingError error={submitMutation.error} />
          </fieldset>
        </form>
      </div>
      <div className='cell small-12 medium-4'>
        <CartCheckoutOrderSummary {..._.get(checkoutContext, 'data')} />
      </div>
    </div>
  );
};

CartCheckoutShipping.propTypes = {
  location: PropTypes.object,
};

export default CartCheckoutShipping;
