import _ from 'lodash';
import React from 'react';
import { useGetProductNotificationsV1Query } from '../../generated/types-and-hooks';
import log from 'loglevel';

const AdminProductNotifications = (args) => {
  const Storefront = args.Storefront.toUpperCase();
  const productsContext = useGetProductNotificationsV1Query({ Storefront });
  log.debug({ Storefront, args }, 'AdminProductNotifications loading');

  const productsById = _.keyBy(productsContext.data?.GetProducts?.products, 'Id');
  // eslint-disable-next-line
  const renderProductLine = ({ ProductId, UserCount }, index) => {
    const Product = productsById[ProductId];
    const Name = Storefront === 'PLUGTRAYS' ? Product?.NamePlugtrays : Product?.Name;
    return (
      <tr key={`${index}-${ProductId}`}>
        <td>{ProductId}</td>
        <td>{Name}</td>
        <td>{UserCount}</td>
      </tr>
    );
  };

  const renderedContent = productsContext.isLoading ? (
    <p>Loading .....</p>
  ) : (
    <table>
      <thead>
        <tr>
          <td>Product Id</td>
          <td>Description</td>
          <td>User Count</td>
        </tr>
      </thead>
      <tbody>{_.map(productsContext.data?.GetProductNotificationsV1, renderProductLine)}</tbody>
    </table>
  );

  return (
    <div>
      <h2>{_.startCase(_.toLower(Storefront))} - Product Stock Notifications</h2>
      {renderedContent}
    </div>
  );
};

export default AdminProductNotifications;
