import { gql } from '@apollo/client';

export const getUser = gql`
  query GetUser {
    getUser {
      name
      address
      address2
      city
      state
      zip
      phone
    }
  }
`;

export const putUser = gql`
  mutation SaveUser(
    $name: String!
    $address: String!
    $address2: String
    $city: String!
    $state: String!
    $zip: String!
    $phone: String!
  ) {
    saveUser(
      user: {
        name: $name
        address: $address
        address2: $address2
        city: $city
        state: $state
        zip: $zip
        phone: $phone
      }
    ) {
      name
      address
      address2
      city
      state
      zip
      phone
    }
  }
`;

export const saveEmail = gql`
  mutation saveEmail($Email: String!, $OptOut: Boolean!) {
    SaveEmail(Email: $Email, OptOut: $OptOut)
  }
`;

export const getMyOrders = gql`
  query getMyOrders {
    GetMyOrders {
      Products {
        Id
        Quantity
        Status
        QuantityValidated
        PricePlugtrays
        NamePlugtrays
      }
      ShippingService {
        shippingService {
          DaysInTransit
          DeliveryTime
          Price
          ServiceCode
        }
        DeliveryInstructions
        IsGift
        GiftMessage
        WaiveWarranty
      }
      Shipping {
        FirstName
        LastName
        Address
        Address2
        City
        State
        Zip
        Phone
        Email
      }
      Billing {
        FirstName
        LastName
        Address
        Address2
        City
        State
        Zip
        Phone
        Email
      }
      Payment {
        Token
      }
      Id
      PaymentAuthorization {
        ResponseCode
      }
      SourceIp
      Status
      Subtotal
      Tax
      TimestampCreated
      Total
      UserId
    }
  }
`;
