import _ from 'lodash';
import React, { Fragment, useEffect } from 'react';
import { useAdminConnectQuickbooksOAuthMutation } from '../../generated/types-and-hooks';
import log from 'loglevel';
import SavingError from '../SavingError';
import config from '../../../config';

const ENV_CONFIG = config.get();

const AdminQuickbooksOAuthCallback = ({ location }) => {
  const [needToSendCode, setNeedToSendCode] = React.useState<boolean>(false);
  const redirect_uri = `${window.location.origin}/app/admin/callback`;
  log.debug({ location, redirect_uri }, 'got location!');
  const connectMutation = useAdminConnectQuickbooksOAuthMutation({
    onSuccess: (data) => {
      log.info({ data }, 'got data from connect mutation');
    },
  });

  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const realmId = searchParams.get('realmId');
  if (code && state && realmId && !needToSendCode) {
    setNeedToSendCode(true);
  }

  useEffect(() => {
    if (needToSendCode) {
      log.info({ code }, 'sending code to backend');
      connectMutation.mutate({ Code: code!, RedirectUri: redirect_uri });
    }
  }, [needToSendCode]);

  const connectSearchParams = new URLSearchParams({
    client_id: ENV_CONFIG.quickbooksClientId,
    scope: 'com.intuit.quickbooks.accounting com.intuit.quickbooks.payment openid profile email phone address',
    redirect_uri,
    response_type: 'code',
    state: 'LocalAuth',
  });
  const url = `https://appcenter.intuit.com/connect/oauth2?${connectSearchParams.toString()}`;

  let sendingStatus = <Fragment />;
  if (connectMutation.isLoading) {
    sendingStatus = <p>Sending code to backend ......</p>;
  } else if (connectMutation.isSuccess) {
    if (connectMutation.data?.ConnectQuickbooksOAuth) {
      sendingStatus = <p>Successfully connected to QuickBooks!</p>;
    } else {
      sendingStatus = <p>Failed to connect to QuickBooks! Please try again</p>;
    }
  }
  return (
    <div>
      <h3>OAuth2 Callback</h3>
      <SavingError error={connectMutation.error} />
      {sendingStatus}
      <a href={url} target='_blank'>
        Login to QuickBooks
      </a>
    </div>
  );
};

AdminQuickbooksOAuthCallback.propTypes = {};

AdminQuickbooksOAuthCallback.defaultProps = {};

export default AdminQuickbooksOAuthCallback;
