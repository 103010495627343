import PropTypes from 'prop-types';
import _ from 'lodash';
import React, { useState, HTMLProps, useEffect, Fragment } from 'react';
import { Link } from 'gatsby';
import { formatCurrency } from '../../utils/cart';
import log from 'loglevel';
import Loading from '../Loading';
import { useQueryClient } from '@tanstack/react-query';
import LoadingError from '../LoadingError';
import SavingError from '../SavingError';
import {
  Order,
  OrderStatus,
  ShipmentStatus,
  Storefront,
  UpsShipment,
  useAdminCreateShipmentForOrdersMutation,
  useAdminSaveOrderStatusMutation,
  useAdminScheduleOrdersMutation,
  useGetShipmentsQuery,
  useGetWarrantyStatesV1Query,
  useInfiniteGetOrdersPageQuery,
} from '../../generated/types-and-hooks';
import Modal from 'react-modal';
import Button from '../Button';
import { createAllPackingSlips } from './OrderPdfs';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  SortingState,
  Table,
} from '@tanstack/react-table';

const isShipmentTerminal = (shipment: UpsShipment | null) =>
  shipment && [ShipmentStatus.Created, ShipmentStatus.Error].includes(shipment.Status);

const printedOrderSortKey = (o: Order) => {
  if (o.Shipping?.State === 'CA') {
    return 0;
  }
  return 1;
};

const printedOrderSort = (a: Order, b: Order) => {
  // First custom sort by State (CA first, then everything else)
  const aKey = printedOrderSortKey(a);
  const bKey = printedOrderSortKey(b);
  if (aKey !== bKey) {
    return aKey - bKey;
  }
  // Next, Sort by item count descending
  const aTotal = a.PotCount! + a.PlugtrayCount!;
  const bTotal = b.PotCount! + b.PlugtrayCount!;
  if (aTotal !== bTotal) {
    return bTotal - aTotal;
  }
  // Finally, sort by Id, to keep it deterministic
  return a.Id.localeCompare(b.Id);
};

const printedOrderTableSort = (a, b) => printedOrderSort(a.original, b.original);

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return <input type='checkbox' ref={ref} className={className + ' cursor-pointer'} {...rest} />;
}

type OrderRow = Order & {
  StateUnderWarranty: boolean;
  SuggestedShipDate?: Date;
  CustomerWaivedWarranty?: boolean;
  ProductTypes: string;
};

const formatTimestampAsDate = (timestamp: number) => new Date(timestamp * 1000).toLocaleDateString();

const selectCellsMatching = (table: Table<OrderRow>, column, value) => {
  if (!value) {
    log.debug({ value }, 'selectCellsMatching: no value');
    return;
  }
  const dateToMatch = formatTimestampAsDate(value);
  const newRowSelection = {};
  for (const row of table.getRowModel().rows) {
    if (!row.original[column]) {
      continue;
    }
    const date = formatTimestampAsDate(row.original[column]);
    if (date === dateToMatch && row.getCanSelect()) {
      newRowSelection[row.id] = true;
    }
  }
  table.setRowSelection(newRowSelection);
  log.debug({ newRowSelection }, 'selecting cells matching');
};

const defaultColumns: ColumnDef<OrderRow>[] = [
  {
    header: `Orders`,
    columns: [
      {
        id: 'Select',
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div style={{ minWidth: '20px' }}>
            {row.getCanSelect() && (
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            )}
          </div>
        ),
      },
      {
        accessorFn: ({ Id }) => ({ Id }),
        id: 'ViewOrder',
        cell: (info) => <Link to={`/app/admin/orders/${info.getValue().Id}`}>Edit</Link>,
        header: () => <span>Manage (Packing Order)</span>,
        sortingFn: printedOrderTableSort,
      },
      {
        accessorFn: (row) => row.TimestampCreated,
        id: 'Date',
        cell: (info) => formatTimestampAsDate(info.getValue()),
        header: () => <span>Order Date</span>,
      },
      {
        accessorFn: (row) => row.SuggestedShipDate,
        id: 'SuggestedShipDate',
        cell: (info) => (info.getValue() && info.getValue().toLocaleDateString()) || 'N/A',
        header: () => <span>Suggested Ship Date</span>,
      },
      {
        accessorFn: (row) => row.ProductTypes,
        id: 'ProductTypes',
        cell: (info) => info.getValue() || 'N/A',
        header: () => <span>Products In Order</span>,
      },
      {
        accessorFn: ({ EstimatedShippingDate }) => EstimatedShippingDate,
        id: 'EstimatedShippingDate',
        cell: (info) =>
          info.getValue() && (
            <div style={{ whiteSpace: 'nowrap' }}>
              <i
                title='Select All Orders with the same Estimated Shipping Date'
                className='fi-clipboard-pencil'
                onClick={() => selectCellsMatching(info.table, 'EstimatedShippingDate', info.getValue())}
              ></i>
              {formatTimestampAsDate(info.getValue())}
            </div>
          ),
        header: () => <span>Estimated Shipping Date</span>,
      },
      {
        accessorFn: ({ EstimatedShippingDatePlugtrays }) => EstimatedShippingDatePlugtrays,
        id: 'EstimatedShippingDatePlugtrays',
        cell: (info) =>
          info.getValue() && (
            <div style={{ whiteSpace: 'nowrap' }}>
              <i
                title='Select All Orders with the same Plugtrays Estimated Shipping Date'
                className='fi-clipboard-pencil'
                onClick={() => selectCellsMatching(info.table, 'EstimatedShippingDatePlugtrays', info.getValue())}
              ></i>
              &nbsp;
              {formatTimestampAsDate(info.getValue())}
            </div>
          ),
        header: () => <span>Estimated Shipping Date Plugtrays</span>,
      },
      {
        accessorFn: (row) => row.Shipping?.State,
        id: 'ShippingState',
        cell: (info) => info.getValue(),
        header: () => <span>Shipping State</span>,
      },
      {
        accessorFn: (row) => row.StateUnderWarranty,
        id: 'StateUnderWarranty',
        cell: (info) => (info.getValue() ? `✅` : `❌`),
        header: () => <span>State Under Warranty</span>,
      },
      {
        accessorFn: ({ CustomerWaivedWarranty }) => CustomerWaivedWarranty,
        id: 'WarrantyWaived',
        cell: (info) => {
          if (typeof info.getValue() === 'undefined') {
            return 'N/A';
          }
          return info.getValue() ? `✅` : `❌`;
        },
        header: () => <span>Warranty Waived</span>,
      },
      {
        accessorFn: ({ InvoiceId, Id }) => ({ InvoiceId, Id }),
        id: 'InvoiceId',
        cell: (info) => <Link to={`/app/admin/orders/${info.getValue().Id}`}>{info.getValue().InvoiceId}</Link>,
        header: () => <span>Invoice&nbsp;#</span>,
      },
      {
        accessorFn: (row) => `${row.Shipping?.FirstName} ${row.Shipping?.LastName}`,
        id: 'Name',
        cell: (info) => info.getValue(),
        header: () => <span>Customer Name</span>,
      },
      {
        accessorFn: (row) => row.Total,
        id: 'Total',
        cell: (info) => `${formatCurrency(info.getValue())}`,
        header: () => <span>Total</span>,
      },
      {
        accessorFn: (row) => row.PotCount,
        id: 'PotCount',
        header: () => <span>#&nbsp;Pots</span>,
        cell: (info) => (info.getValue() ? info.getValue() : '-'),
      },
      {
        accessorFn: (row) => row.PlugtrayCount,
        id: 'PlugtrayCount',
        header: () => <span>#&nbsp;Plugtrays</span>,
        cell: (info) => (info.getValue() ? info.getValue() : '-'),
      },
    ],
  },
];

type AdminActiveOrdersV2Props = {
  Status: OrderStatus;
  ScanIndexForward?: boolean;
  PrintShipments: (Shipments: UpsShipment[]) => Promise<void>;
  zebraStatusRendered: JSX.Element;
};

const AdminActiveOrdersV2 = ({
  Status = OrderStatus.Ready,
  ScanIndexForward = true,
  PrintShipments,
  zebraStatusRendered,
}: AdminActiveOrdersV2Props) => {
  Modal.setAppElement('#___gatsby');
  const [rowSelection, setRowSelection] = React.useState({});
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const queryClient = useQueryClient();

  const [shipModalOpen, setShipModalOpen] = useState(false);
  const [currentShipmentIds, setCurrentShipmentIds] = useState<string[]>([]);
  const [finishedLoadingShipments, setFinishedLoadingShipments] = useState<boolean>(false);
  const [ordersBeingShipped, setOrdersBeingShipped] = useState<Order[]>([]);
  const [shipStatusText, setShipStatusText] = useState<string>('');
  const [failedShipmentInvoiceIds, setFailedShipmentInvoiceIds] = useState<string[]>([]);

  useEffect(() => {
    log.debug('tab changed, clearing state');
    setCurrentShipmentIds([]);
    setRowSelection({});
  }, [Status, ScanIndexForward]);

  const closeShipModal = () => setShipModalOpen(false);

  const openShipModal = () => {
    // Reset state
    setFailedShipmentInvoiceIds([]);
    setCurrentShipmentIds([]);
    setOrdersBeingShipped([]);
    setShipStatusText('');
    setFinishedLoadingShipments(false);
    setShipModalOpen(true);
  };

  const [printPickSlipModalIsOpen, setPrintSlipModalIsOpen] = useState<boolean>(false);

  const openPrintPickSlipModal = () => setPrintSlipModalIsOpen(true);
  const closePrintPickSlipModal = () => setPrintSlipModalIsOpen(false);

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [scheduledDate, setScheduledDate] = useState('');

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const warrantyStatesContext = useGetWarrantyStatesV1Query();

  const ordersContext = useInfiniteGetOrdersPageQuery(
    {
      Status,
      ScanIndexForward,
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.GetOrdersPage?.LastEvaluatedKey) {
          return {
            ExclusiveStartKey: lastPage?.GetOrdersPage?.LastEvaluatedKey,
          };
        }
      },
    }
  );

  // Automatically load next page if available
  useEffect(() => {
    if (!ordersContext.isLoading && ordersContext.hasNextPage) {
      log.debug('fetching next page automatically');
      ordersContext.fetchNextPage();
    }
  }, [ordersContext.data]);

  const transformOrder = (order: Order): OrderRow => {
    const StateUnderWarranty =
      warrantyStatesContext.data?.GetWarrantyStatesV1?.WarrantyStates.includes(order.Shipping?.State!) || false;

    let SuggestedShipDate;
    let CustomerWaivedWarranty;
    let ProductTypes;

    if (order.WarrantyValid) {
      CustomerWaivedWarranty = undefined;
    } else {
      if (order.PlugtrayCount! > 0) {
        CustomerWaivedWarranty = order.WaiveWarrantyPlugtrays;
      }
      if (order.PotCount! > 0) {
        CustomerWaivedWarranty = order.WaiveWarranty;
      }
    }
    if (!order.EstimatedShippingDate) {
      if (StateUnderWarranty || CustomerWaivedWarranty || order.Storefront === Storefront.Plugtrays) {
        var d = new Date();
        d.setDate(d.getDate() + ((1 + 7 - d.getDay()) % 7 || 7));
        SuggestedShipDate = new Date(d.toDateString());
      }
    }
    if (order.PlugtrayCount! > 0 && order.PotCount! > 0) {
      ProductTypes = 'Multiple';
    } else {
      if (order.PlugtrayCount! > 0) {
        ProductTypes = 'Plug tray';
      } else {
        ProductTypes = 'Pots';
      }
    }
    return {
      ...order,
      StateUnderWarranty,
      SuggestedShipDate,
      CustomerWaivedWarranty,
      ProductTypes,
    };
  };
  const allOrders = _.concat(
    ..._.map(ordersContext.data?.pages, ({ GetOrdersPage }) => GetOrdersPage?.Orders.map(transformOrder))
  );

  const scheduleOrdersMutation = useAdminScheduleOrdersMutation({
    onSuccess: (data) => {
      log.info({ data }, 'scheduleOrdersMutation onSuccess');
      queryClient.invalidateQueries({ queryKey: ['getOrdersPage.infinite'] });
      setRowSelection({});
      closeModal();
      setScheduledDate('');
    },
  });

  const createShipmentMutation = useAdminCreateShipmentForOrdersMutation({
    onSuccess: (data) => {
      log.info({ data }, 'createShipmentMutation onSuccess');
      if (data) {
        const selectedOrders = _.filter(allOrders, ({ Id }) => _.includes(selectedOrderIds, Id)) as Order[];
        setOrdersBeingShipped(selectedOrders);
        setRowSelection({});
        setFinishedLoadingShipments(false);
        const { CreateShipmentForOrders } = data;
        const shipmentIds = CreateShipmentForOrders.map(({ Id }) => Id);
        log.debug({ shipmentIds }, 'got shipment ids, will be polling for status next');
        setCurrentShipmentIds(shipmentIds);
      }
    },
  });
  const saveOrderStatusMutation = useAdminSaveOrderStatusMutation({
    onSuccess: (data) => {
      log.info({ data }, 'saveOrderStatusMutation onSuccess');
      queryClient.invalidateQueries({ queryKey: ['getOrdersPage.infinite'] });
      setRowSelection({});
      // closeFulfillmentModal();
    },
  });

  const getShipmentsQuery = useGetShipmentsQuery(
    { Ids: currentShipmentIds },
    {
      enabled: currentShipmentIds.length > 0 && !finishedLoadingShipments,
      onSuccess: (data) => {
        log.info({ data }, 'getShipmentsQuery onSuccess');
        if (getShipmentsQuery.data?.GetShipments) {
          const { GetShipments } = data;
          const groupedStatuses = _.groupBy(GetShipments, ({ Status }) => Status);

          if (groupedStatuses[ShipmentStatus.Error]) {
            const failedInvoiceIds = _.map(groupedStatuses[ShipmentStatus.Error], ({ OrderId }) => {
              const order = _.find(ordersBeingShipped, ({ Id }) => Id === OrderId);
              return order?.InvoiceId;
            });
            log.debug({ failedInvoiceIds });
            setFailedShipmentInvoiceIds(failedInvoiceIds);
          }

          let loadingMessage = 'Shipments requested, working .....';
          if (GetShipments.every(isShipmentTerminal)) {
            loadingMessage = '';
            // Done polling
            log.info('shipments are in terminal status');
            setFinishedLoadingShipments(true);
            queryClient.invalidateQueries({ queryKey: ['getOrdersPage.infinite'] });
          }
          let shipStatus = `${loadingMessage} ${_.size(groupedStatuses[ShipmentStatus.Requested])} working, 
          ${_.size(groupedStatuses[ShipmentStatus.Created])} created,
          ${_.size(groupedStatuses[ShipmentStatus.Error])} errors.`;
          setShipStatusText(shipStatus);
          log.debug({ groupedStatuses, shipStatusText }, 'groupedStatuses');
        }
      },
      refetchInterval: 1000,
    }
  );

  const [columns] = React.useState<typeof defaultColumns>(() => [...defaultColumns]);

  let columnVisibility = {};
  switch (Status) {
    case OrderStatus.Ready:
      columnVisibility = {
        Select: false,
        EstimatedShippingDate: false,
        EstimatedShippingDatePlugtrays: false,
      };
      break;
    case OrderStatus.Scheduled:
      columnVisibility = {
        SuggestedShipDate: false,
      };
      break;

    case OrderStatus.Fulfillment:
      columnVisibility = {
        SuggestedShipDate: false,
      };
      break;
    case OrderStatus.Downloaded:
    case OrderStatus.Declined:
    case OrderStatus.Shipped:
      columnVisibility = {
        SuggestedShipDate: false,
        EstimatedShippingDate: false,
        EstimatedShippingDatePlugtrays: false,
        StateUnderWarranty: false,
        ShippingState: false,
        WarrantyWaived: false,
      };
      break;
  }

  const isRowSelectable = (row) => {
    const order = row.original;
    if ([OrderStatus.Scheduled, OrderStatus.Fulfillment].includes(Status)) {
      // Disable row selection for orders that have both POTS & PLUGTRAYS (they cannot be shipped using bulk endpoint)
      if (order.PlugtrayCount > 0 && order.PotCount > 0) {
        return false;
      }
    }
    return true;
  };

  const table = useReactTable({
    data: allOrders,
    columns,
    state: {
      columnVisibility,
      rowSelection,
      sorting,
    },
    onSortingChange: setSorting,
    enableRowSelection: isRowSelectable,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: ({ Id }) => Id,
  });

  const selectedOrderIds = _.keys(rowSelection);
  const createShipmentSubmit = () => {
    log.debug({ selectedOrderIds }, 'createShipmentSubmit');
    setShipStatusText('Requesting shipments...');
    createShipmentMutation.mutate({ OrderIds: selectedOrderIds });
  };

  const scheduleOrderSubmit = () => {
    log.debug({ selectedOrderIds, scheduledDate }, 'scheduleOrderSubmit');
    const EstimatedShippingDate =
      (new Date(scheduledDate).getTime() + new Date().getTimezoneOffset() * 60 * 1000) / 1000;
    scheduleOrdersMutation.mutate({ OrderIds: selectedOrderIds, EstimatedShippingDate });
  };

  const printLabels = () => {
    if (!getShipmentsQuery.data?.GetShipments) {
      log.error({ getShipmentsQuery }, 'no shipments found');
      return;
    }
    const orderIdsSorted = ordersBeingShipped.sort(printedOrderSort).map(({ Id }) => Id);

    const validLabels = getShipmentsQuery.data.GetShipments.filter(({ Status }) => Status === ShipmentStatus.Created);
    const shipmentsSorted = validLabels.sort(
      (a, b) => orderIdsSorted.indexOf(a?.OrderId!) - orderIdsSorted.indexOf(b?.OrderId!)
    );
    log.debug({ shipmentsSorted, orderIdsSorted, ordersBeingShipped }, 'printLabels');
    PrintShipments(shipmentsSorted);
  };

  const canSubmitCreateShipments = createShipmentMutation.isLoading || currentShipmentIds.length > 0;

  const printPackingSlipsForShippedOrder = () => {
    const ordersSorted = ordersBeingShipped.sort(printedOrderSort);
    log.debug({ ordersBeingShipped, ordersSorted }, 'printPackingSlipsForShippedOrder selectedOrders');
    createAllPackingSlips(ordersSorted);
  };

  const printPickSlipsAndMarkInFulfillment = async () => {
    const selectedOrders = _.filter(allOrders, ({ Id }) => _.includes(selectedOrderIds, Id)) as Order[];
    const sortedOrders = selectedOrders.sort(printedOrderSort);
    log.debug({ sortedOrders }, 'printPackingSlips');
    createAllPackingSlips(sortedOrders, 'Pick Slip');

    const Orders = selectedOrderIds.map((Id) => ({ Id, Status: OrderStatus.Fulfillment }));
    log.debug({ selectedOrderIds, Orders }, 'markOrdersAsInFulfillment');
    await saveOrderStatusMutation.mutateAsync({ Orders });

    closePrintPickSlipModal();
  };

  let availableActions: JSX.Element[] = [];
  availableActions.push(
    <p key='orders-selected' style={{ color: '#767676', marginBottom: '0', marginLeft: '1rem' }}>
      <small key='orders-selected'>
        <em>
          {_.size(selectedOrderIds)} {_.size(selectedOrderIds) > 1 ? 'orders' : 'order'} selected
        </em>
      </small>
    </p>
  );
  if (Status === OrderStatus.Ready || Status === OrderStatus.Downloaded) {
    // Because of the complexities of Pots vs Plugtrays,
    // Scheduling needs to be done on the order detail page
    // availableActions.push(
    //   <a className='action-bar--item' key='schedule-orders' onClick={scheduleOrdersClick}>
    //     <i className='fi-calendar'></i>
    //     <span>Schedule orders</span>
    //   </a>
    // );
  } else if (Status === OrderStatus.Fulfillment) {
    availableActions.push(
      <Fragment key='fulfillment'>
        <a className='action-bar--item' key='ship-orders' onClick={openShipModal}>
          <i className='fi-mail'></i>
          <span>Ship orders</span>
        </a>
        <div className='action-bar--item'>{zebraStatusRendered}</div>
      </Fragment>
    );
  } else if (Status === OrderStatus.Scheduled) {
    availableActions.push(
      <a key='scheduled' className='action-bar--item' key='print-packing-skip' onClick={openPrintPickSlipModal}>
        <i className='fi-print'></i>
        <span>Print pick slips</span>
      </a>
    );
  } else {
    availableActions.push(
      <p key='none-available' style={{ color: '#767676', marginBottom: '0', marginLeft: '1rem' }}>
        <small>No available actions.</small>
      </p>
    );
  }

  // We're automatically loading the next page, so we don't need a "load more" button
  // TODO: hash this out
  const fetchMoreRendered = ordersContext.hasNextPage ? (
    <tr key='hasMore'>
      <td colSpan='100%'>
        <button disabled={ordersContext.isFetchingNextPage} onClick={() => ordersContext.fetchNextPage()}>
          Fetch more results
        </button>
      </td>
    </tr>
  ) : null;

  const tableRendered = (
    <div>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: header.column.getCanSort() ? 'cursor-pointer' : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {{
                        asc: '🔼',
                        desc: '🔽',
                      }[header.column.getIsSorted() as string] ?? null}
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
          {fetchMoreRendered}
        </tbody>
      </table>
    </div>
  );

  const canScheduleOrders = scheduledDate && !scheduleOrdersMutation.isLoading;
  const scheduleOrderButtonStyle = `secondary expanded ${scheduleOrdersMutation.isLoading && 'loading'} ${
    !canScheduleOrders && 'disabled'
  }}`;
  return (
    <>
      <div className='cell small-4' style={{ backgroundColor: '#f8f8f8' }}>
        {_.size(selectedOrderIds) > 0 ? (
          availableActions
        ) : (
          <p style={{ color: '#767676', marginTop: '1rem', marginLeft: '1rem' }}>
            <small>
              <em>Select an order to view available actions</em>
            </small>
          </p>
        )}
      </div>
      <div className='cell small-12'>
        {ordersContext.isLoading && <Loading />}
        <Modal isOpen={modalIsOpen} contentLabel='Schedule Orders' className='modal' onRequestClose={closeModal}>
          <div className='grid-x'>
            <div className='cell small-12 modal--close'>
              <button className='button transparent' onClick={closeModal}>
                <i className='fi-x' /> close
              </button>
            </div>
          </div>
          <h3>Schedule Orders</h3>
          <label>
            Choose an estimated shipping date
            <input type='date' onChange={(e) => setScheduledDate(e.target.value)} value={scheduledDate} />
          </label>
          <p>
            Confirming this date will send an automatic email to selected customers with this date as their expected
            ship date.
          </p>

          <p>Would you like to schedule these orders?</p>
          <Button
            testId='accept-date-button'
            isDisabled={!canScheduleOrders}
            style={scheduleOrderButtonStyle}
            onClickHandler={scheduleOrderSubmit}
            text='Confirm scheduled ship date'
          />
        </Modal>

        <Modal
          isOpen={printPickSlipModalIsOpen}
          contentLabel='Print Pick Slips'
          className='modal'
          onRequestClose={closePrintPickSlipModal}
        >
          <div className='grid-x'>
            <div className='cell small-12 modal--close'>
              <button className='button transparent' onClick={closePrintPickSlipModal}>
                <i className='fi-x' /> close
              </button>
            </div>
          </div>
          <h3>Print Pick Slips &amp; Move to In Fulfillment</h3>
          <p>This actions will open a new tab with pick slips, and will also mark each order as "In Fulfillment".</p>
          <p>
            Marking orders as "in fulfillment" means that they are being pulled. If for any reason an order can't be
            fulfilled once it's sent to the nursery, you can reschedule the order on the next screen.
          </p>
          <p>Would you like to do this?</p>
          <Button
            testId='print-pick-slips-button'
            isDisabled={saveOrderStatusMutation.isLoading}
            style={
              saveOrderStatusMutation.isLoading
                ? `secondary expanded ${saveOrderStatusMutation.isLoading && 'loading'} disabled`
                : 'secondary expanded'
            }
            onClickHandler={printPickSlipsAndMarkInFulfillment}
            text='Print Pick Slips &amp; Move to In Fulfillment'
          />
        </Modal>

        <Modal isOpen={shipModalOpen} contentLabel='Ship Orders' className='modal' onRequestClose={closeShipModal}>
          <div className='grid-x'>
            <div className='cell small-12 modal--close'>
              <button className='button transparent' onClick={closeShipModal}>
                <i className='fi-x' /> close
              </button>
            </div>
          </div>
          <div>
            <h3>Ship Orders</h3>
            <p>Creating shipments will do the following:</p>
            <ul>
              <li>Generate UPS labels</li>
              <li>Mark orders as shipped</li>
              <li>Send tracking numbers to customers</li>
            </ul>
            <Button
              testId='request-shipment'
              isDisabled={canSubmitCreateShipments}
              style={
                canSubmitCreateShipments
                  ? `secondary expanded ${
                      (createShipmentMutation.isLoading || !finishedLoadingShipments) && 'loading'
                    } disabled`
                  : 'secondary expanded'
              }
              onClickHandler={createShipmentSubmit}
              text='Create shipments'
            />
            {shipStatusText && (
              <div>
                <p>
                  <b>{shipStatusText}</b>
                </p>
              </div>
            )}
          </div>
          {finishedLoadingShipments && (
            <div>
              {failedShipmentInvoiceIds.length > 0 && (
                <div>
                  <p>
                    <b>Failed to create shipments for the following orders:</b>
                  </p>
                  <ul>
                    {failedShipmentInvoiceIds.map((invoiceId) => (
                      <li key={invoiceId}>{invoiceId}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className='grid-x'>
                <div className='cell small-6'>
                  <Button
                    key='print-labels'
                    testId='print-labels'
                    isDisabled={!finishedLoadingShipments}
                    style={
                      !finishedLoadingShipments
                        ? `secondary ${
                            (createShipmentMutation.isLoading || !finishedLoadingShipments) && 'loading'
                          } disabled`
                        : 'secondary'
                    }
                    onClickHandler={printLabels}
                    text='Print UPS Labels'
                  />
                </div>
                <div className='cell small-6'>
                  <Button
                    key='print-packing-slips'
                    testId='print-packing-slips'
                    isDisabled={!finishedLoadingShipments}
                    style={
                      !finishedLoadingShipments
                        ? `secondary ${
                            (createShipmentMutation.isLoading || !finishedLoadingShipments) && 'loading'
                          } disabled`
                        : 'secondary'
                    }
                    onClickHandler={printPackingSlipsForShippedOrder}
                    text='Print Packing Slips'
                  />
                </div>
              </div>
            </div>
          )}
        </Modal>
        <LoadingError error={ordersContext.error} />
        <LoadingError error={getShipmentsQuery.error} />
        <SavingError error={scheduleOrdersMutation.error} />
        <SavingError error={createShipmentMutation.error} />
        <SavingError error={saveOrderStatusMutation.error} />
        {tableRendered}
      </div>
    </>
  );
};

AdminActiveOrdersV2.propTypes = {
  Status: PropTypes.string,
  ScanIndexForward: PropTypes.bool,
};

AdminActiveOrdersV2.defaultProps = {};

export default AdminActiveOrdersV2;
