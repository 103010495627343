import _ from 'lodash';
import React from 'react';
import LoadingError from '../LoadingError';
import { useAdminGetQuickbooksStatusQuery } from '../../generated/types-and-hooks';
import log from 'loglevel';

const AdminQuickbooksStatus = () => {
  const quickbooksStatusContext = useAdminGetQuickbooksStatusQuery();

  return (
    <div>
      <h3>Quickbooks Status</h3>
      <LoadingError error={quickbooksStatusContext.error} />
      {quickbooksStatusContext.data?.GetQuickbooksStatus && (
        <>
          <h2>Connected</h2>
          <p>Settings Ok: {(quickbooksStatusContext.data?.GetQuickbooksStatus.SettingsOk && `✅`) || `❌`}</p>
          <p>Company: {quickbooksStatusContext.data?.GetQuickbooksStatus.CompanyInfo?.CompanyName}</p>
          <ul>
            <li>{quickbooksStatusContext.data.GetQuickbooksStatus.CompanyInfo?.CompanyAddr?.Line1}</li>
            <li>
              {quickbooksStatusContext.data.GetQuickbooksStatus.CompanyInfo?.CompanyAddr?.City}
              {', '}
              {quickbooksStatusContext.data?.GetQuickbooksStatus.CompanyInfo?.CompanyAddr?.CountrySubDivisionCode}{' '}
              {quickbooksStatusContext.data?.GetQuickbooksStatus.CompanyInfo?.CompanyAddr?.PostalCode}
            </li>
          </ul>
        </>
      )}
    </div>
  );
};

AdminQuickbooksStatus.propTypes = {};

AdminQuickbooksStatus.defaultProps = {};

export default AdminQuickbooksStatus;
