import React from 'react';
import PropTypes from 'prop-types';
import LoadingError from '../LoadingError';
import Loading from '../Loading';
import { formatCurrency } from '../../utils/cart';
import _ from 'lodash';
import { Link } from 'gatsby';
import { AdminGiftCard, useInfiniteGetGiftCardsPageQuery } from '../../generated/types-and-hooks';

const AdminGiftCards = ({ Status, SortAscending: ScanIndexForward = true }) => {
  const giftCardsContext = useInfiniteGetGiftCardsPageQuery(
    { Status, ScanIndexForward },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage?.GetGiftCardsPage?.LastEvaluatedKey) {
          return {
            ExclusiveStartKey: lastPage?.GetGiftCardsPage?.LastEvaluatedKey,
          };
        }
      },
    }
  );

  const allGiftCards = _.flatten(giftCardsContext?.data?.pages.map((page) => page.GetGiftCardsPage?.GiftCards));

  const renderGiftCard = ({
    Id,
    RecipientName,
    RecipientEmail,
    SenderName,
    TimestampCreated,
    Amount,
    Balance,
    OrderId,
  }: AdminGiftCard) => (
    <tr key={Id}>
      <td>{new Date(TimestampCreated * 1000).toLocaleString()}</td>
      <td>
        <Link to={`/app/admin/gift-card/${Id}`}>{Id}</Link>
      </td>
      <td>
        <a href={`mailto:${RecipientEmail}`}>{RecipientName}</a>
      </td>
      <td>{SenderName}</td>
      <td>{formatCurrency(Amount)}</td>
      <td>{formatCurrency(Balance)}</td>
    </tr>
  );

  const fetchMoreRendered = giftCardsContext.hasNextPage ? (
    <tr>
      <td key='hasMore' colSpan={6}>
        <button disabled={giftCardsContext.isFetchingNextPage} onClick={() => giftCardsContext.fetchNextPage()}>
          Fetch more results
        </button>
      </td>
    </tr>
  ) : null;

  return (
    <div>
      <h3>Gift Cards</h3>
      <p>
        By Status = {Status} {ScanIndexForward ? 'By Date' : 'Reverse By Date'}
      </p>
      {giftCardsContext.isLoading && <Loading />}
      <LoadingError error={giftCardsContext.error} />
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Id</th>
            <th>Recipient</th>
            <th>Sender</th>
            <th>Amount</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          {_.map(allGiftCards, renderGiftCard)}
          {fetchMoreRendered}
        </tbody>
      </table>
    </div>
  );
};

AdminGiftCards.propTypes = {
  Status: PropTypes.string,
  SortAscending: PropTypes.bool,
};

AdminGiftCards.defaultProps = {};

export default AdminGiftCards;
