import React from 'react';
import { Router } from '@reach/router';
import Layout from '../../components/layout';
import PrivateRoute from '../../components/PrivateRoute';
import AdminRoute from '../../components/admin/AdminRoute';
import AdminDashboardV2 from '../../components/admin/AdminDashboardV2';
import AdminProducts from '../../components/admin/AdminProducts';
import AdminProductDetail from '../../components/admin/AdminProductDetail';
import UserProfileHooks from '../../components/user/UserProfileHooks';
import AdminTags from '../../components/admin/AdminTags';
import AdminTagsCollections from '../../components/admin/AdminTagsCollections';
import Cart from '../../components/cart/Cart';
import CartCheckoutShipping from '../../components/cart/CartCheckoutShipping';
import CartCheckoutShippingAddress from '../../components/cart/CartCheckoutShippingAddress';
import CartCheckoutReview from '../../components/cart/CartCheckoutReview';
import OrderDetail from '../../components/admin/OrderDetail';
import AdminOrder from '../../components/admin/AdminOrder';
import AdminWarrantyStates from '../../components/admin/AdminWarrantyStates';
import Search from '../../components/products/Search';
import EmailOptOut from '../../components/EmailOptOut';
import CartCheckoutBillingPayment from '../../components/cart/CartCheckoutBillingPayment';
import AdminProductNotifications from '../../components/admin/AdminProductNotifications';
import GiftCard from '../../components/GiftCard';
import AdminGiftCards from '../../components/admin/AdminGiftCards';
import AdminGiftCardDetail from '../../components/admin/AdminGiftCardDetail';
import ProductDataImporter from '../../components/admin/ProductDataImporter';
import AdminQuickbooksOAuthCallback from '../../components/admin/AdminQuickbooksOAuthCallback';
import AdminSiteSettings from '../../components/admin/AdminSiteSettings';
import AdminOrdersV2 from '../../components/admin/AdminOrdersV2';
import AdminQuickbooksStatus from '../../components/admin/AdminQuickbooksStatus';
import Reports from '../../components/admin/Reports';

const App = (props) => {
  return (
    <Layout {...props} title='App'>
      <Router>
        <Search path='/app/search' />
        <EmailOptOut path='/app/opt-out/:email' />
        <PrivateRoute path='/app/profile' component={UserProfileHooks} />

        <AdminRoute path='/app/admin' component={AdminDashboardV2} />
        <AdminRoute path='/app/admin/products' component={AdminProducts} />
        <AdminRoute path='/app/admin/products/:productId' component={AdminProductDetail} />
        <AdminRoute path='/app/admin/tags' component={AdminTags} />
        <AdminRoute path='/app/admin/tags-collections' component={AdminTagsCollections} />
        <AdminRoute path='/app/admin/tags-collections/:Tag' component={AdminTagsCollections} />
        <AdminRoute path='/app/admin/all-orders-v2/:Status' component={AdminOrdersV2} />
        <AdminRoute path='/app/admin/orders/:Id' component={AdminOrder} />
        <AdminRoute path='/app/admin/warranty-states' component={AdminWarrantyStates} />
        <AdminRoute path='/app/admin/product-notifications/:Storefront' component={AdminProductNotifications} />
        <AdminRoute path='/app/admin/gift-cards/:Status' component={AdminGiftCards} />
        <AdminRoute path='/app/admin/gift-card/:Id' component={AdminGiftCardDetail} />
        <AdminRoute path='/app/admin/product-importer' component={ProductDataImporter} />
        <AdminRoute path='/app/admin/callback' component={AdminQuickbooksOAuthCallback} />
        <AdminRoute path='/app/admin/site-settings' component={AdminSiteSettings} />
        <AdminRoute path='/app/admin/quickbooks-status' component={AdminQuickbooksStatus} />
        <AdminRoute path='/app/admin/reports' component={Reports} />

        <Cart path='/app/cart' />
        <CartCheckoutShippingAddress path='/app/checkout/shipping-address' />
        <CartCheckoutShipping path='/app/checkout/shipping' />
        <CartCheckoutBillingPayment path='/app/checkout/billing-payment' />
        <CartCheckoutReview path='/app/checkout/review' />
        <OrderDetail path='/app/orders/:OrderId' component={OrderDetail} />
        <GiftCard path='/app/gift-cards/:Index' />
      </Router>
    </Layout>
  );
};

export default App;
