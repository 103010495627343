import _ from 'lodash';
import React from 'react';
import { Link } from 'gatsby';
import slugify from 'slugify';
import LoadingError from '../LoadingError';
import { useAllTags } from '../hooks';

const renderTagRowLinked = (tag) => (
  <tr key={tag}>
    <td>
      <Link to={`/${slugify(_.toLower(tag))}`}>{tag}</Link>
    </td>
  </tr>
);

const AdminTags = () => {
  const tagsContext = useAllTags();

  const renderedTagsPlugtrays = tagsContext.isLoading ? (
    <tr>
      <td>Loading .....</td>
    </tr>
  ) : (
    _.map(_.get(tagsContext, 'data.TagsPlugtrays'), renderTagRowLinked)
  );
  const renderedTags = tagsContext.isLoading ? (
    <tr>
      <td>Loading .....</td>
    </tr>
  ) : (
    _.map(_.get(tagsContext, 'data.Tags'), renderTagRowLinked)
  );

  return (
    <div>
      <h3>TagsPlugtrays</h3>
      <LoadingError error={tagsContext.error} />
      <table>
        <tbody>{renderedTagsPlugtrays}</tbody>
      </table>
      <h3>Tags</h3>
      <table>
        <tbody>{renderedTags}</tbody>
      </table>
    </div>
  );
};

AdminTags.propTypes = {};

AdminTags.defaultProps = {};

export default AdminTags;
