import _ from 'lodash';
import React, { useState } from 'react';
import { States } from '../utils/states';

const renderStateOption = (Name, ShortName) => (
  <option key={ShortName} value={ShortName}>
    {Name}
  </option>
);

const StateInput = ({ labelText, defaultValue = 'AL', key, required }) => {
  const [value, setValue] = useState(defaultValue);
  const onChange = (e) => setValue(e.target.value);

  const renderedOptions = _.map(States, renderStateOption);

  const rendered = (
    <label key={key}>
      {labelText}
      <select value={value} onChange={onChange} required={required}>
        {renderedOptions}
      </select>
    </label>
  );
  return [value, rendered, setValue];
};

export default StateInput;
