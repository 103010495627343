import _ from 'lodash';
import React, { useState } from 'react';
import TextInput, {
  validateCreditCardNumber,
  validateExpirationDate,
  validateCVV,
  keyDownAllowOnlyNumbers,
} from '../TextInput';
import log from 'loglevel';

const translateError = (err) => {
  if (_.includes(err, '(E00114)')) {
    return 'Your credit card expired (we only save it for 15 minutes for security reasons).  Please re-enter your card and try again.';
  }
  return err;
};

const CartCheckoutPayment = ({ location }) => {
  const Errors = _.map(_.get(location, 'state.Errors') || [], translateError);
  const [validationVisible, setValidationVisible] = useState(false);

  const [
    SecurityCode,
    SecurityCodeRendered,
    setSecurityCode, // Not used
    setSecurityCodeFocus,
  ] = TextInput({
    testId: 'credit-card-cvv',
    validationVisible,
    validator: validateCVV,
    labelText: 'Security code',
    placeholderText: '000',
    onKeyDown: keyDownAllowOnlyNumbers,
    autoComplete: 'cc-csc',
  });

  const [
    ExpirationDate,
    ExpirationDateRendered,
    setExpirationDate, // Not used
    setExpirationDateFocus,
  ] = TextInput({
    testId: 'credit-card-expiration',
    validationVisible,
    validator: validateExpirationDate,
    labelText: 'Expiration date',
    placeholderText: 'MM/YY',
    helpText: 'MM/YY',
    onKeyDown: keyDownAllowOnlyNumbers,
    validationPattern: '[0-9]{2}/[0-9]{2,4}',
    autoComplete: 'cc-exp',
    /* This works on MVG, but is throwing react errors here, omitting for now
    onValid: (validValue) => {
      log.debug({ validValue }, 'got ExpirationDate onValid, setting focus to CVV');
      setSecurityCodeFocus();
    },
    */
  });

  const [CreditCardNumber, CreditCardRendered] = TextInput({
    testId: 'credit-card-number',
    validationVisible,
    validator: validateCreditCardNumber,
    labelText: 'Credit card number',
    onKeyDown: keyDownAllowOnlyNumbers,
    autoComplete: 'cc-number',
    /*  This works on MVG, but is throwing react errors here, omitting for now
    onValid: (validValue) => {
      log.debug({ validValue }, 'got CreditCardNumber onValid, setting focus to the expiration date');
      setExpirationDateFocus();
    },
    */
  });

  const validateForm = () => {
    const fieldValues = [CreditCardNumber, ExpirationDate, SecurityCode];
    let passedValidation = true;
    _.map(fieldValues, (value) => {
      if (_.isUndefined(value)) {
        passedValidation = false;
      }
    });
    if (!passedValidation) {
      setValidationVisible(true);
      return false;
    }
    return true;
  };

  let renderedErrors;
  if (_.size(Errors) > 0) {
    const errs = _.map(Errors, (error, index) => <p key={index}>{error}</p>);
    renderedErrors = (
      <div className='callout alert'>
        <h4>Your order could not be completed.</h4>
        {errs}
      </div>
    );
  }

  const rendered = (
    <>
      {renderedErrors}
      <fieldset className='grid-x grid-padding-x fieldset--center-legend'>
        <legend className='header__small-caps'>Payment Information</legend>
        <div className='cell small-12 medium-6'>{CreditCardRendered}</div>
        <div className='cell small-6 medium-3'>{ExpirationDateRendered}</div>
        <div className='cell small-4 medium-3'>{SecurityCodeRendered}</div>
      </fieldset>
    </>
  );

  const payload = { CreditCardNumber, ExpirationDate, SecurityCode };
  return [rendered, payload, validateForm];
};

export default CartCheckoutPayment;
