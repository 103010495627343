import _ from 'lodash';
import React from 'react';
import { saveBillingPayment, saveBillingOnly } from '../../graphql/cart';
import { navigate } from 'gatsby';
import CartCheckoutPayment from './CartCheckoutPayment';
import CartCheckoutCreateAccount from './CartCheckoutCreateAccount';
import CartCheckoutBilling from './CartCheckoutBilling';
import SEO from '../seo';
import CartCheckoutOrderSummary from './CartCheckoutOrderSummary';
import UserInfoSummary from './CartUserInfoSummary';
import Button from '../Button';
import { serviceCodeLookup } from '../../utils/cart';
import PropTypes from 'prop-types';
import { useCheckoutContext, postPaymentToAuthorize } from './hooks';
import log from 'loglevel';
import config from '../../../config';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import SavingError from '../SavingError';
import LoadingError from '../LoadingError';
import CartCheckoutGiftCardRedeem from './CartCheckoutGiftCardRedeem';
import { doGraphqlQuery } from '../../fetcher';

const ENV_CONFIG = config.get();

const postBillingPayment = async (billingPaymentPayload) => {
  // throw {errors: [{message:'billing payment saving error!'}, {message:'another error'}]};
  let mutation = saveBillingPayment;
  if (!_.has(billingPaymentPayload, 'Token')) {
    mutation = saveBillingOnly;
  }
  const result = await doGraphqlQuery(mutation, billingPaymentPayload);
  log.debug({ result }, 'posted billing payment with result');
  return result.data;
};

const CartCheckoutBillingPayment = ({ location }) => {
  const queryClient = useQueryClient();
  const checkoutContext = useCheckoutContext({ location });

  const [billingRendered, billingPayload, validateBilling] = CartCheckoutBilling(checkoutContext);

  const [paymentRendered, paymentPayload, validatePayment] = CartCheckoutPayment({ location });

  const [createAccountRendered, createAccountPayload, validateCreateAccount] = CartCheckoutCreateAccount(
    billingPayload.Email
  );

  const redeemGiftCardRendered = CartCheckoutGiftCardRedeem(checkoutContext);

  const submitMutation = useMutation(postBillingPayment, {
    onSuccess: (data) => {
      log.debug({ data }, 'finished saving payment token, redirecting to review');
      queryClient.setQueryData(['checkout'], data.CheckoutV1);
      navigate('/app/checkout/review');
    },
  });
  const authorizePaymentMutation = useMutation(postPaymentToAuthorize, {
    onSuccess: (data) => {
      const { CreateAccount, Password } = createAccountPayload;
      const CreateAccountPassword = CreateAccount ? Password : undefined;
      log.debug({ data, CreateAccountPassword }, 'got data!');
      const billingPaymentPayload = {
        CreateAccountPassword,
        Token: data.opaqueData.dataValue,
        ...billingPayload,
      };
      log.debug({ billingPaymentPayload }, 'generated payment payload to save to acorn!');
      submitMutation.mutate(billingPaymentPayload);
    },
  });

  const submit = async (e) => {
    e.preventDefault();
    const PaymentRequired = _.get(checkoutContext, 'data.PaymentRequired');
    if (PaymentRequired) {
      if (!validatePayment()) {
        log.debug('failed payment validation');
        return;
      }
    }
    if (!validateBilling() || !validateCreateAccount()) {
      log.debug('failed billing or create account validation');
      return;
    }

    if (PaymentRequired) {
      log.debug({ billingPayload, paymentPayload, createAccountPayload }, 'handling submit');
      const { FirstName, LastName, Zip } = billingPayload;
      const { CreditCardNumber, ExpirationDate, SecurityCode } = paymentPayload;
      const { month, year } = ExpirationDate;
      const paymentData = {
        authData: {
          clientKey: ENV_CONFIG.authorizeNetClientKey,
          apiLoginID: ENV_CONFIG.authorizeNetApiLoginId,
        },
        cardData: {
          cardNumber: CreditCardNumber,
          month,
          year,
          cardCode: SecurityCode,
          zip: Zip,
          fullName: `${FirstName} ${LastName}`,
        },
      };
      log.debug({ paymentData }, 'dispatching payment data!');
      authorizePaymentMutation.mutate(paymentData);
    } else {
      const { CreateAccount, Password } = createAccountPayload;
      const CreateAccountPassword = CreateAccount ? Password : undefined;
      const billingTotalPayload = {
        CreateAccountPassword,
        ...billingPayload,
      };
      log.debug({ billingTotalPayload }, 'payment is not required, saving billing payload');
      submitMutation.mutate(billingTotalPayload);
    }
  };

  const renderAddress = ({
    // eslint-disable-next-line
    FirstName,
    LastName,
    Address,
    Address2,
    City,
    State,
    Zip,
    Phone,
    Email,
  }) => (
    <div>
      <p>
        {FirstName} {LastName}
      </p>
      <p>{Address}</p>
      <p>{Address2 ? Address2 : ''}</p>
      <p>
        {City}, {State} {Zip}
      </p>
      <p>
        <i className='fi-telephone'></i> {Phone}
      </p>
      <p>
        <i className='fi-mail'></i> {Email}
      </p>
      <p>
        <i className='fi-arrow-right'></i> Shipping{' '}
        {serviceCodeLookup[_.get(checkoutContext, 'data.Cart.ShippingService.shippingService.ServiceCode')]}
      </p>
    </div>
  );

  const shippingRendered = _.get(checkoutContext, 'data.Cart.Shipping') ? (
    renderAddress(checkoutContext.data.Cart.Shipping)
  ) : (
    <p>Loading.....</p>
  );

  const hasPaymentErrorsRendered = _.get(location, 'state.Errors') ? (
    <div className='cell small-12 callout alert'>
      <h4>Your order could not be completed</h4>
      <p>There was an error processing your payment. Please check your payment information and try again.</p>
    </div>
  ) : null;

  const isLoading = authorizePaymentMutation.isLoading || submitMutation.isLoading;
  return (
    <div className='grid-x grid-margin-x'>
      {hasPaymentErrorsRendered}
      <div className='cell small-12 medium-8'>
        <SEO title='Billing &amp; Payment Information' includeAcceptJS={true} />
        <UserInfoSummary
          heading='Shipping information'
          editLink='/app/checkout/shipping-address'
          infoRendered={shippingRendered}
        />
        <form onSubmit={submit}>
          <LoadingError error={checkoutContext.error} />
          {billingRendered}
          {ENV_CONFIG.enableGiftCards && redeemGiftCardRendered}
          {_.get(checkoutContext, 'data.PaymentRequired') && paymentRendered}
          {createAccountRendered}
          <div className='grid-x grid-padding-x'>
            <div className='cell small-12'>
              <Button
                isDisabled={isLoading}
                type='submit'
                style={isLoading ? 'primary expanded loading' : 'primary expanded'}
                text='Continue to review order'
                testId='submit-billing'
              />
            </div>
            <SavingError error={authorizePaymentMutation.error} />
            <SavingError error={submitMutation.error} />
          </div>
        </form>
      </div>
      <div className='cell small-12 medium-4'>
        <CartCheckoutOrderSummary {..._.get(checkoutContext, 'data')} />
      </div>
    </div>
  );
};

CartCheckoutBillingPayment.propTypes = {
  location: PropTypes.object,
};

export default CartCheckoutBillingPayment;
