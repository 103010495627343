import _ from 'lodash';
import React from 'react';
import { navigate } from 'gatsby';
import { formatCurrency, serviceCodeLookup } from '../../utils/cart';
import UserInfoSummary from './CartUserInfoSummary';
import Button from '../Button';
import log from 'loglevel';
import { resolveCheckout, useCheckoutContext } from './hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import SavingError from '../SavingError';
import { generateImageCdnPath, ImageSize } from '../../utils/images';

// If these statuses are encountered -> redirect to payment page & display errors from server
const REJECTED_STATUS = [
  'PAYMENT_ERROR', // Should never happen, but .....
  'PAYMENT_REJECTED',
  'DECLINED',
];

const CartCheckoutReview = () => {
  const queryClient = useQueryClient();
  const submitMutation = useMutation(_.partial(resolveCheckout, false), {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['checkout']);
      queryClient.invalidateQueries(['cart']);
      log.debug({ data, variables }, 'finished submitting order');
      const { OrderId, Status, Errors } = data;
      log.debug({ OrderId, Status }, 'got status');
      if (_.includes(REJECTED_STATUS, Status)) {
        navigate('/app/checkout/billing-payment', {
          state: { Errors },
        });
        return;
      } else if (OrderId) {
        navigate(`/app/orders/${OrderId}`);
      }
    },
  });
  const checkoutContext = useCheckoutContext();

  const submit = (e) => {
    e.preventDefault();
    submitMutation.mutate();
  };

  const renderGiftCard = ({ Amount, RecipientName, RecipientEmail, SenderName, Message, Index }) => (
    <div key={Index} className='cell small-12 cart--product-row'>
      <div className='grid-x grid-margin-x'>
        <div className='cell small-6 medium-2'>
          {
            //renderedImage
          }
        </div>
        <div className='cell small-6 medium-4'>
          <p className='product-row--title'>
            <span>
              To {RecipientName} {RecipientEmail}
            </span>
            <strong>From {SenderName}</strong>
          </p>
          <p className='product-row--item-id'>Message : {Message}</p>
        </div>

        <div className='cell small-4 medium-2 currency'>
          <p>{formatCurrency(Amount)}</p>
        </div>

        <div className='cell small-4 medium-2 currency'>
          <p>1</p>
        </div>
        <div className='cell small-4 medium-2 currency'>{formatCurrency(Amount)}</div>
      </div>
    </div>
  );
  const buildProduct = ({
    // eslint-disable-next-line
    Id,
    QuantityValidated,
    Status,
    PricePlugtrays,
    BotanicalName,
    CommonName,
    NamePlugtrays,
    ImageHashes,
  }) => {
    const renderedImage = <img alt={NamePlugtrays} src={generateImageCdnPath(_.get(ImageHashes, [0]), ImageSize.Small)} />;

    return (
      <div key={Id} className='cell small-12 cart--product-row'>
        <div className='grid-x grid-margin-x'>
          {QuantityValidated === 0 && (
            <div className='cell small-12 callout alert small' role='alert'>
              <h3 className='header__small-caps'>Out of stock!</h3>
              <p>
                We&apos;re so sorry! {BotanicalName} {CommonName || NamePlugtrays} is out of stock and will be removed
                from your order.
              </p>
            </div>
          )}
          <div className='cell small-6 medium-2'>{renderedImage}</div>
          <div className='cell small-6 medium-4'>
            <p className='product-row--title'>
              <span>{BotanicalName}</span>
              <strong>{CommonName || NamePlugtrays}</strong>
            </p>
            <p className='product-row--item-id'>Product ID: {Id}</p>
            {Status !== 'Ok' && <p className='product-row--stock-warning'>Limited stock!</p>}
          </div>

          <div className='cell small-4 medium-2 currency'>
            <p>{formatCurrency(PricePlugtrays)}</p>
          </div>

          <div className='cell small-4 medium-2 currency'>
            <p>{QuantityValidated}</p>
          </div>
          <div className='cell small-4 medium-2 currency'>{formatCurrency(PricePlugtrays * QuantityValidated)}</div>
        </div>
      </div>
    );
  };

  const shippingInfo = (
    <>
      <p>
        {_.get(checkoutContext, 'data.Cart.Shipping.FirstName')} {_.get(checkoutContext, 'data.Cart.Shipping.LastName')}
      </p>
      <p>{_.get(checkoutContext, 'data.Cart.Shipping.Address')}</p>
      <p>{_.get(checkoutContext, 'data.Cart.Shipping.Address2')}</p>
      <p>
        {_.get(checkoutContext, 'data.Cart.Shipping.City')}, {_.get(checkoutContext, 'data.Cart.Shipping.State')}{' '}
        {_.get(checkoutContext, 'data.Cart.Shipping.Zip')}
      </p>
      <p>
        <i className='fi-telephone'></i> {_.get(checkoutContext, 'data.Cart.Shipping.Phone')}
      </p>
      <p>
        <i className='fi-mail'></i> {_.get(checkoutContext, 'data.Cart.Shipping.Email')}
      </p>
      <p>
        <i className='fi-arrow-right'></i> Shipping{' '}
        {serviceCodeLookup[_.get(checkoutContext, 'data.Cart.ShippingService.shippingService.ServiceCode')]}
      </p>
    </>
  );

  const billingInfo = (
    <>
      <p>
        {_.get(checkoutContext, 'data.Cart.Billing.FirstName')} {_.get(checkoutContext, 'data.Cart.Billing.LastName')}
      </p>
      <p>{_.get(checkoutContext, 'data.Cart.Billing.Address')}</p>
      <p>{_.get(checkoutContext, 'data.Cart.Billing.Address2')}</p>
      <p>
        {_.get(checkoutContext, 'data.Cart.Billing.City')}, {_.get(checkoutContext, 'data.Cart.Billing.State')}{' '}
        {_.get(checkoutContext, 'data.Cart.Billing.Zip')}
      </p>
      <p>
        <i className='fi-telephone'></i> {_.get(checkoutContext, 'data.Cart.Billing.Phone')}
      </p>
      <p>
        <i className='fi-mail'></i> {_.get(checkoutContext, 'data.Cart.Billing.Email')}
      </p>
    </>
  );
  const productsRendered = _.map(_.get(checkoutContext, ['data', 'Cart', 'Products']), buildProduct);
  const productQuantitiesValidated = _.sum(
    _.map(_.get(checkoutContext, ['data', 'Cart', 'Products']), ({ QuantityValidated }) => QuantityValidated)
  );
  const giftCards = _.get(checkoutContext, ['data', 'Cart', 'GiftCards']);
  const giftCardsRendered = _.map(giftCards, renderGiftCard);
  const totalItems = productQuantitiesValidated + _.size(giftCards);

  const totalBox = (
    <div className='cell small-12'>
      <div className='grid-x'>
        <div className='cell small-12'>
          <p>
            <strong>
              Subtotal:{' '}
              <span className='currency' data-testid='cartSubtotal'>
                {formatCurrency(
                  _.get(checkoutContext, ['data', 'Subtotal'], 0) +
                    _.get(checkoutContext, ['data', 'GiftCardSubtotal'], 0)
                )}
              </span>
            </strong>
          </p>
          <p>
            Tax:{' '}
            <span className='currency' data-testid='cartTax'>
              {formatCurrency(_.get(checkoutContext, ['data', 'Tax']))}
            </span>
          </p>
          <p>
            Shipping:{' '}
            <span className='currency' data-testid='cartShipping'>
              {formatCurrency(
                _.get(checkoutContext, ['data', 'Cart', 'ShippingService', 'shippingService', 'Price']) || 0
              )}
            </span>
          </p>
          <p className='order-total'>
            Total:{' '}
            <span className='currency' data-testid='cartTotal'>
              {formatCurrency(_.get(checkoutContext, ['data', 'Total']))}
            </span>
          </p>
          {_.get(checkoutContext, 'data.GiftCardRedemptionsTotal') > 0 && (
            <>
              <p>
                Gift Card Redemptions:{' '}
                <span className='currency' data-testid='cartGiftCardRedemptionTotal'>
                  {formatCurrency(_.get(checkoutContext, ['data', 'GiftCardRedemptionsTotal']))}
                </span>
              </p>
              <p className='order-total'>
                Total to Charge:{' '}
                <span className='currency' data-testid='cartTotalToCharge'>
                  {formatCurrency(_.get(checkoutContext, ['data', 'TotalToCharge']))}
                </span>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );

  const itemsInCart = (
    <>
      <div className='cell small-12'>
        <div className='grid-x grid-margin-x show-for-medium product-table--header__faux'>
          <div className='cell medium-6'>Item details</div>
          <div className='cell small-4 medium-2 currency-header'>Price</div>
          <div className='cell small-4 medium-2 table-header__center'>Quantity</div>
          <div className='cell small-4 medium-2 currency-header'>Total</div>
        </div>
      </div>
      {productsRendered}
      {giftCardsRendered}
      {totalBox}
      {totalItems < 1 && <p className='callout warning'>Your cart is empty.</p>}
    </>
  );

  return (
    <div className='grid-x grid-margin-x'>
      <div className='cell small-12 large-8 large-offset-2'>
        <h2>Review &amp; Place Order</h2>
        <form onSubmit={submit}>
          <div className='grid-x grid-margin-x'>
            <div className='cell small-12'>
              <UserInfoSummary
                heading='Shipping information'
                editLink='/app/checkout/shipping-address'
                infoRendered={shippingInfo}
              />
            </div>
            <div className='cell small-12'>
              <UserInfoSummary
                heading='Billing information'
                editLink='/app/checkout/billing-payment'
                infoRendered={billingInfo}
              />
            </div>
            <div className='cell small-12'>
              <UserInfoSummary heading='Order details' editLink='/app/cart' infoRendered={itemsInCart} />
            </div>

            <div className='cell small-12 order-summary--container'></div>
            <div className='cell small-12'>
              <Button
                isDisabled={submitMutation.isLoading || totalItems < 1}
                type='submit'
                style={submitMutation.isLoading ? 'primary expanded loading' : 'primary expanded'}
                text='Place Order'
              />
              <br />
              <SavingError error={submitMutation.error} />
              <p className='help-text' style={{ textAlign: 'center' }}>
                Your card will be charged when you place your order.
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CartCheckoutReview;
