import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

type CheckboxParams = {
 labelText?: string
 required?: boolean
 defaultValue?: boolean
 key?: string
 testId?: string 
}

const CheckboxInput = ({ labelText, required, defaultValue = false, key, testId }: CheckboxParams) : [boolean, JSX.Element, Function, Function]=> {
  const [checked, setChecked] = useState(defaultValue);

  useEffect(() => {
    setChecked(defaultValue);
  }, [defaultValue]);

  const setValue = (newChecked) => setChecked(newChecked);
  const onChange = () => setChecked(!checked);
  const reset = () => setChecked(defaultValue);

  const rendered = (
    <label key={key}>
      <input type='checkbox' checked={checked} onChange={onChange} required={required} data-testid={testId} />
      {labelText}
    </label>
  );

  return [checked, rendered, setValue, reset];
};

CheckboxInput.propTypes = {
  labelText: PropTypes.string,
  required: PropTypes.bool,
  key: PropTypes.string,
};

export default CheckboxInput;
