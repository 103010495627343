// ProductGrid for usage when products are already hydrated ( like from search )
import _ from 'lodash';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Product } from './ProductGrid';

export const ProductGridHydrated = ({ Products }) => {
  const renderedProducts = _.map(Products, Product);
  return (
    <div className='grid-x grid-margin-x grid-margin-y grid-padding-x grid-padding-y'>
      <Fragment>{renderedProducts}</Fragment>
    </div>
  );
};

ProductGridHydrated.defaultProps = {
  Products: [],
};

ProductGridHydrated.propTypes = {
  Products: PropTypes.array,
};

ProductGridHydrated.displayName = 'ProductGridHydrated';

export default ProductGridHydrated;
