import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const ExtraShippingText = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          markdownRemark(frontmatter: { component: { eq: "extraShippingText" } }) {
            html
            frontmatter {
              component
              display
            }
          }
        }
      `}
      render={(data) => (
        <div
          className='callout warning cell small-12 waive-warranty'
          style={{ display: data.markdownRemark.display }}
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        ></div>
      )}
    />
  );
};

export default ExtraShippingText;
