import PropTypes from 'prop-types';
import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import Loading from '../Loading';
import { formatBool, formatCurrency } from '../../utils/cart';
import { Link } from 'gatsby';
import {
  CartProduct,
  GiftCard,
  MutationCreateShipmentForOrderArgs,
  Order,
  OrderGiftCardRedemption,
  OrderStatus,
  PlantType,
  ShipmentRequestType,
  ShipmentStatus,
  UpsShipment,
  useAdminCreateShipmentForOrderMutation,
  useAdminGetOrderDetailQuery,
  useAdminSaveOrderAdminMessageMutation,
  useAdminSaveOrderStatusMutation,
  useAdminScheduleOrdersMutation,
  useAdminVoidShipmentMutation,
} from '../../generated/types-and-hooks';
import log from 'loglevel';
import { useQueryClient } from '@tanstack/react-query';
import { PrintProductType, createAllPackingSlips } from './OrderPdfs';
import LoadingError from '../LoadingError';
import SavingError from '../SavingError';
import AdminZebraPicker from './AdminZebraPicker';
import Modal from 'react-modal';
import Button from '../Button';

const overriddenAddressStyle = {
  color: 'red',
  textDecoration: 'line-through',
};

const linkToSendOrderEmail = ({ Storefront, InvoiceId }: Order, email: string) => (
  <a href={`mailto:${email}?subject=Re: ${_.capitalize(Storefront)} Order #${InvoiceId}`}>{email}</a>
);

const linkToTrackUpsShipment = (trackingNumber) => (
  <a href={`https://www.ups.com/track?track=yes&trackNums=${trackingNumber}`} target='_blank'>
    {trackingNumber}
  </a>
);

const formatDateTime = (timestamp: number): string => new Date(timestamp * 1000).toLocaleString();

const formatDate = (timestamp: number): string => new Date(timestamp * 1000).toLocaleDateString();

const formatDateForPicker = (timestamp: number) => new Date(timestamp * 1000).toISOString().slice(0, 10);

const buildProductTableRow = (product: CartProduct) => {
  let Price;
  let Name;
  switch (product.PlantType) {
    case PlantType.Plugtray:
      Price = product.PricePlugtrays;
      Name = product.NamePlugtrays;
      break;
    case PlantType.Collection:
    case PlantType.Pot:
      Price = product.Price;
      Name = product.Name;
      break;
    default:
      throw new Error(`Invalid Product PlantType:${product.PlantType}`);
  }
  return (
    <tr key={`${product.Id}-${product.PlantType}`}>
      <td>{product.PlantType}</td>
      <td>
        <Link to={`/app/admin/products/${product.Id}`}>{product.Id}</Link>
      </td>
      <td>{Name}</td>
      <td>{product.QuantityValidated}</td>
      <td>{product.QuantityBackorder! > 0 ? product.QuantityBackorder : null}</td>
      <td>{product.PotCount > 0 ? product.PotCount : null}</td>
      <td>{product.PlugtrayCount > 0 ? product.PlugtrayCount : null}</td>
      <td>{formatCurrency(Price)}</td>
      <td>{formatCurrency(product.LineSubtotal)}</td>
    </tr>
  );
};

const formatShippingService = (ServiceCode) => {
  switch (ServiceCode) {
    case '03':
      return 'UPS Ground';
    case '02':
      return 'UPS 2-Day';
    default:
      return `Unknown ServiceCode:${ServiceCode}`;
  }
};

const renderGiftCard = ({ Index, Amount, RecipientName, RecipientEmail }: GiftCard) => (
  <tr key={Index}>
    <td>Gift Card</td>
    <td />
    <td>
      To <a href={`mailto:${RecipientEmail}`}>{RecipientName}</a>
    </td>
    <td>1</td>
    <td colSpan={3} />
    <td>{formatCurrency(Amount)}</td>
    <td>{formatCurrency(Amount)}</td>
  </tr>
);

const renderRedeemedGiftCard = ({ Id, Balance }: OrderGiftCardRedemption) => (
  <tr key={Id}>
    <td>
      <p>
        <Link to={`/app/admin/gift-card/${Id}`}>{Id}</Link>
      </p>
    </td>
    <td>{formatCurrency(Balance)}</td>
  </tr>
);

type renderShipmentProps = {
  voidShipmentClick: (Id: string) => void;
  PrintShipments: (Shipments: UpsShipment[]) => Promise<void>;
};
const renderShipment = (
  { voidShipmentClick, PrintShipments }: renderShipmentProps,
  Shipment: UpsShipment,
  index: number
) => {
  const { Id, Status, TimestampCreated, TotalCharges, Packages, Type, Errors, AddressOverride } = Shipment;
  const actions = [
    Status === ShipmentStatus.Created && (
      <Fragment key={index}>
        <div style={{ padding: '0.5rem 0' }}>
          <a className='button no-span secondary' key='printLabel' onClick={() => PrintShipments([Shipment])}>
            Print Labels
          </a>
        </div>
        <div style={{ padding: '0.5rem 0' }}>
          <a
            className='button no-span hollow alert'
            key='voidShipment'
            onClick={() => window.confirm('Are you sure you want to void this shipment?') && voidShipmentClick(Id)}
          >
            Void Shipment
          </a>
        </div>
      </Fragment>
    ),
  ];
  return (
    <Fragment key={`${Id}-${index}`}>
      {
        <tr>
          <td>{Id}</td>
          <td>{Status}</td>
          <td>{Type}</td>
          <td>{formatDate(TimestampCreated)}</td>
          <td>{formatCurrency(TotalCharges)}</td>
          <td>{actions}</td>
          <td>{Packages && Packages[0] && linkToTrackUpsShipment(Packages[0].TrackingNumber)}</td>
        </tr>
      }
      {Packages?.slice(1).map(({ TrackingNumber }, index) => (
        <tr key={`${Id}-${index}`}>
          <td colSpan={6}></td>
          <td>{linkToTrackUpsShipment(TrackingNumber)}</td>
        </tr>
      ))}
      {AddressOverride && (
        <tr>
          <td></td>
          <td colSpan={6}>
            <b>Address Override</b>
            <p>
              {AddressOverride.FirstName} {AddressOverride.LastName}
              <br />
              {AddressOverride.Address}
              <br />
              {AddressOverride.Address2 ? (
                <>
                  {AddressOverride.Address2} <br />
                </>
              ) : (
                ''
              )}
              {AddressOverride.City}, {AddressOverride.State} {AddressOverride.Zip} <br />
              {AddressOverride.Phone}
            </p>
          </td>
        </tr>
      )}
      {Errors?.map(({ Code, Message }, index) => (
        <tr key={`error-${index}`}>
          <td></td>
          <td colSpan={2}>Error Code: {Code}</td>
          <td colSpan={4}>{Message}</td>
        </tr>
      ))}
    </Fragment>
  );
};

const PRODUCT_COLUMN_FOOTER_OFFSET = 9;

const AdminOrder = ({ Id }) => {
  Modal.setAppElement('#___gatsby');
  const queryClient = useQueryClient();

  const [setStatusModalOpen, setSetStatusModalOpen] = React.useState<boolean>(false);
  const [newStatus, setNewStatus] = React.useState<OrderStatus>(OrderStatus.Cancelled);
  const closeSetStatusModal = () => setSetStatusModalOpen(false);
  const openSetStatusModal = () => {
    if (Status) {
      setNewStatus(Status);
    }
    setSetStatusModalOpen(true);
  };

  const [initiallyLoaded, setInitiallyLoaded] = useState<boolean>(false);
  const [adminMessage, setAdminMessage] = useState<string>('');
  const [isEditingAdminMessage, setIsEditingAdminMessage] = useState<boolean>(false);

  const [createShipmentModalOpen, setCreateShipmentModalOpen] = React.useState<boolean>(false);

  const closeCreateShipmentModal = () => setCreateShipmentModalOpen(false);
  const [newShipmentTypeSelected, setNewShipmentTypeSelected] = useState<ShipmentRequestType>(ShipmentRequestType.All);
  const [overrideAddressChecked, setOverrideAddressChecked] = useState<boolean>(false);
  const [overrideFirstName, setOverrideFirstName] = useState<string>('');
  const [overrideLastName, setOverrideLastName] = useState<string>('');
  const [overrideAddress, setOverrideAddress] = useState<string>('');
  const [overrideAddress2, setOverrideAddress2] = useState<string>('');
  const [overrideCity, setOverrideCity] = useState<string>('');
  const [overrideState, setOverrideState] = useState<string>('');
  const [overrideZip, setOverrideZip] = useState<string>('');
  const [overridePhone, setOverridePhone] = useState<string>('');
  const [overrideEmail, setOverrideEmail] = useState<string>('');
  const [overrideUpsMessage, setOverrideUpsMessage] = useState<string>('');

  const [scheduleShipmentModalOpen, setScheduleShipmentModalOpen] = React.useState<boolean>(false);
  const openScheduleShipmentModal = () => {
    setScheduledDate(EstimatedShippingDate ? formatDateForPicker(EstimatedShippingDate) : '');
    setScheduledDatePlugtrays(
      EstimatedShippingDatePlugtrays ? formatDateForPicker(EstimatedShippingDatePlugtrays) : ''
    );
    setScheduleShipmentModalOpen(true);
  };
  const closeScheduleShipmentModal = () => setScheduleShipmentModalOpen(false);
  const [scheduledDate, setScheduledDate] = useState<string>('');
  const [scheduledDatePlugtrays, setScheduledDatePlugtrays] = useState<string>('');

  const orderContext = useAdminGetOrderDetailQuery({ Id });

  useEffect(() => {
    if (!initiallyLoaded && orderContext.data) {
      setInitiallyLoaded(true);
      setAdminMessage(orderContext.data.GetOrder?.AdminMessage || '');
    }
  }, [orderContext.data]);

  const shipToOverridden =
    orderContext.data?.GetShipmentsForOrder.filter(
      (shipment) => shipment?.AddressOverride && shipment.AddressOverride.Address
    ).length! > 0;

  const AddressOverride = _.sortBy(orderContext.data?.GetShipmentsForOrder, 'TimestampCreated')
    .reverse()
    .find((shipment) => shipment?.AddressOverride && shipment.AddressOverride.Address)?.AddressOverride;

  const openCreateShipmentModal = () => {
    let { Shipping, ShippingService } = orderContext.data?.GetOrder || {};
    let { DeliveryInstructions } = ShippingService || {};
    if (shipToOverridden) {
      Shipping = AddressOverride;
      if (AddressOverride?.DeliveryInstructions) {
        DeliveryInstructions = AddressOverride.DeliveryInstructions;
      }
    }
    setOverrideFirstName(Shipping?.FirstName || '');
    setOverrideLastName(Shipping?.LastName || '');
    setOverrideAddress(Shipping?.Address || '');
    setOverrideAddress2(Shipping?.Address2 || '');
    setOverrideCity(Shipping?.City || '');
    setOverrideState(Shipping?.State || '');
    setOverrideZip(Shipping?.Zip || '');
    setOverridePhone(Shipping?.Phone || '');
    setOverrideEmail(Shipping?.Email || '');
    setOverrideUpsMessage(DeliveryInstructions || '');
    setOverrideAddressChecked(false);
    setCreateShipmentModalOpen(true);
  };

  const saveAdminMessageMutation = useAdminSaveOrderAdminMessageMutation({
    onSuccess: (data) => {
      log.debug({ data }, 'saveAdminMessageMutation success');
      setIsEditingAdminMessage(false);
      queryClient.invalidateQueries({ queryKey: ['adminGetOrderDetail', { Id }] });
    },
  });

  const voidShipmentMutation = useAdminVoidShipmentMutation({
    onSuccess: (data) => {
      log.debug({ data }, 'voidShipmentMutation success');
      queryClient.invalidateQueries({ queryKey: ['adminGetOrderDetail', { Id }] });
    },
  });

  const scheduleOrdersMutation = useAdminScheduleOrdersMutation({
    onSuccess: (data) => {
      log.info({ data }, 'scheduleOrdersMutation onSuccess');
      queryClient.invalidateQueries({ queryKey: ['adminGetOrderDetail', { Id }] });
      queryClient.invalidateQueries({ queryKey: ['getOrdersPage.infinite'] });
      closeScheduleShipmentModal();
    },
  });

  const createShipmentMutation = useAdminCreateShipmentForOrderMutation({
    onSuccess: (data) => {
      log.debug({ data }, 'createShipmentMutation success');
      closeCreateShipmentModal();
      queryClient.invalidateQueries({ queryKey: ['adminGetOrderDetail', { Id }] });
      queryClient.invalidateQueries({ queryKey: ['getOrdersPage.infinite'] });
    },
    onError: (error) => {
      log.debug({ error }, 'createShipmentMutation error');
      closeCreateShipmentModal();
    },
  });

  const saveOrderStatusMutation = useAdminSaveOrderStatusMutation({
    onSuccess: (data) => {
      log.debug({ data }, 'saveOrderStatusMutation success');
      closeSetStatusModal();
      queryClient.invalidateQueries({ queryKey: ['adminGetOrderDetail', { Id }] });
      queryClient.invalidateQueries({ queryKey: ['getOrdersPage.infinite'] });
    },
    onError: (error) => {
      log.error({ error }, 'saveOrderStatusMutation error');
      closeSetStatusModal();
    },
  });

  const saveOrderStatusSubmit = () => {
    saveOrderStatusMutation.mutate({ Orders: [{ Id, Status: newStatus }] });
  };

  const { Rendered: zebraPickerRendered, StatusRendered: zebraStatusRendered, PrintShipments } = AdminZebraPicker();

  const voidShipmentClick = (Id: string) => {
    voidShipmentMutation.mutate({ Id });
  };

  const createShipmentSubmit = () => {
    const createShipmentArgs: MutationCreateShipmentForOrderArgs = {
      OrderId: Id,
      Type: newShipmentTypeSelected,
    };
    if (overrideAddressChecked) {
      createShipmentArgs.AddressOverride = {
        FirstName: overrideFirstName,
        LastName: overrideLastName,
        Address: overrideAddress,
        Address2: overrideAddress2,
        City: overrideCity,
        State: overrideState,
        Zip: overrideZip,
        Phone: overridePhone,
        Email: overrideEmail,
        DeliveryInstructions: overrideUpsMessage,
      };
    }
    createShipmentMutation.mutate(createShipmentArgs);
  };

  const {
    Status,
    GiftCardRedemptions,
    InvoiceId,
    TimestampCreated,
    Shipping,
    Billing,
    ShippingService,
    WarrantyValid,
    Products,
    Subtotal,
    Total,
    Tax,
    GiftCards,
    GiftCardSubtotal,
    GiftCardRedemptionsTotal,
    TotalToCharge,
    WaiveWarrantyPlugtrays,
    WaiveWarranty,
    Storefront,
    SourceIp,
    PotCount,
    PlugtrayCount,
    EstimatedShippingDate,
    EstimatedShippingDatePlugtrays,
  } = orderContext.data?.GetOrder || {};

  const scheduleOrderSubmit = () => {
    log.debug({ scheduledDate, scheduledDatePlugtrays }, 'scheduleOrderSubmit');
    const payload = {};
    if (PotCount! > 0) {
      payload.EstimatedShippingDate =
        (new Date(scheduledDate).getTime() + new Date().getTimezoneOffset() * 60 * 1000) / 1000;
    }
    if (PlugtrayCount > 0) {
      payload.EstimatedShippingDatePlugtrays =
        (new Date(scheduledDatePlugtrays).getTime() + new Date().getTimezoneOffset() * 60 * 1000) / 1000;
    }
    log.debug({ payload }, 'scheduleOrderSubmit, scheduling order');
    scheduleOrdersMutation.mutate({ OrderIds: [Id], ...payload });
  };

  const canScheduleOrders =
    !scheduleOrdersMutation.isLoading &&
    ((PlugtrayCount! > 0 && scheduledDatePlugtrays) || PlugtrayCount === 0) &&
    ((PotCount! > 0 && scheduledDate) || PotCount === 0);

  const scheduleOrderButtonStyle = `secondary expanded ${scheduleOrdersMutation.isLoading && 'loading'} ${
    !canScheduleOrders && 'disabled'
  }}`;

  const canSetOrderStatus = !saveOrderStatusMutation.isLoading && newStatus !== Status;
  const setOrderStatusButtonStyle = `secondary expanded ${scheduleOrdersMutation.isLoading && 'loading'} ${
    !canSetOrderStatus && 'disabled'
  }}`;

  return (
    <Fragment>
      <LoadingError error={orderContext.error} />
      <Modal
        isOpen={setStatusModalOpen}
        contentLabel='Set Order Status'
        className='modal'
        onRequestClose={closeSetStatusModal}
      >
        <div className='grid-x'>
          <div className='cell small-12 modal--close'>
            <button className='button transparent' onClick={closeSetStatusModal}>
              <i className='fi-x' /> close
            </button>
          </div>
        </div>
        <h3>Set Order Status</h3>
        <p>
          Sometimes, 💩 happens. This allows you to cancel an order, or move an order to a different status. Please note
          that will <b>ONLY</b> change the status & nothing more. Please reach out to Drew &amp; Heather for
          clarification on what will happen.
        </p>
        <h3> Please only use this feature if you are 100% positive you know what you're doing!!!</h3>
        <label>
          Choose a new status for this order:
          <select
            id='order-status'
            value={newStatus}
            onChange={(e) => {
              setNewStatus(e.target.value as OrderStatus);
            }}
          >
            <option value={OrderStatus.Ready}>Ready</option>
            <option value={OrderStatus.Scheduled}>Scheduled</option>
            <option value={OrderStatus.Fulfillment}>In Fulfillment</option>
            <option value={OrderStatus.PartiallyShipped}>Partially Shipped</option>
            <option value={OrderStatus.Shipped}>Shipped</option>
            <option value={OrderStatus.Cancelled}>Cancelled</option>
          </select>
        </label>

        <p>Would you like to schedule these orders?</p>
        <Button
          testId='set-order-status-button'
          isDisabled={!canSetOrderStatus}
          style={setOrderStatusButtonStyle}
          onClickHandler={saveOrderStatusSubmit}
          text='Set order status'
        />
      </Modal>
      <Modal
        isOpen={scheduleShipmentModalOpen}
        contentLabel='Schedule Order'
        className='modal'
        onRequestClose={closeScheduleShipmentModal}
      >
        <div className='grid-x'>
          <div className='cell small-12 modal--close'>
            <button className='button transparent' onClick={closeScheduleShipmentModal}>
              <i className='fi-x' /> close
            </button>
          </div>
        </div>
        <h3>Schedule Order</h3>
        {PotCount! > 0 && (
          <label>
            Choose an estimated shipping date for Pots:
            <input type='date' onChange={(e) => setScheduledDate(e.target.value)} value={scheduledDate} />
          </label>
        )}
        {PlugtrayCount! > 0 && (
          <label>
            Choose an estimated shipping date for Plugtrays:
            <input
              type='date'
              onChange={(e) => setScheduledDatePlugtrays(e.target.value)}
              value={scheduledDatePlugtrays}
            />
          </label>
        )}
        <p>
          Confirming this date will send an automatic email to selected customers with this date as their expected ship
          date.
        </p>

        <p>Would you like to schedule these orders?</p>
        <Button
          testId='accept-date-button'
          isDisabled={!canScheduleOrders}
          style={scheduleOrderButtonStyle}
          onClickHandler={scheduleOrderSubmit}
          text='Confirm scheduled ship date'
        />
      </Modal>
      <Modal
        isOpen={createShipmentModalOpen}
        contentLabel='Create Shipment'
        className='modal'
        onRequestClose={closeCreateShipmentModal}
      >
        <div className='grid-x'>
          <div className='cell small-12 modal--close'>
            <button className='button transparent' onClick={closeCreateShipmentModal}>
              <i className='fi-x' /> close
            </button>
          </div>
        </div>
        <h3>Create Shipment</h3>
        <label htmlFor='shipment-type'>Choose an shipment type</label>
        <select
          id='shipment-type'
          value={newShipmentTypeSelected}
          onChange={(e) => setNewShipmentTypeSelected(e.target.value as ShipmentRequestType)}
        >
          {PotCount > 0 && <option value={ShipmentRequestType.Pots}>Pot</option>}
          {PlugtrayCount > 0 && <option value={ShipmentRequestType.Plugtrays}>Plugtray</option>}
          <option value={ShipmentRequestType.All}>Entire Shipment</option>
          <option value={ShipmentRequestType.None}>None (Address Override)</option>
        </select>
        <label>
          Override address
          <input
            type='checkbox'
            id='override-address'
            checked={overrideAddressChecked}
            onChange={(e) => setOverrideAddressChecked(e.target.checked)}
          />
        </label>
        {overrideAddressChecked && (
          <div>
            <label>
              First Name
              <input type='text' value={overrideFirstName} onChange={(e) => setOverrideFirstName(e.target.value)} />
            </label>
            <label>
              Last Name
              <input type='text' value={overrideLastName} onChange={(e) => setOverrideLastName(e.target.value)} />
            </label>
            <label>
              Address
              <input type='text' value={overrideAddress} onChange={(e) => setOverrideAddress(e.target.value)} />
            </label>
            <label>
              Address 2
              <input type='text' value={overrideAddress2} onChange={(e) => setOverrideAddress2(e.target.value)} />
            </label>
            <label>
              City
              <input type='text' value={overrideCity} onChange={(e) => setOverrideCity(e.target.value)} />
            </label>
            <label>
              State
              <input type='text' value={overrideState} onChange={(e) => setOverrideState(e.target.value)} />
            </label>
            <label>
              Zip
              <input type='text' value={overrideZip} onChange={(e) => setOverrideZip(e.target.value)} />
            </label>
            <label>
              Phone
              <input type='text' value={overridePhone} onChange={(e) => setOverridePhone(e.target.value)} />
            </label>
            <label>
              Email
              <input type='text' value={overrideEmail} onChange={(e) => setOverrideEmail(e.target.value)} />
            </label>
            <label>
              Ups Message
              <input type='text' value={overrideUpsMessage} onChange={(e) => setOverrideUpsMessage(e.target.value)} />
            </label>
          </div>
        )}
        <Button
          testId='create-shipment-button'
          isDisabled={
            createShipmentMutation.isLoading ||
            (newShipmentTypeSelected === ShipmentRequestType.None && !overrideAddressChecked)
          }
          style={createShipmentMutation.isLoading ? 'secondary expanded disabled loading' : 'secondary expanded'}
          onClickHandler={createShipmentSubmit}
          text='Create Shipment'
        />
      </Modal>
      {orderContext.isLoading ? (
        <Loading />
      ) : (
        <div className='grid-x'>
          <div className='cell small-12'>
            <h3>
              {`Order #${InvoiceId}`} - {Status}{' '}
            </h3>
            <h4>Order actions</h4>
            <table className='table--vertical-headers'>
              <tbody>
                <tr>
                  <th>Order documents</th>
                  <th>Additional information</th>
                  <th>Available actions</th>
                </tr>

                <tr>
                  <th>Estimated Shipping Date</th>
                  <td>
                    {PotCount > 0 && `Pots: ${EstimatedShippingDate ? formatDate(EstimatedShippingDate) : 'Not Set'}`}
                    <br />
                    {PlugtrayCount > 0 &&
                      `Plug trays: ${
                        EstimatedShippingDatePlugtrays ? formatDate(EstimatedShippingDatePlugtrays) : 'Not Set'
                      }`}
                  </td>
                  <td>
                    <a className='button secondary no-span' onClick={openScheduleShipmentModal}>
                      Schedule Order
                    </a>
                  </td>
                </tr>
                <tr>
                  <th>Forcefully Set Order Status</th>
                  <td></td>
                  <td>
                    <a className='button secondary no-span' onClick={openSetStatusModal}>
                      Set order status
                    </a>
                  </td>
                </tr>
                <tr>
                  <th>Pick slips - **Moves Scheduled order to In-Fulfillment</th>
                  <td>
                    {/* Any display information goes here */}
                    {/* {Status } */}
                  </td>
                  <td>
                    <div>
                      <a
                        className='button secondary no-span'
                        onClick={() => {
                          createAllPackingSlips([orderContext.data?.GetOrder!], 'Pick Slip');
                          if (Status === OrderStatus.Scheduled) {
                            saveOrderStatusMutation.mutate({ Orders: [{ Id, Status: OrderStatus.Fulfillment }] });
                          }
                        }}
                      >
                        **All products
                      </a>
                      {PotCount > 0 &&
                        PlugtrayCount > 0 && [
                          <a
                            className='button secondary no-span'
                            onClick={() => {
                              createAllPackingSlips([orderContext.data?.GetOrder!], 'Pick Slip', PrintProductType.Pots);
                              if (Status === OrderStatus.Scheduled) {
                                saveOrderStatusMutation.mutate({ Orders: [{ Id, Status: OrderStatus.Fulfillment }] });
                              }
                            }}
                          >
                            **Pots only
                          </a>,
                          <a
                            className='button secondary no-span'
                            onClick={() => {
                              createAllPackingSlips(
                                [orderContext.data?.GetOrder!],
                                'Pick Slip',
                                PrintProductType.Plugtrays
                              );
                              if (Status === OrderStatus.Scheduled) {
                                saveOrderStatusMutation.mutate({ Orders: [{ Id, Status: OrderStatus.Fulfillment }] });
                              }
                            }}
                          >
                            **Plug trays only
                          </a>,
                        ]}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Packing slips</th>
                  <td>{/* Any display information goes here */}</td>
                  <td>
                    <div>
                      <a
                        className='button secondary no-span'
                        onClick={() => createAllPackingSlips([orderContext.data?.GetOrder!])}
                      >
                        All products
                      </a>
                      {PotCount > 0 &&
                        PlugtrayCount > 0 && [
                          <a
                            className='button secondary no-span'
                            onClick={() =>
                              createAllPackingSlips(
                                [orderContext.data?.GetOrder!],
                                'Packing Slip',
                                PrintProductType.Pots
                              )
                            }
                          >
                            Pots only
                          </a>,
                          <a
                            className='button secondary no-span'
                            onClick={() =>
                              createAllPackingSlips(
                                [orderContext.data?.GetOrder!],
                                'Packing Slip',
                                PrintProductType.Plugtrays
                              )
                            }
                          >
                            Plug trays only
                          </a>,
                        ]}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            {orderContext.data?.GetShipmentsForOrder && (
              <>
                {zebraPickerRendered}
                <h4>Shipments associated with this order</h4>
                <div className='grid-x'>
                  <div className='cell small-6'>
                    <div style={{ padding: '0.5rem 0' }}>
                      <a className='button primary no-span' onClick={openCreateShipmentModal}>
                        Create Shipment
                      </a>
                      <a
                        className='button transparent no-span'
                        onClick={() => queryClient.invalidateQueries({ queryKey: ['adminGetOrderDetail', { Id }] })}
                      >
                        Refresh
                      </a>
                    </div>
                  </div>
                  <div className='cell small-6' style={{ textAlign: 'right' }}>
                    {' '}
                    {zebraStatusRendered}
                  </div>
                </div>
                <SavingError error={createShipmentMutation.error} />
                <SavingError error={voidShipmentMutation.error} />
                <table title='UPS shipments'>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Status</th>
                      <th>Type</th>
                      <th>Created</th>
                      <th>Cost</th>
                      <td>Actions</td>
                      <th>Tracking Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(
                      _.sortBy(orderContext.data.GetShipmentsForOrder, 'TimestampCreated'),
                      _.partial(renderShipment, { voidShipmentClick, PrintShipments })
                    )}
                  </tbody>
                </table>
              </>
            )}
            <p>
              <SavingError error={scheduleOrdersMutation.error} />
            </p>
          </div>
          <div className='cell small-12'>
            <h4>Order information</h4>
            <table>
              <thead>
                <tr>
                  <th>Invoice ID</th>
                  <th>Status</th>
                  <th>Warranty</th>
                  <th>Date placed</th>
                  <th>Storefront</th>
                  <th>GUID</th>
                  <th>IP address</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {/* Invoice ID */}
                    {InvoiceId}
                  </td>
                  <td>
                    {/* Order status */}
                    {Status}
                  </td>
                  <td>
                    <p className={WarrantyValid ? '' : 'text__warning'}>
                      {WarrantyValid ? 'Under warranty at time of purchase' : 'Not under warranty at time of purchase'}
                    </p>
                    {!WarrantyValid ? (
                      <>
                        {PlugtrayCount > 0 && <p>Plug trays warranty waived: {formatBool(WaiveWarrantyPlugtrays)}</p>}
                        {PotCount > 0 && <p>Pots warranty waived: {formatBool(WaiveWarranty)}</p>}
                      </>
                    ) : null}
                  </td>
                  <td>
                    {/* Date placed */}
                    {formatDateTime(TimestampCreated!)}
                  </td>
                  <td>
                    {/* Storefront */}
                    {Storefront}
                  </td>
                  <td>
                    {/* GUID */}
                    {Id}
                  </td>
                  <td>{SourceIp}</td>
                </tr>
              </tbody>
            </table>
            <div>
              <SavingError error={saveAdminMessageMutation.error} />
              <label>
                Admin Message (max 15,000 characters)
                <textarea
                  maxLength={15000}
                  value={adminMessage}
                  onChange={(e) => setAdminMessage(e.target.value)}
                  readOnly={!isEditingAdminMessage}
                  onDoubleClick={() => setIsEditingAdminMessage(true)}
                  rows={6}
                />
              </label>
              {isEditingAdminMessage && (
                <button
                  disabled={saveAdminMessageMutation.isLoading}
                  onClick={() =>
                    saveAdminMessageMutation.mutate({
                      Id,
                      AdminMessage: adminMessage,
                    })
                  }
                >
                  Save Admin Message
                </button>
              )}
              {!isEditingAdminMessage && (
                <button onClick={() => setIsEditingAdminMessage(true)}>Edit Admin Message</button>
              )}
            </div>
          </div>
          <div className='cell small-12 medium-6'>
            <h4>Ship to:</h4>
            <div style={shipToOverridden ? overriddenAddressStyle : undefined}>
              <p>
                {Shipping?.FirstName} {Shipping?.LastName}
              </p>
              <p>{Shipping?.Address}</p>
              <p>{Shipping?.Address2 && Shipping.Address2}</p>
              <p>
                {Shipping?.City}, {Shipping?.State} {Shipping?.Zip}
              </p>
              <p>
                <strong>Phone: </strong>
                {Shipping?.Phone}
              </p>
              <p>
                <strong>Email: </strong>
                {linkToSendOrderEmail(orderContext.data?.GetOrder, Shipping?.Email)}
              </p>
            </div>
            {shipToOverridden && (
              <div>
                <p>
                  <strong>Overridden To:</strong>
                </p>
                <p>
                  {AddressOverride?.FirstName} {AddressOverride?.LastName}
                </p>
                <p>{AddressOverride?.Address}</p>
                <p>{AddressOverride?.Address2 && AddressOverride.Address2}</p>
                <p>
                  {AddressOverride?.City}, {AddressOverride?.State} {AddressOverride?.Zip}
                </p>
                <p>
                  <strong>Phone: </strong>
                  {AddressOverride?.Phone}
                </p>
              </div>
            )}
          </div>
          <div className='cell small-12 medium-6'>
            <h4>Bill to:</h4>
            <p>
              {Billing?.FirstName} {Billing?.LastName}
            </p>
            <p>{Billing?.Address}</p>
            <p>{Billing?.Address2 && Billing.Address2}</p>
            <p>
              {Billing?.City}, {Billing?.State} {Billing?.Zip}
            </p>
            <p>
              <strong>Phone: </strong>
              {Billing?.Phone}
            </p>
            <p>
              <strong>Email: </strong>
              {linkToSendOrderEmail(orderContext.data?.GetOrder, Billing?.Email)}
            </p>
            {GiftCardRedemptions && (
              <>
                <p>
                  <strong>Gift Cards Redeemed</strong>
                </p>
                <table title='Gift Cards Redeemed'>
                  <thead>
                    <tr>
                      <th>Card Id</th>
                      <th>Balance (when order was placed)</th>
                    </tr>
                  </thead>
                  <tbody>{_.map(GiftCardRedemptions, renderRedeemedGiftCard)}</tbody>
                </table>
              </>
            )}
          </div>
          <div className='cell small-12'>
            <h3>Order summary</h3>
            <table>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Product ID</th>
                  <th>Product name</th>
                  <th>Quantity</th>
                  <th>Backorder Quantity</th>
                  <th>Pot Count</th>
                  <th>Plugtray Count</th>
                  <th>Unit Price</th>
                  <th>Line Subtotal</th>
                </tr>
              </thead>
              <tbody>
                {_.map(Products, buildProductTableRow)}
                {_.map(GiftCards, renderGiftCard)}
                {GiftCardSubtotal! > 0 && (
                  <tr className='order--summary'>
                    <td colSpan={PRODUCT_COLUMN_FOOTER_OFFSET}>
                      <strong>Gift Cards: </strong>
                      {formatCurrency(GiftCardSubtotal)}
                    </td>
                  </tr>
                )}
                <tr className='order--summary'>
                  <td colSpan={PRODUCT_COLUMN_FOOTER_OFFSET}>
                    <strong>Subtotal: </strong>
                    {formatCurrency(Subtotal! + (GiftCardSubtotal || 0))}
                  </td>
                </tr>
                <tr className='order--summary'>
                  <td colSpan={PRODUCT_COLUMN_FOOTER_OFFSET}>Tax: {formatCurrency(Tax)}</td>
                </tr>
                <tr className='order--summary'>
                  <td colSpan={PRODUCT_COLUMN_FOOTER_OFFSET}>
                    Shipping ({formatShippingService(_.get(ShippingService, 'shippingService.ServiceCode'))}
                    ): {formatCurrency(_.get(ShippingService, 'shippingService.Price'))}
                  </td>
                </tr>
                <tr className='order--summary'>
                  <td colSpan={PRODUCT_COLUMN_FOOTER_OFFSET}>
                    <strong>Total: {formatCurrency(Total)}</strong>
                  </td>
                </tr>
                {GiftCardRedemptionsTotal! > 0 && (
                  <>
                    <tr className='order--summary'>
                      <td colSpan={PRODUCT_COLUMN_FOOTER_OFFSET}>
                        <strong>Gift Card Redemptions: {formatCurrency(GiftCardRedemptionsTotal)}</strong>
                      </td>
                    </tr>
                    <tr className='order--summary'>
                      <td colSpan={PRODUCT_COLUMN_FOOTER_OFFSET}>
                        <strong>Total Charged: {formatCurrency(TotalToCharge)}</strong>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
            <p>Total Plugtrays: {PlugtrayCount}</p>
            <p>Total Pots: {PotCount}</p>
          </div>
        </div>
      )}
    </Fragment>
  );
};

AdminOrder.propTypes = {
  Id: PropTypes.string,
};

AdminOrder.defaultProps = {};

export default AdminOrder;
