import { PDFDocument, PDFPage, StandardFonts, layoutMultilineText, rgb } from 'pdf-lib';
import download from 'downloadjs';
import { Order, PlantType, ProductType } from '../../generated/types-and-hooks';
import _ from 'lodash';
import { ProductsAPIQueryType, fetchProductsV1 } from '../../utils/public-api';

const formatDate = (timestamp: number): string => new Date(timestamp * 1000).toLocaleDateString();
const formatDateTime = (timestamp: number): string => new Date(timestamp * 1000).toLocaleString();

const formatShippingService = (ShippingServiceCode: string): string => {
  switch (ShippingServiceCode) {
    case '02':
      return 'UPS 2nd Day Air';
    case '03':
      return 'UPS Ground';
  }
  throw new Error(`Shipping Service Code ${ShippingServiceCode} not found`);
};

export enum PrintProductType {
  All,
  Plugtrays,
  Pots,
}

const transformProductNames = (product) => {
  let productNameLines = [];
  const lineLength = 40;
  const lineNums = Math.ceil(product.Name.length / lineLength);
  if (lineNums > 1) {
    const words = product.Name.split(' ');
    let counter = 0;
    let currentLine = '';
    words.forEach((word, i) => {
      currentLine += `${word} `;
      counter += word.length;

      if (counter >= lineLength || i + 1 == words.length) {
        productNameLines.push(currentLine);
        counter = 0;
        currentLine = '';
      }
    });
  } else {
    productNameLines.push(product.Name);
  }

  return productNameLines;
};

export const createAllPackingSlips = async (
  Orders: Order[],
  Title: string = 'Packing Slip',
  productTypes: PrintProductType = PrintProductType.All
) => {
  const pdfDoc = await PDFDocument.create();

  for (const Order of Orders) {
    for (const product of Order.Products!) {
      if (!product) continue;

      product.Lines = 0;
      product.NameParts = transformProductNames(product);
      product.Lines += product.NameParts.length;

      if (product.PlantType === PlantType.Collection) {
        const CollectionProductCount = product.PotCount / product.QuantityValidated;
        if (CollectionProductCount !== 6) {
          // Only print out 6-packs
          continue;
        }
        const result = await fetchProductsV1({ type: ProductsAPIQueryType.Tag, tag: `included_in:${product?.Id}` });
        if (result.Products && result.Products.length <= 6) {
          product.CollectionProducts = _.sortBy(result.Products, ['BotanicalName', 'CommonName']);
          for (const item of product.CollectionProducts) {
            item.NameParts = transformProductNames(item);
            product.Lines += item.NameParts.length;
          }
        }
      }
    }

    const pdfBytes = await createPackingSlip({ ...Order, Products: Order.Products }, Title, productTypes);
    const pdf = await PDFDocument.load(pdfBytes);

    const copiedPages = await pdfDoc.copyPages(pdf, pdf.getPageIndices());
    copiedPages.forEach((page) => pdfDoc.addPage(page));
  }
  const pdfBytes = await pdfDoc.save();
  const docTitle = Title.toLowerCase().replace(' ', '-');
  download(pdfBytes, `${docTitle}s.pdf`, 'application/pdf');
};

const drawHeader = (
  page: PDFPage,
  Order: Order,
  font,
  fontBold,
  pageNumber: number,
  totalPages: number,
  Title: string
) => {
  // Get the width and height of the page
  const { width, height } = page.getSize();

  // Draw Address
  let size = 16;
  let lineHeight = size + size * 0.25;
  let x = 25;
  let y = height - 3 * size;
  page.drawText('Mountain Valley Growers', {
    x,
    y,
    size,
    font: fontBold,
  });
  y -= lineHeight;
  size = 12;
  page.drawText('38325 Pepperweed Road', {
    x,
    y,
    size,
    font,
  });
  y -= lineHeight;
  page.drawText('Squaw Valley, CA 93675', {
    x,
    y,
    size,
    font,
  });

  // Draw Right side headers
  size = 32;
  x = width / 2;
  y = height - 3 * 18;
  page.drawText(Title.toUpperCase(), {
    x,
    y,
    size,
    font: fontBold,
  });
  y -= lineHeight;
  size = 12;
  lineHeight = size + size * 0.25;
  page.drawText(`Page: ${pageNumber} / ${totalPages}`, {
    x,
    y,
    size,
    font,
  });
  if (Order.EstimatedShippingDate) {
    y -= lineHeight;
    page.drawText(`Estimated Shipping Date: ${formatDate(Order.EstimatedShippingDate)}`, {
      x,
      y,
      size,
      font,
    });
  }
  if (Order.PotCount! > 0) {
    y -= lineHeight;
    page.drawText(`Total 3" Pots: ${Order.PotCount}`, {
      x,
      y,
      size,
      font,
    });
  }
  if (Order.PlugtrayCount! > 0) {
    y -= lineHeight;
    page.drawText(`Total Plugtrays: ${Order.PlugtrayCount}`, {
      x,
      y,
      size,
      font,
    });
  }
  y -= lineHeight;
  page.drawText(`Invoice Number: ${Order.InvoiceId}`, {
    x,
    y,
    size,
    font,
  });

  // Draw Bill To
  y = 650;
  x = 25;
  page.drawText(`Bill To:`, {
    x,
    y,
    size,
    font: fontBold,
  });
  y -= lineHeight;
  page.drawText(`${Order.Billing.FirstName} ${Order.Billing.LastName}`, {
    x,
    y,
    size,
    font,
  });
  y -= lineHeight;
  if (Order.Billing.Company) {
    page.drawText(Order.Billing.Company, {
      x,
      y,
      size,
      font,
    });
    y -= lineHeight;
  }
  page.drawText(Order.Billing.Address, {
    x,
    y,
    size,
    font,
  });
  y -= lineHeight;
  if (Order.Billing.Address2) {
    page.drawText(Order.Billing.Address, {
      x,
      y,
      size,
      font,
    });
    y -= lineHeight;
  }
  page.drawText(`${Order.Billing.City}, ${Order.Billing.State} ${Order.Billing.Zip}`, {
    x,
    y,
    size,
    font,
  });

  // Draw Ship To
  y = 650;
  x = width / 2;
  page.drawText(`Ship To:`, {
    x,
    y,
    size,
    font: fontBold,
  });
  y -= lineHeight;
  page.drawText(`${Order.Shipping.FirstName} ${Order.Shipping.LastName}`, {
    x,
    y,
    size,
    font,
  });
  y -= lineHeight;
  if (Order.Shipping.Company) {
    page.drawText(Order.Shipping.Company, {
      x,
      y,
      size,
      font,
    });
    y -= lineHeight;
  }
  page.drawText(Order.Shipping.Address, {
    x,
    y,
    size,
    font,
  });
  y -= lineHeight;
  if (Order.Shipping.Address2) {
    page.drawText(Order.Shipping.Address, {
      x,
      y,
      size,
      font,
    });
    y -= lineHeight;
  }
  page.drawText(`${Order.Shipping.City}, ${Order.Shipping.State} ${Order.Shipping.Zip}`, {
    x,
    y,
    size,
    font,
  });
};

const drawProducts = (page: PDFPage, products, font, fontBold, productTypes: PrintProductType) => {
  // Get the width and height of the page
  const typeOffset = 50;
  const idOffset = 100;
  const descriptionOffset = 50;

  const { width, height } = page.getSize();

  // Draw product header
  let size = 16;
  let x = 25;
  let y = 550;
  let lineHeight = size + size * 0.25;

  page.drawText('Qty', {
    x,
    y,
    size,
    font: fontBold,
  });
  x += typeOffset;
  page.drawText('Type', {
    x,
    y,
    size,
    font: fontBold,
  });
  x += idOffset;
  page.drawText('Id', {
    x,
    y,
    size,
    font: fontBold,
  });
  x += descriptionOffset;
  page.drawText('Name', {
    x,
    y,
    size,
    font: fontBold,
  });
  x = 25;
  if (productTypes !== PrintProductType.All) {
    y -= size + size * 0.25;
    size = 10;
    page.drawText('* indicates an item that will ship separately', {
      x,
      y,
      size,
      font: fontBold,
    });
  }
  size = 12;
  lineHeight = size + size * 0.25;
  y -= size;
  for (const product of products) {
    x = 25;
    y -= size;

    // If the slip is for only one of a product type, only print that product type's quantity.
    // Otherwise, indicate the product is in another castle.
    if (
      productTypes === PrintProductType.All ||
      (productTypes === PrintProductType.Pots && [PlantType.Collection, PlantType.Pot].includes(product.PlantType)) ||
      (productTypes === PrintProductType.Plugtrays && product.PlantType === PlantType.Plugtray)
    ) {
      page.drawText(`${product.QuantityValidated}`, {
        x,
        y,
        size,
        font,
      });
    } else {
      page.drawText('*', {
        x,
        y,
        size,
        font,
      });
    }
    x += typeOffset;
    page.drawText(`${product.PlantType}`, {
      x,
      y,
      size,
      font,
    });
    x += idOffset;
    page.drawText(`${product.Id}`, {
      x,
      y,
      size,
      font,
    });
    x += descriptionOffset;

    product.NameParts.forEach((line) => {
      page.drawText(`${line} `, {
        x,
        y,
        size,
        font,
        maxWidth: width / 2,
        lineHeight: 15,
      });
      y -= lineHeight;
    });
    if (product.CollectionProducts) {
      product.CollectionProducts.forEach((product) => {
        product.NameParts.forEach((line) => {
          page.drawText(`${line}`, {
            x,
            y,
            size: size - 2,
            font,
            maxWidth: width / 2,
            lineHeight: 15,
          });
          y -= lineHeight;
        });
      });
    }
    x = 10;
  }
};

const drawFooter = (page: PDFPage, font, fontBold, Order: Order) => {
  let size = 10;
  let lineHeight = size + size * 0.25;
  let x = 25;
  let y = 125;
  page.drawText('Certified Organic by SCS Global. CDFA Registration #10-000778.', {
    x,
    y,
    size,
    font,
  });
  y -= lineHeight;
  page.drawText('USDA - APHIS - PPQ, 4700 River Road, Riverdale, MD.', {
    x,
    y,
    size,
    font: fontBold,
  });
  y -= lineHeight;
  page.drawText('Certified under 7CFR301.92', {
    x,
    y,
    size,
    font: fontBold,
  });
  y -= lineHeight;
  page.drawText('Federal Cooperative', {
    x,
    y,
    size,
    font: fontBold,
  });
  y -= lineHeight;
  page.drawText('Domestic Plant Quarantine', {
    x,
    y,
    size,
    font: fontBold,
  });
  y -= lineHeight;
  page.drawText('CA#: 10B4503001', {
    x,
    y,
    size,
    font: fontBold,
  });
  y -= lineHeight;

  size = 6;
  lineHeight = size + size * 0.25;
  x = 400;
  y = 65;
  page.drawText(`Order Id: ${Order.Id}`, {
    x,
    y,
    size,
    font,
  });
  y -= lineHeight;
};

const PRODUCT_PAGE_SIZE = 14;

const chunkProducts = (products) => {
  const pages = [];
  let page = [];
  let lineCount = 0;

  products.forEach((product, i) => {
    page.push(product);
    lineCount += product.Lines;
    if (lineCount >= PRODUCT_PAGE_SIZE || i + 1 == products.length) {
      pages.push(page);
      page = [];
      lineCount = 0;
    }
  });
  return pages;
};

export const createPackingSlip = async (Order: Order, Title: string, productTypes: PrintProductType) => {
  const pdfDoc = await PDFDocument.create();

  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const fontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

  const sortedProducts = _.orderBy(Order.Products, ['PlantType', 'Id'], ['asc']);

  const productPages = chunkProducts(sortedProducts);
  const totalPages = productPages.length;

  for (const [index, products] of productPages.entries()) {
    const page = pdfDoc.addPage();
    drawHeader(page, Order, font, fontBold, index + 1, totalPages, Title);
    drawProducts(page, products, font, fontBold, productTypes);
    drawFooter(page, font, fontBold, Order);
  }

  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};
