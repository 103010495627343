import React from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { UserContext } from '../UserContext';
import log from 'loglevel';
import _ from 'lodash';

const AdminRoute = ({ component: Component, ...rest }) => (
  <UserContext.Consumer>
    {({ userContext }) => {
      log.debug({ userContext }, 'AdminRoute got user');
      const isAdmin = _.get(userContext, ['data', 'isAdmin']);
      if (isAdmin) {
        return <Component {...rest} />;
      }
      if (userContext.isFetched && !isAdmin) {
        // Only do a redirect once the data has fully loaded
        //  so we know for a fact that they're not logged in
        navigate('/');
      }
      return (
        <div>
          <h2>No permission</h2>
          <p>You need to be logged in with the correct permissions.</p>
        </div>
      );
    }}
  </UserContext.Consumer>
);

AdminRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
};

export default AdminRoute;
