import _ from 'lodash';
import React from 'react';
import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import CheckboxInput from '../CheckboxInput';
import TextInput, { validateLength } from '../TextInput';
import Button from '../Button';
import { redeemGiftCard, removeGiftCardToRedeem } from '../../graphql/cart';
import SavingError from '../SavingError';
import log from 'loglevel';
import { formatCurrency } from '../../utils/cart';
import { doGraphqlQuery } from '../../fetcher';

// saveGiftCardRedemptions

const postGiftCards = async (GiftCard) => {
  log.debug({ GiftCard }, 'redeeming gift card');
  const result = await doGraphqlQuery(redeemGiftCard, GiftCard);
  log.debug({ result }, 'redeemed gift card');
  return result.data;
};

const removeGiftCard = async (Index) => {
  log.debug({ Index }, 'removing gift card');
  const result = await doGraphqlQuery(removeGiftCardToRedeem, { Index });
  log.debug({ Index, result }, 'removed gift card');
  return result.data;
};

const CartCheckoutGiftCardRedeem = (checkoutContext) => {
  const queryClient = useQueryClient();
  const [validationVisible, setValidationVisible] = useState(false);

  const [HaveGiftCard, HaveGiftCardRendered, SetHaveGiftCard] = CheckboxInput({
    validationVisible,
    labelText: 'I have a gift card I\'d like to redeem',
    testId: 'gift-card-redeem',
  });

  const submitMutation = useMutation(postGiftCards, {
    onSuccess: (data) => {
      log.debug({ data }, 'redeemGiftCard onSuccess');
      queryClient.setQueryData(['checkout'], data.CheckoutV1);
      SetHaveGiftCard(false);
      SetGiftCardNum('');
      SetPin('');
    },
  });
  const removeMutation = useMutation(removeGiftCard, {
    onSuccess: (data) => {
      log.debug({ data }, 'removeMutation onSuccess');
      queryClient.setQueryData(['checkout'], data.CheckoutV1);
    },
  });

  const [GiftCardNum, GiftCardNumRendered, SetGiftCardNum] = TextInput({
    validationVisible,
    labelText: 'Gift Card Number',
    required: false,
    validator: _.partial(validateLength, 16, 16),
  });
  const [Pin, PinRendered, SetPin] = TextInput({
    validationVisible,
    labelText: 'Pin',
    required: false,
    validator: _.partial(validateLength, 4, 6),
  });

  const validateForm = () => {
    const fieldValues = [GiftCardNum, Pin];
    let passedValidation = true;
    _.map(fieldValues, (value) => {
      if (_.isUndefined(value)) {
        passedValidation = false;
      }
    });
    if (!passedValidation) {
      setValidationVisible(true);
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    // validate gift card number & refresh cart
    if (validateForm()) {
      submitMutation.mutate({ Id: GiftCardNum, Pin });
    }
  };

  const giftCardInputRendered = HaveGiftCard ? (
    <>
      <div className='cell small-8'>{GiftCardNumRendered}</div>
      <div className='cell small-4'>{PinRendered}</div>
      <div className='cell small-4'>
        <Button
          isDisabled={checkoutContext.isLoading || submitMutation.isLoading}
          style={
            checkoutContext.isLoading || submitMutation.isLoading ? 'primary expanded loading' : 'primary expanded'
          }
          text='Redeem this gift card'
          testId='submit-gift-card-redeem'
          onClickHandler={onSubmit}
        />
      </div>
      <SavingError />
    </>
  ) : null;

  const renderGiftCard = ({ Id, Balance }, Index) => (
    <div key={Index}>
      GiftCard #{Id} - {formatCurrency(Balance)}{' '}
      <a onClick={_.partial(removeMutation.mutate, Index)}>
        <i className='fi-x-circle' />
      </a>
    </div>
  );
  const giftCards = _.get(checkoutContext, ['data', 'Cart', 'GiftCardRedemptions']);
  const renderedGiftCards = _.map(giftCards, renderGiftCard);

  return (
    <>
      <fieldset className='grid-x grid-padding-x fieldset--center-legend'>
        <legend className='header__small-caps'>Redeem gift card</legend>
        <div className='grid-x grid-margin-x'>
          <div className='cell small-12'>{HaveGiftCardRendered}</div>
          {giftCardInputRendered}
          {renderedGiftCards}
          <SavingError error={removeMutation.error} />
          <SavingError error={submitMutation.error} />
        </div>
      </fieldset>
    </>
  );
};

export default CartCheckoutGiftCardRedeem;
