import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { graphqlOperation, API } from 'aws-amplify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import LoadingError from '../LoadingError';
import { useProductsTagging } from '../hooks';
import { useTable, useSortBy } from 'react-table';
import log from 'loglevel';
import { saveProductBulkTags } from '../../graphql/admin';
import { navigate } from 'gatsby';

// Create an editable cell renderer
const CheckableCell = ({ value, row, removeProductTag, addProductTag }) => {
  // We need to keep and update the state of the cell normally
  // const [value, setValue] = React.useState(initialValue)

  const onToggle = () => {
    if (!value) {
      addProductTag(row.values.Id);
    } else {
      removeProductTag(row.values.Id);
    }
  };

  return <input type='checkbox' checked={value} onChange={onToggle} />;
};
CheckableCell.propTypes = {
  value: PropTypes.bool,
  row: PropTypes.object,
  removeProductTag: PropTypes.func,
  addProductTag: PropTypes.func,
};

function Table({ columns, data, removeProductTag, addProductTag }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      removeProductTag,
      addProductTag,
    },
    useSortBy
  );

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, headerIndex) => (
            <tr key={headerIndex} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th key={index} {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} {...row.getRowProps()}>
                {row.cells.map((cell, j) => {
                  return (
                    <td key={j} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
Table.propTypes = {
  columns: PropTypes.object,
  data: PropTypes.object,
  removeProductTag: PropTypes.func,
  addProductTag: PropTypes.func,
};

const AdminTagsCollections = ({ Tag: defaultTag }) => {
  const queryClient = useQueryClient();
  const [activeTag, setActiveTag] = useState(defaultTag);
  const [productIdsWithTag, setProductIdsWithTag] = useState(new Set());
  const products = useProductsTagging();
  const [savingMessage, setSavingMessage] = useState(undefined);
  const [newTagValue, setNewTagValue] = useState('');
  const uniqueTags = useMemo(
    () => Array.from(new Set(_.flatten(_.map(products.data, ({ Tags }) => Tags))).values()).sort(),
    [products]
  );

  useEffect(() => {
    navigate(`/app/admin/tags-collections/${activeTag}`, { replace: true });
  }, [activeTag]);

  const saveTagsMutation = useMutation(
    async ({ Tag, TaggedProductIds, UntaggedProductIds }) => {
      const result = await API.graphql(
        graphqlOperation(saveProductBulkTags, { Tag, TaggedProductIds, UntaggedProductIds })
      );
      return result;
    },
    {
      onSuccess: (data, { Tag, TaggedProductIds, UntaggedProductIds }) => {
        queryClient.invalidateQueries('productsTagging');
        setSavingMessage(
          `Successfully saved tag: ${Tag}, with ${_.size(TaggedProductIds)} products tagged, ${_.size(
            UntaggedProductIds
          )} products not tagged.`
        );
        setTimeout(() => setSavingMessage(undefined), 30000);
      },
    }
  );

  useEffect(() => {
    const productsTagged = _.filter(products.data, ({ Tags }) => _.includes(Tags, activeTag));
    const productIds = _.flatten(_.map(productsTagged, ({ Id }) => Id));
    setProductIdsWithTag(new Set(productIds));
    log.debug({ productIds }, 'got tagged items');
  }, [products.data, activeTag]);
  const removeProductTag = (Id) => {
    const newSet = new Set(productIdsWithTag);
    newSet.delete(Id);
    setProductIdsWithTag(newSet);
  };
  const addProductTag = (Id) => {
    const newSet = new Set(productIdsWithTag);
    newSet.add(Id);
    setProductIdsWithTag(newSet);
  };

  const productsWithTag = useMemo(
    () =>
      _.map(products.data, (product) => ({
        ...product,
        hasSelectedTag: productIdsWithTag.has(product.Id),
      })),
    [productIdsWithTag]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Core',
        columns: [
          {
            Header: 'Product Id',
            accessor: 'Id',
          },
          {
            Header: 'Type',
            accessor: 'Type',
          },
          {
            Header: `Has Tag ${activeTag}`,
            accessor: 'hasSelectedTag',
            Cell: CheckableCell,
          },
        ],
      },
      {
        Header: 'MVG Info',
        columns: [
          {
            Header: 'Name',
            accessor: 'Name',
          },
          {
            Header: 'Display?',
            accessor: 'Display',
          },
        ],
      },
      {
        Header: 'Plugtrays Info',
        columns: [
          {
            Header: 'Name',
            accessor: 'NamePlugtrays',
          },
          {
            Header: 'Display?',
            accessor: 'DisplayPlugtrays',
          },
        ],
      },
    ],
    [activeTag]
  );

  const onSubmit = () => {
    const Tag = activeTag === 'New Tag' ? newTagValue : activeTag;
    const productIdsWithoutTag = new Set();
    _.map(products.data, ({ Id }) => {
      if (!productIdsWithTag.has(Id)) {
        productIdsWithoutTag.add(Id);
      }
    });
    const TaggedProductIds = Array.from(productIdsWithTag.values());
    const UntaggedProductIds = Array.from(productIdsWithoutTag.values());
    log.info({ Tag, TaggedProductIds, UntaggedProductIds }, 'saving tag set');

    saveTagsMutation.mutate({
      Tag,
      TaggedProductIds,
      UntaggedProductIds,
    });
  };

  const onTagChange = ({ target }) => {
    const { value } = target;
    setActiveTag(value);
  };
  return (
    <div>
      <h3>Tags</h3>
      <LoadingError error={products.error} />
      <select onChange={onTagChange} value={activeTag}>
        <option value=''>None</option>
        <option value='New Tag'>New Tag</option>
        {_.map(uniqueTags, (Tag, index) => (
          <option key={index} value={Tag}>
            {Tag}
          </option>
        ))}
      </select>
      {activeTag === 'New Tag' && (
        <>
          <p>New Tag : </p>
          <input type='text' value={newTagValue} onChange={(e) => setNewTagValue(e.target.value)} />
        </>
      )}
      <Table
        data={productsWithTag}
        columns={columns}
        addProductTag={addProductTag}
        removeProductTag={removeProductTag}
      />
      <button onClick={onSubmit} disabled={saveTagsMutation.isLoading || !products.isSuccess || !activeTag}>
        Save!
      </button>
      {savingMessage ? <h3>{savingMessage}</h3> : null}
    </div>
  );
};

AdminTagsCollections.propTypes = {
  Tag: PropTypes.string,
};

AdminTagsCollections.defaultProps = {};

export default AdminTagsCollections;
