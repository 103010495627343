import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { serviceCodeLookup, formatCurrency } from '../../utils/cart';
import LoadingError from '../LoadingError';
import { useShippingOptions } from './hooks';
import log from 'loglevel';

const ShippingServiceInput = ({ PlugtrayCount, FirstName, LastName, Address, Address2, City, State, Zip }) => {
  const shippingOptionsArgs = {
    Address: {
      FirstName,
      LastName,
      Address,
      Address2,
      City,
      State,
      Zip,
    },
    PlugtrayCount,
  };
  const shippingOptions = useShippingOptions(shippingOptionsArgs);
  const [shippingChoice, setShippingChoice] = useState(undefined);

  useEffect(() => {
    // Runs after every render, be careful!
    if (_.isUndefined(shippingChoice) && _.size(shippingOptions.data) > 0) {
      log.debug('pre-selecting first shipping option by default');
      setShippingChoice(_.get(shippingOptions, ['data', 0]));
    }
  });

  const errorMsg = (
    <div className='callout alert'>
      <p>
        We were unable to calculate shipping for the address you entered. This usually means the state and zip code are
        mismatched. Please check your shipping address and correct it if necessary.
      </p>
      <p>
        If your address looks correct, please email us at{' '}
        <a href='mailto:customerservice@mountainvalleygrowers.com'>customerservice@mountainvalleygrowers.com</a> for
        further assistance.
      </p>
    </div>
  );

  const renderOption = (shippingOption) => {
    const { Price, ServiceCode } = shippingOption;
    const handleChange = () => {
      setShippingChoice(shippingOption);
    };

    return (
      <label key={ServiceCode}>
        <input
          type='radio'
          name='shippingOption'
          value={ServiceCode}
          onChange={handleChange}
          required
          data-testid={`shipping-${ServiceCode}`}
          checked={ServiceCode === _.get(shippingChoice, 'ServiceCode')}
        />
        {`${serviceCodeLookup[ServiceCode]} - ${formatCurrency(Price)}`}
      </label>
    );
  };

  const renderedOptionsOrError =
    _.isArray(shippingOptions.data) && _.size(shippingOptions.data) === 0
      ? errorMsg
      : _.map(shippingOptions.data, renderOption);

  let renderedOptions = shippingOptions.isLoading
    ? [<option key='placeholder'>Loading...</option>]
    : renderedOptionsOrError;

  const rendered = (
    <div className='cell small-12'>
      <p>
        <strong>Shipping options</strong>
      </p>
      <p>
        <em>
          <small>
            Please note: <strong>Your plants will ship on the next available ship date.</strong> (We ship Monday -
            Wednesday!) We are sorry, but we cannot delay shipment of plug trays, as they outgrow their trays very
            quickly.
          </small>
        </em>
      </p>
      <p>
        In order to get your plants to you in top condition, we ship most plugs UPS 2nd-Day Air. Some areas may be
        eligible for UPS Ground shipping.
      </p>
      {renderedOptions}
      <LoadingError error={shippingOptions.error} />
    </div>
  );

  return [shippingChoice, rendered, setShippingChoice];
};

export default ShippingServiceInput;
