import React from 'react';
import _ from 'lodash';
import Button from '../Button';
import PropTypes from 'prop-types';
import { generateImageCdnPath, ImageSize } from '../../utils/images';

const CartItem = ({
  Id,
  NamePlugtrays,
  BotanicalName,
  CommonName,
  PricePlugtrays,
  Quantity,
  QuantityValidated,
  ImageHashes,
  setCartQuantity,
}) => {
  const renderedImage = (
    <img alt={NamePlugtrays} src={generateImageCdnPath(_.get(ImageHashes, [0]), ImageSize.Small)} />
  );
  const handleInputChange = (e) => setCartQuantity(parseInt(e.target.value));

  const noticeLimitedStock =
    QuantityValidated === 0 ? (
      <div className='cell small-12 callout alert small' role='alert'>
        <h3 className='header__small-caps'>Out of stock!</h3>
        <p>
          We&apos;re so sorry! {BotanicalName} {CommonName || NamePlugtrays} is now out of stock and will be removed
          from your order.
        </p>
      </div>
    ) : Quantity !== QuantityValidated ? (
      <div className='cell small-12 callout alert small' role='alert'>
        <h3 className='header__small-caps'>Low stock</h3>
        <p>
          There are only {QuantityValidated} {NamePlugtrays}s in stock, and your cart has {Quantity} in it currently.
          We&apos;ll remove {Quantity - QuantityValidated} {Quantity - QuantityValidated === 1 ? 'tray' : 'trays'} from
          your cart when you check out.
        </p>
      </div>
    ) : undefined;

  return (
    <div key={Id} className='grid-x grid-margin-x cart--product-row'>
      {noticeLimitedStock}
      <div className='cell small-6 medium-4 large-2'>{renderedImage}</div>
      <div className='cell small-6 medium-8 large-4'>
        <p className='product-row--title'>
          <span>{BotanicalName}</span>
          <strong>{CommonName || NamePlugtrays}</strong>
        </p>
        <p className='product-row--item-id'>Product ID: {Id}</p>
      </div>
      <div className='cell small-12 large-6'>
        <div className='grid-x grid-padding-x product-row--quant-calc'>
          <div className='cell small-2 medium-4 large-2  product-row--item-price'>
            <p className='hide-for-large'>Price</p>
            <p className='currency'>${PricePlugtrays}</p>
          </div>

          <div className='cell small-8 medium-4 large-6'>
            <label htmlFor={`quantity-${Id}`} className='show-for-sr'>
              Quantity
            </label>
            <p className='hide-for-large hide-for-sr'>Quantity</p>
            <div className='input-group plus-minus-input'>
              <div className='input-group-button'>
                <button
                  type='button'
                  className='button hollow circle'
                  aria-label='Decrease quantity by one'
                  onClick={_.partial(setCartQuantity, Quantity - 1)}
                >
                  <i className='fi-minus' aria-hidden='true'></i>
                </button>
              </div>
              <input
                className={`input-group-field ${Quantity !== QuantityValidated ? 'error' : ''}`}
                type='number'
                name={`quantity-${Id}`}
                value={Quantity}
                onChange={handleInputChange}
                min='0'
                max='360'
              />
              <div className='input-group-button'>
                <button
                  type='button'
                  className='button hollow circle'
                  disabled={Quantity > QuantityValidated}
                  aria-label='Increase quantity by one'
                  onClick={_.partial(setCartQuantity, Quantity + 1)}
                >
                  <i className='fi-plus' aria-hidden='true'></i>
                </button>
              </div>
            </div>
          </div>

          <div className='cell small-2 medium-4 large-4'>
            <p className='hide-for-large'>Total</p>
            <p className='currency'>
              <strong>${PricePlugtrays * QuantityValidated}</strong>
            </p>
          </div>
        </div>
      </div>
      <div className='cell small-12 product-row--item__delete'>
        <Button style='transparent icon fi-trash' text='Remove item' onClickHandler={_.partial(setCartQuantity, 0)} />
      </div>
    </div>
  );
};

CartItem.propTypes = {
  Id: PropTypes.string,
  NamePlugtrays: PropTypes.string,
  BotanicalName: PropTypes.string,
  CommonName: PropTypes.string,
  PricePlugtrays: PropTypes.number,
  Quantity: PropTypes.number,
  QuantityValidated: PropTypes.number,
  ImageHashes: PropTypes.arrayOf(PropTypes.string),
  setCartQuantity: PropTypes.func,
};

export default CartItem;
