import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from '../../utils/cart';
import _ from 'lodash';
import { Link } from 'gatsby';
import { GiftCardTransaction, useGetGiftCardQuery } from '../../generated/types-and-hooks';
import Loading from '../Loading';

const renderTransaction = ({ Amount, OrderId, Timestamp }: GiftCardTransaction, index: number) => (
  <tr key={index}>
    <td>{new Date(Timestamp * 1000).toLocaleString()}</td>
    <td>
      <Link to={`/app/admin/orders/${OrderId}`}>{OrderId}</Link>
    </td>
    <td>{formatCurrency(Amount)}</td>
  </tr>
);

const AdminGiftCardDetail = ({ Id }) => {
  const giftCardContext = useGetGiftCardQuery({ Id });

  if (giftCardContext.isLoading) {
    return <Loading />;
  }

  const {
    Status,
    SenderName,
    RecipientName,
    RecipientEmail,
    Message,
    Amount,
    Balance,
    OrderId,
    TimestampCreated,
    Pin,
    Transactions,
  } = giftCardContext.data?.GetGiftCard || {};
  const PurchaseDate = TimestampCreated ? new Date(TimestampCreated * 1000).toLocaleString() : 'Invalid Date';
  return (
    <>
      <h3>Gift Card Detail</h3>
      <div>
        <dt>Id</dt>
        <dd>{Id}</dd>
      </div>
      <div>
        <dt>Status</dt>
        <dd>{Status}</dd>
      </div>
      <div>
        <dt>Sender Name</dt>
        <dd>{SenderName}</dd>
      </div>
      <div>
        <dt>Recipient Name</dt>
        <dd>{RecipientName}</dd>
      </div>
      <div>
        <dt>Recipient Email</dt>
        <dd>{RecipientEmail}</dd>
      </div>
      <div>
        <dt>Message</dt>
        <dd>{Message}</dd>
      </div>
      <div>
        <dt>Amount</dt>
        <dd>{formatCurrency(Amount)}</dd>
      </div>
      <div>
        <dt>Balance</dt>
        <dd>{formatCurrency(Balance)}</dd>
      </div>
      <div>
        <dt>Timestamp Created</dt>
        <dd>{PurchaseDate}</dd>
      </div>
      <div>
        <dt>Pin</dt>
        <dd>{Pin}</dd>
      </div>
      <div>
        <dt>Order Id (When Gift Card Was Purchased)</dt>
        <dd>
          <Link to={`/app/admin/orders/${OrderId}`}>{OrderId}</Link>
        </dd>
      </div>

      <h3>Redemptions</h3>
      <table>
        <thead>
          <tr>
            <th>Timestamp</th>
            <th>Order Id</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>{_.map(Transactions, renderTransaction)}</tbody>
      </table>
    </>
  );
};

AdminGiftCardDetail.propTypes = {
  Id: PropTypes.string,
};

export default AdminGiftCardDetail;
