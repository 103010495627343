import _ from 'lodash';
import React, { Key, useEffect, useState } from 'react';
import LoadingError from '../LoadingError';
import { useQuery } from '@tanstack/react-query';
import fetch from 'isomorphic-fetch';
import log from 'loglevel';
import Modal from 'react-modal';
import Button from '../Button';
import { UpsShipment } from '../../generated/types-and-hooks';

const ZEBRA_URL = 'https://127.0.0.1:9101';

type ZebraPrinter = {
  connection: string;
  deviceType: string;
  manufacturer: string;
  name: string;
  provider: string;
  uid: string;
  version: number;
};

const fetchPrinterList = async () => {
  const res = await fetch(`${ZEBRA_URL}/available`, {});
  return res.json();
};

const renderPrinterOption = ({ uid, connection }: ZebraPrinter, index: Number) => (
  <option value={uid} key={index as Key}>
    {uid} ({connection})
  </option>
);

export type AdminZebraPickerResult = {
  Rendered: JSX.Element;
  StatusRendered: JSX.Element; // "Minimized" Status Element that shows the status of the printer.  Clicking on it opens the modal.
  OpenModal: () => void;
  ReadyToPrint: boolean;
  PrintLabel: (zplData: string) => Promise<void>;
  PrintShipments: (Shipments: UpsShipment[]) => Promise<void>;
};

const LOCALSTORAGE_PRINTER_KEY = 'acorn:defaultZebraPrinter';

const AdminZebraPicker = () => {
  Modal.setAppElement('#___gatsby');

  const query = useQuery({ queryKey: ['todos'], queryFn: fetchPrinterList });
  const [hasInitialized, setHasInitialized] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [selectedPrinter, setSelectedPrinter] = useState<string>('');
  const [printersByUid, setPrintersByUid] = useState<{ [key: string]: ZebraPrinter }>({});
  const OpenModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const [showHelp, setShowHelp] = useState<boolean>(false);

  useEffect(() => {
    if (hasInitialized || !query.data) {
      return;
    }
    setPrintersByUid(_.keyBy(query.data.printer, 'uid'));
    const defaultPrinter = localStorage.getItem(LOCALSTORAGE_PRINTER_KEY);
    if (defaultPrinter) {
      setSelectedPrinter(defaultPrinter);
    }
    setHasInitialized(true);
  }, [query.data]);

  const ReadyToPrint = selectedPrinter !== '' && selectedPrinter in printersByUid;

  const printerChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPrinterUid = event.target.value;
    log.debug({ event, selectedPrinterUid }, 'printerSelected');
    setSelectedPrinter(selectedPrinterUid);
    localStorage.setItem(LOCALSTORAGE_PRINTER_KEY, selectedPrinterUid);
  };

  const PrintLabel = async (ZplData: string) => {
    if (!ReadyToPrint) {
      log.error({ selectedPrinter, ReadyToPrint }, 'not ready to print');
      throw new Error('Not ready to print, please select a printer first');
    }
    const device = printersByUid[selectedPrinter];
    const result = await fetch(`${ZEBRA_URL}/write`, {
      credentials: 'omit',
      headers: {
        'Content-Type': 'text/plain;charset=UTF-8',
      },

      body: JSON.stringify({
        device,
        data: ZplData,
      }),
      method: 'POST',
      mode: 'cors',
    });
    log.debug({ result, ZplData }, 'printing request sent');
    if (!result.ok) {
      log.error({ result, status: result.status, statusText: result.statusText }, 'printing request failed');
      throw new Error(`Printing request failed: ${result.status}:${result.statusText}`);
    }
    const data = await result.json();
    log.info({ data }, 'finished printing label');
  };

  const PrintShipments = async (Shipments: UpsShipment[]) => {
    // Prints all labels for all packages in all shipments
    if (!Shipments) {
      log.error({ Shipments }, 'No Shipments to print');
      return;
    }
    if (!ReadyToPrint) {
      log.error({ ReadyToPrint }, 'Not ready to print');
    }
    let labels: string[] = [];
    for (const shipment of Shipments) {
      const { Id, OrderId } = shipment;
      log.debug({ OrderId, Id }, 'Printing shipment');
      if (shipment?.Packages) {
        for (const p of shipment?.Packages) {
          if (p.Label) {
            labels.push(p.Label);
          }
        }
      }
    }
    for (const label of labels) {
      // base64 decode label
      const decodedLabel = atob(label);
      await PrintLabel(decodedLabel);
    }
  };

  const initialSetupInstructionsRendered = !showHelp ? (
    <>
      <a onClick={() => setShowHelp(true)}>Show Help/Troubleshooting Info</a>
    </>
  ) : (
    <>
      <a onClick={() => setShowHelp(false)}>Hide Help/Troubleshooting Info</a>
      <h3>Setup/Troubleshooting Instructions</h3>
      <ol>
        <li>
          Download & install{' '}
          <a href='https://www.zebra.com/content/dam/zebra_new_ia/en-us/solutions-verticals/product/Software/Printer%20Software/Link-OS/browser-print/zebra-browser-print-windows-v132489.exe'>
            Zebra Browser Print
          </a>
          .
        </li>
        <li>
          Validate that Zebra Browser Print is running, you will see an additional icon in your Windows system tray.
        </li>
        <li>
          Add security exception to allow browser to communicate with Zebra Cloud Print. Click{` `}
          <a href={ZEBRA_URL} target='_blank'>
            this link
          </a>
          {` `}
          and accept the security exception. This should only have to be done once, but browser updates may require you
          to do this again.
        </li>
        <li>
          Zebra Cloud Print will prompt for permission. You will see a dialog box saying{' '}
          <i>https://www.plugtrays.com wants to access your Zebra Devices ....</i>, click <i>Yes</i>.
        </li>
        <li>The list of available printers should now be visible, please select a valid label printer.</li>
      </ol>
      <p>
        <b>
          If you have completed all of the above steps, and the list of printers does not populate, try refreshing the
          page.
        </b>
      </p>
    </>
  );

  const Rendered = (
    <>
      <Modal isOpen={modalIsOpen} contentLabel='Choose Label Printer' className='modal' onRequestClose={closeModal}>
        <div className='grid-x'>
          <div className='cell small-12 modal--close'>
            <button className='button transparent' onClick={closeModal}>
              <i className='fi-x' /> close
            </button>
          </div>
        </div>
        <h3>Select a Label Printer</h3>
        <LoadingError error={query.error} />

        <label htmlFor='printer-select'>Choose a printer</label>

        <select name='printer' id='printer-select' onChange={printerChanged} value={selectedPrinter}>
          <option value=''>--Please choose an option--</option>

          {query.data?.printer?.map(renderPrinterOption)}
        </select>
        {initialSetupInstructionsRendered}
        <Button
          testId='accept-date-button'
          // isDisabled={query.isLoading}
          style={query.isLoading ? 'secondary expanded loading' : 'secondary expanded'}
          onClickHandler={closeModal}
          text='Close'
        />
      </Modal>
    </>
  );

  const StatusRendered = (
    <span onClick={OpenModal}>
      {ReadyToPrint ? (
        <span>
          <i className='fi-print'></i> Edit label printer settings
        </span>
      ) : (
        <span style={{ color: 'red' }}>
          <i className='fi-alert'></i> Set up label printer
        </span>
      )}
    </span>
  ) as JSX.Element;
  return { Rendered, OpenModal, ReadyToPrint, PrintLabel, StatusRendered, PrintShipments };
};

AdminZebraPicker.propTypes = {};

AdminZebraPicker.defaultProps = {};

export default AdminZebraPicker;
