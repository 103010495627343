import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, Fragment, useMemo } from 'react';
import Loading from '../Loading';
import Button from '../Button';
import config from '../../../config';
import log from 'loglevel';
import {
  ProductType,
  useGetProductQuery,
  useSaveProductMutation,
  useGetTagsAndCdnImagesQuery,
  useDeleteProductMutation,
  AcornProductInput,
  AcornProduct,
} from '../../generated/types-and-hooks';
import Select from 'react-select';
import { navigate } from 'gatsby';
import SavingError from '../SavingError';
import { Controller, FieldError, FieldErrorsImpl, Merge, useForm } from 'react-hook-form';
import { generateImageCdnPath, ImageType, ImageSize } from '../../utils/images';

type Modify<T, R> = Omit<T, keyof R> & R;
type ProductFormValues = Modify<
  Omit<AcornProduct, 'MVGPath' | 'ImageHashes' | 'AdminImages'>,
  {
    Type: SelectOption;
    Tags: SelectOption[];
    TagsPlugtrays: SelectOption[];
    CollectionProductCount: SelectOption;
  }
>;

const ProductTypeOptions = [
  { label: 'Plant (Pot/Plugtray)', value: ProductType.Plant },
  { label: 'Collection', value: ProductType.Collection },
];

const CollectionProductCountOptions = [
  { label: '6-Pack', value: '6' },
  { label: '36-Pack', value: '36' },
];

type SelectOption = {
  label: string;
  value: string;
};
const translateOption = (strOption: string): SelectOption => ({ label: strOption, value: strOption });
const translateFromOption = (option: SelectOption) => option?.value;

const renderErrorMessage = (error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined) => {
  if (error) {
    return <p style={{ color: 'red' }}>{error.message as string}</p>;
  }
};

const AdminProductDetail = ({ productId }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm<ProductFormValues>({
    defaultValues: {
      Tags: [],
      TagsPlugtrays: [],
      Price: 0,
      PricePlugtrays: 0,
      InStock: 0,
      InStockPlugtrays: 0,
      BackorderLimit: 0,
    },
  });
  const watchType = watch('Type');
  const watchDisplayMVG = watch('DisplayMVG');
  const watchDisplayPlugtrays = watch('DisplayPlugtrays');

  const isAdding = productId === 'new';
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [debouncedId, setDebouncedId] = useState<string>();
  const [Images, setImages] = useState<string[]>([]);

  const [newImageName, setNewImageName] = useState<SelectOption>();

  const validateNewProductId = useGetProductQuery(
    { Id: debouncedId! },
    {
      enabled: isAdding && debouncedId !== undefined && debouncedId.length > 0,
      onSuccess: (data) => {
        log.debug({ data }, 'ran validateNewProductId query');
      },
    }
  );

  const tagsAndImagesContext = useGetTagsAndCdnImagesQuery();
  const tagsAndImages = useMemo(() => {
    const imageHashes = _.keyBy(tagsAndImagesContext.data?.GetCdnImages, 'Path');
    return {
      imageHashes,
      imageOptions: _.map(imageHashes, ({ Path }) => ({ label: Path, value: Path })),
      tagsOptions: _.orderBy(tagsAndImagesContext.data?.GetAllTags?.Tags).map(translateOption),
      tagsPlugtraysOptions: _.orderBy(tagsAndImagesContext.data?.GetAllTags?.TagsPlugtrays).map(translateOption),
    };
  }, [tagsAndImagesContext.data]);

  const saveProductContext = useSaveProductMutation({
    onSuccess: (data, variables) => {
      log.debug({ data, variables }, 'finished saving product');
      if (isAdding) {
        log.debug('redirecting to edit product detail route');
        // Redirect to edit the newly created item
        navigate(`/app/admin/products/${data.SaveProduct?.Id}`);
      }
    },
  });

  const deleteProductContext = useDeleteProductMutation({
    onSuccess: (data) => {
      log.debug({ data }, 'successfully deleted product');
      navigate('/app/admin');
    },
  });

  const productContext = useGetProductQuery(
    { Id: productId },
    {
      enabled: !isAdding,
      onSuccess: (data) => {
        // Populate initial state
        if (!hasLoaded) {
          const Product = data.GetAcornProduct;
          reset({
            ..._.omit(Product, ['ImageHashes', 'Images', 'MVGPath']),
            Type: translateOption(Product?.Type!),
            Tags: Product?.Tags?.map(translateOption),
            TagsPlugtrays: Product?.TagsPlugtrays?.map(translateOption),
            CollectionProductCount: _.find(
              CollectionProductCountOptions,
              _.matchesProperty('value', String(Product?.CollectionProductCount))
            ),
          });
          setImages(Product?.Images);
          setHasLoaded(true);
        }
      },
    }
  );

  const onSubmit = (data) => {
    const ProductToSave = {
      ...data,
      Type: translateFromOption(data.Type),
      Tags: data.Tags?.map(translateFromOption),
      TagsPlugtrays: data.TagsPlugtrays?.map(translateFromOption),
      Images,
    } as AcornProductInput;

    if (ProductToSave.Type === ProductType.Collection && data.CollectionProductCount) {
      ProductToSave.CollectionProductCount = Number(translateFromOption(data.CollectionProductCount));
    }
    log.debug({ ProductToSave }, 'built product to save');
    saveProductContext.mutate(ProductToSave);
  };

  const removeImageAtIndex = (index) => {
    let newImages = [...Images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const moveImageUp = (index) => {
    if (index < 1) {
      return;
    }
    let newImages = [...Images];
    const itemA = _.get(newImages, index);
    const itemB = _.get(newImages, index - 1);
    newImages[index] = itemB;
    newImages[index - 1] = itemA;
    setImages(newImages);
  };

  const moveImageDown = (index) => {
    if (index + 1 >= _.size(Images)) {
      return;
    }
    let newImages = [...Images];
    const itemA = _.get(newImages, index);
    const itemB = _.get(newImages, index + 1);
    newImages[index] = itemB;
    newImages[index + 1] = itemA;
    setImages(newImages);
  };

  const renderImageRow = (image, index) => {
    const actionsRendered = (
      <td>
        <a onClick={() => moveImageUp(index)}>↑</a>
        <br />
        <a onClick={() => moveImageDown(index)}>↓</a>
        <br />
        <a onClick={() => removeImageAtIndex(index)}>❌</a>
        <br />
      </td>
    );
    const imageFileName = image.slice(8);
    if (!(imageFileName in tagsAndImages.imageHashes)) {
      return (
        <tr key={index}>
          <td>{image}</td>
          <td colSpan={2}>Cannot find image on CDN. This image isn't going to work.</td>
          {actionsRendered}
        </tr>
      );
    }
    const { Hash, HashedSlug } = tagsAndImages.imageHashes[imageFileName] || {};
    const ImageUrl = generateImageCdnPath(HashedSlug, ImageSize.Original, ImageType.Webp);
    return (
      <tr key={index}>
        <td>{image}</td>
        <td>{Hash}</td>
        <td>
          <img alt={`Image: ${imageFileName}, Version: ${Hash}, HashedSlug: ${HashedSlug}`} src={ImageUrl} />
        </td>
        {actionsRendered}
      </tr>
    );
  };

  const renderedTitle = isAdding ? 'Add New Product' : `Edit Product ${productId}`;

  const renderedImageRows = _.map(Images, renderImageRow);
  const newImageHash = tagsAndImages.imageHashes[newImageName?.value!];
  const newImageConfirmRendered = newImageHash ? (
    <>
      <td>{newImageHash.Hash}</td>
      <td>
        <img
          alt={`Path: ${newImageHash.Path} Version: ${newImageHash.Hash}`}
          src={generateImageCdnPath(newImageHash.HashedSlug, ImageSize.Original, ImageType.Webp)}
        />
      </td>
    </>
  ) : null;
  const addNewImage = () => {
    if (!newImageHash) {
      log.error({ newImageHash, newImageName }, 'addNewImage, could not find image to add');
      return;
    }
    setImages([...Images, `/images/${newImageHash.Path}`]);
    setNewImageName(undefined);
  };

  if (productContext.isLoading && !isAdding) {
    return <Loading />;
  }

  return (
    <Fragment>
      <div className='grid-x'>
        <div className='cell small-12'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <>
              <h1 className='product--header'>{renderedTitle}</h1>

              <label>
                Id
                <input
                  type='text'
                  {...register('Id', {
                    required: 'Id is required',
                    minLength: {
                      value: 4,
                      message: 'Id must have length >= 4',
                    },
                    onChange: (e) => {
                      _.debounce(() => setDebouncedId(e.target.value), 1500, {})();
                    },
                    validate: {
                      checkId: () =>
                        !isAdding ||
                        (isAdding &&
                          validateNewProductId.isFetched &&
                          validateNewProductId.data?.GetAcornProduct === null),
                    },
                  })}
                  readOnly={!isAdding}
                  aria-invalid={errors.Id ? 'true' : 'false'}
                />
                {renderErrorMessage(errors?.Id)}
                {errors?.Id?.type === 'checkId' ? (
                  <div className='cell small-12 callout alert'>
                    <h3>Warning</h3>
                    <p>Product ID already exists.</p>
                  </div>
                ) : null}
              </label>
              <label>
                Product Type
                <Controller
                  name='Type'
                  control={control}
                  rules={{ required: 'Product Type is required' }}
                  render={({ field }) => <Select options={ProductTypeOptions} isDisabled={!isAdding} {...field} />}
                />
                {renderErrorMessage(errors?.Type)}
              </label>
              {watchType?.value === ProductType.Collection ? (
                <label>
                  Collection Product Count
                  <Controller
                    name='CollectionProductCount'
                    control={control}
                    rules={{ required: 'Collection Product Count is required' }}
                    render={({ field }) => <Select options={CollectionProductCountOptions} {...field} />}
                  />
                  {renderErrorMessage(errors?.CollectionProductCount)}
                </label>
              ) : null}
              <label>
                Slug
                <input
                  type={'text'}
                  {...register('Slug', {
                    required: 'Slug is required',
                    minLength: {
                      value: 10,
                      message: 'Slug must have a length >= 10',
                    },
                  })}
                />
                {renderErrorMessage(errors.Slug)}
                <i>The slug represents the website path for the product. Please don't change this unless necessary!</i>
              </label>

              <h3>Taxonomy</h3>
              <label>
                Botanical Name
                <input type={'text'} {...register('BotanicalName', {})} />
              </label>
              {renderErrorMessage(errors.BotanicalName)}
              <label>
                Common Name
                <input type={'text'} {...register('CommonName')} />
                {renderErrorMessage(errors.CommonName)}
              </label>
              <label>
                Genus
                <input type={'text'} {...register('Genus')} />
                {renderErrorMessage(errors.Genus)}
                <i>If present, a 'genus:' tag will be automatically added.</i>
              </label>
              <label>
                Tags
                <Controller
                  name='Tags'
                  control={control}
                  render={({ field }) => <Select options={tagsAndImages.tagsOptions} isMulti {...field} />}
                />
                {renderErrorMessage(errors?.Tags)}
                <i>Some of these tag namespaces have special meanings:</i>
                <ul>
                  <li>&quot;includes:TAG&quot; -&gt; Collections Only: Assigns TAG to collection</li>
                  <li>&quot;col:COLLECTION-NAME&quot; -&gt; Tags for representing a specific collection</li>
                  <li>&quot;zone:NUMBER&quot; -&gt; Only specify a single zone tag</li>
                </ul>
              </label>

              <h3>MVG Product Details</h3>
              <label>
                <input type={'checkbox'} {...register('DisplayMVG', {})} />
                Show MVG Product
                {renderErrorMessage(errors.DisplayMVG)}
              </label>
              <label>
                Name
                <input
                  type={'text'}
                  {...register('Name', {
                    required: watchDisplayMVG ? 'Name is required' : undefined,
                  })}
                />
                {renderErrorMessage(errors.Name)}
              </label>
              <label>
                Description
                <input
                  type={'text'}
                  {...register('Description', {
                    required: watchDisplayMVG ? 'Description is required' : undefined,
                  })}
                />
                {renderErrorMessage(errors.Description)}
              </label>
              <label>
                In Stock
                <input
                  type={'number'}
                  step={1}
                  {...register('InStock', {
                    required: 'InStock is required',
                    valueAsNumber: true,
                    min: {
                      value: 0,
                      message: 'InStock must be >= 0',
                    },
                  })}
                />
                {renderErrorMessage(errors.InStock)}
              </label>
              <label>
                Backorder Limit
                <input
                  type={'number'}
                  step={1}
                  {...register('BackorderLimit', {
                    required: 'BackorderLimit is required',
                    valueAsNumber: true,
                    min: {
                      value: 0,
                      message: 'BackorderLimit must be >= 0',
                    },
                  })}
                />
                {renderErrorMessage(errors.BackorderLimit)}
              </label>
              <label>
                Price
                <input
                  type={'number'}
                  step={'.01'}
                  {...register('Price', {
                    required: 'Price is required',
                    valueAsNumber: true,
                    min: {
                      value: 0,
                      message: 'Price must be >= 0',
                    },
                  })}
                />
                {renderErrorMessage(errors.Price)}
              </label>

              <h3>Plugtrays Product Details</h3>
              <label>
                <input type={'checkbox'} {...register('DisplayPlugtrays', {})} />
                Show Plugtrays Product
                {renderErrorMessage(errors.DisplayPlugtrays)}
              </label>
              <label>
                Name Plugtrays
                <input
                  type={'text'}
                  {...register('NamePlugtrays', {
                    required: watchDisplayPlugtrays ? 'Name Plugtrays is required' : undefined,
                  })}
                />
                {renderErrorMessage(errors.NamePlugtrays)}
              </label>

              <label>
                Description Plugtrays
                <input
                  type={'text'}
                  {...register('DescriptionPlugtrays', {
                    required: watchDisplayPlugtrays ? 'Description Plugtrays is required' : undefined,
                  })}
                />
                {renderErrorMessage(errors.DescriptionPlugtrays)}
              </label>
              <label>
                In Stock Plugtrays
                <input
                  type={'number'}
                  step={1}
                  {...register('InStockPlugtrays', {
                    required: 'InStock Plugtrays is required',
                    valueAsNumber: true,
                    min: {
                      value: 0,
                      message: 'InStock Plugtrays must be >= 0',
                    },
                  })}
                />
                {renderErrorMessage(errors.InStockPlugtrays)}
              </label>
              <label>
                Price Plugtrays
                <input
                  type={'number'}
                  step={'.01'}
                  {...register('PricePlugtrays', {
                    required: 'Price Plugtrays is required',
                    valueAsNumber: true,
                    min: {
                      value: 0,
                      message: 'Price must be >= 0',
                    },
                  })}
                />
                {renderErrorMessage(errors.PricePlugtrays)}
              </label>
              <label>
                Tags Plugtrays
                <Controller
                  name='TagsPlugtrays'
                  control={control}
                  render={({ field }) => <Select options={tagsAndImages.tagsPlugtraysOptions} isMulti {...field} />}
                />
                {renderErrorMessage(errors?.TagsPlugtrays)}
              </label>

              <h3>Images</h3>
              <table>
                <thead>
                  <tr>
                    <th>Image Path</th>
                    <th>Version</th>
                    <th>Image</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {renderedImageRows}
                  <tr>
                    <td>
                      Add New Image:
                      <Select options={tagsAndImages.imageOptions} onChange={setNewImageName} value={newImageName} />
                    </td>
                    {newImageConfirmRendered}
                    <td>
                      <Button isDisabled={!newImageHash} text={'Add Image'} onClickHandler={addNewImage} />
                    </td>
                  </tr>
                </tbody>
              </table>
              <h3>Legacy Fields</h3>
              <i>Deprecated - Used for Plugtrays.com only. Please favor using Tags in the future.</i>
              <label>
                Height
                <input type={'text'} {...register('Height', {})} />
                {renderErrorMessage(errors.Height)}
              </label>
              <label>
                Hardiness
                <input type={'text'} {...register('Hardiness', {})} />
                {renderErrorMessage(errors.Hardiness)}
              </label>
              <label>
                Characteristics
                <input type={'text'} {...register('Characteristics', {})} />
                {renderErrorMessage(errors.Characteristics)}
              </label>
              <label>
                Flower Color
                <input type={'text'} {...register('FlowerColor', {})} />
                {renderErrorMessage(errors.FlowerColor)}
              </label>
              <label>
                Uses
                <input type={'text'} {...register('Uses', {})} />
                {renderErrorMessage(errors.Uses)}
              </label>
              <div className='grid-x'>
                <div className='cell small-6'>
                  <Button
                    type={'submit'}
                    text={saveProductContext.isLoading ? 'Working....' : 'Save Product'}
                    style={saveProductContext.isLoading ? 'loading' : undefined}
                    isDisabled={saveProductContext.isLoading}
                  />
                </div>
                <div className='cell small-6' style={{ textAlign: 'right' }}>
                  {!isAdding && (
                    <>
                      <Button
                        text={'Delete Product'}
                        style='transparent alert'
                        onClickHandler={() => {
                          if (confirm('Are you sure you want to delete this product?  This cannot be un-done.')) {
                            deleteProductContext.mutate({ Id: productContext.data?.GetAcornProduct?.Id! });
                          }
                        }}
                      />
                    </>
                  )}
                </div>
                <div className='cell small-12'>
                  <SavingError error={saveProductContext.error} />
                  <SavingError error={deleteProductContext.error} />
                </div>
              </div>
            </>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

AdminProductDetail.propTypes = {
  productId: PropTypes.string,
};

AdminProductDetail.defaultProps = {};

export default AdminProductDetail;
