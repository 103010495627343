import React from 'react';
import _ from 'lodash';
import LoadingError from '../LoadingError';
import { OrderStatus, useGetWarrantyStatesQuery } from '../../generated/types-and-hooks';
import AdminOrdersV2 from './AdminOrdersV2';

const AdminDashboardV2 = () => {
  const warrantyStatesContext = useGetWarrantyStatesQuery();

  return (
    <div className='grid-x'>
      <div className='cell small-12'>
        <LoadingError error={warrantyStatesContext.error} />
        <AdminOrdersV2 Status={OrderStatus.Ready} />
      </div>
    </div>
  );
};

AdminDashboardV2.propTypes = {};

AdminDashboardV2.defaultProps = {};

export default AdminDashboardV2;
